import React from 'react'
import Footer from "../Footer/Footer";
import HeaderThree from "../HeaderThree/HeaderThree";
import EducationCourseListingBusinessStudies from './EducationCourseListing_BusinessStudies';

export default function EducationCourseListing(props) {
    const [categoryId,setcategoryId] = React.useState(
        props.match && props.match.params && props.match.params.id
          ? props.match.params.id
          : ""
      );

    return (
        <div style={{ width: "100%" }}>
        <HeaderThree />
        <EducationCourseListingBusinessStudies categoryId={categoryId} setcategoryId={setcategoryId}/>
        <Footer /> 
        </div>
    )
}
