import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./HeaderThree.css";
import thalim from "../images/talim-logo-black.webp";
import axios from "../Utils/Axios"; //import axios config
import { AuthContext } from "../Context/AuthContext";
import AccountMenu from "../Registration/AccountMenu";
import SearchBox from "../HeaderTwo/SearchBox";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({

  loginIcon: {
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "8px",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

 
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  home_language_switch: {
    border: "solid 1px #B5B5B5",
    width: "35px",
    height: "35px",
  },
  select_city: {
    margin: theme.spacing(1),
    marginBottom: "auto",
    padding: "0px 20px 0px 10px",
    minWidth: 120,
  },
  dc: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ddc: {
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
}));

export default function HeaderThree() {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [profile, setProfile] = React.useState([]);
  const { open5, setOpen5, login, setLogin } = useContext(AuthContext);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    getProfile();
  }, [JSON.parse(localStorage.getItem("data"))]);

  const getProfile = () => {
    axios(`Home/get_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <Box width="200px"  p={1}>
        <SearchBox />
      </Box>

      <Box className="c" ml={2}>
        {localStorage.getItem("data") || login ? (
          <AccountMenu fullaname={profile && profile.fullname} logo={profile && profile.profile_image}/>
        ) : (
          <Box className="header_two_menu_box">
            <Link to="/Login"><Typography color="black" variant="subtitle2">Join with Sa`aeh </Typography></Link>
          </Box>
        )}
      </Box>
      
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <Box className="header_three_bg">
        <AppBar
          position="fixed"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <Toolbar>
            <Link to="/education">
              <Box
                component={"img"}
                p={1}
                width={170}
                src={thalim}
                alt="Taleem logo"
                to="/home"
              />
            </Link>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Box
               className="header_three_icon_search_bg">
                <SearchBox />
              </Box>
           
              <Box className="c" ml={2}>
                {localStorage.getItem("data") || login ? (
                  <Box className="header_two_menu_box">
                  </Box>
                ) : (
                  <Box className="header_two_menu_box">
                  <Link to="/Login"><Typography color="black" variant="subtitle2">Join with Sa`aeh </Typography></Link>
                </Box>
                )}
              </Box>
              {localStorage.getItem("data") || login ? (
              <AccountMenu fullaname={profile && profile.fullname} logo={profile && profile.profile_image}/>
              ) : ""}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
