import * as React from "react";
import MyAccount_ManageListingAddCoursesEdit from "./MyAccount_ManageListingAddCoursesEdit";

export default function MyAccountEditCoursesEdit() {
  return (
    <div>
      <MyAccount_ManageListingAddCoursesEdit />
    </div>
  );
}
