import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "../Utils/Axios";
import { useAlert } from "react-alert";
import { Link, useHistory } from "react-router-dom";

export default function CommentDialog({bookid,getbooks}) {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [title, set_title] = React.useState("");
  const [desc, set_desc] = React.useState("");
  const alert = useAlert();
  const history = useHistory();

  const handleClickOpen = () => {
  if(localStorage.getItem("data"))
  {
  setOpen(true);
  getbookcomment();
  }
  else{
    history.push({
      pathname: "/login",
      state: { data: true },
    });
  }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getbookcomment = () => {
    axios(`Home/get_books_comment?bookid=${bookid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          set_title (res.data.data[0].title);
          set_desc (res.data.data[0].review);
        }
      })
      .catch((err) => console.log(err));
  };

  const addbookRating = (bookid) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("bookId", bookid);
      formdata.append("rating", value);
      formdata.append("title", title);
      formdata.append("description", desc);
      axios(`Home/add_Book_rating`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            getbooks(bookid);
            alert.success(res.data.message);
            setOpen(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="MyAccountProfileBg">
      <Button
        className="primaryButtonOutlined" 
        variant="outlined"
        onClick={handleClickOpen}
      >
        Comment this product
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ color:"#572672"}}> Write your comment </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please write a comment for this product. We will use this to improve
            our service.
          </DialogContentText>

          <TextField
            label="Title"
            variant="standard"
            fullWidth
            value={title}
            onChange={(e) => set_title(e.target.value)}
          />
          <TextField
            autoFocus
            multiline
            maxRows={4}
            margin="dense"
            id="name"
            label="Comment this product"
            type="text"
            fullWidth
            variant="standard"
            value={desc}
            onChange={(e) => set_desc(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="MyAccountProfileBg">
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            className="primaryButton"
            variant="contained"
            onClick={() => addbookRating(bookid)}
          >
            Submit  
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
