import React, { useState } from "react";
import Footer from "../Footer/Footer";
import { Link, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import {Container,Grid,Box,Card,Button,IconButton,Tooltip,Stack,CardContent,Rating,
  Breadcrumbs,Typography,CardMedia,createTheme,Checkbox,} from "@mui/material";
import axios from "../Utils/Axios"; //import axios config
import parse from "html-react-parser";
import LoadingOverlay from "react-loading-overlay";
import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ReviewRatingDialog from "./ReviewRatingDialog";
import Header from "../Header/Header";

const labels = {
  0.5: "0.5",
  1: "1",
  1.5: "1.5",
  2: "2",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "4.5",
  5: "5",
};


const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.3rem",
    fontWeight: "400",
  },
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function EducationStoresDetails() {
  const [value, setValue] = React.useState(0);

  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const [showMore, setShowMore] = React.useState(false);
  const [languages, setLanguages] = React.useState("");
  const [format, setFormat] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [showButton, setshowButton] = React.useState(0);
  React.useEffect(() => {
    setStoreId(params && params.id ? params.id : "");
  }, [params]);
  const [showNumber, setshowNumber] = React.useState(0);

  const ShowNumber = () => {
    setshowNumber(1);
  };

  const [hover, setHover] = React.useState(0);

  const [bookstore, setBookStore] = React.useState([]);
  const [storeId, setStoreId] = React.useState(params && params.id);

  React.useEffect(() => {
    getbooks();
    getAuthors();
    getLanguages();
  }, [storeId,languages,format,author]);
  const getbooks = () => {
    setLoading(true);
    axios(`Bookstore/get_bookstore_info?store_id=${storeId}&languages=${languages}&format=${format}&author=${author}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setBookStore(res.data.data[0]);
          if (
            res.data.data[0].description &&
            res.data.data[0].description.length > 1400
          ) {
            setshowButton(1);
          }
          setValue(res.data.data[0].rating);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  
  const LanaguageChange = (event,val) => {
    if((event.target.checked)){
      setLanguages(languages + val + ",");
    }else{
      setLanguages(languages.replace(val + ",", ""));
    }
  };

  
  const FormatChange = (event,val) => {
    if((event.target.checked)){
      setFormat(format + val + ",");
    }else{
      setFormat(format.replace(val + ",", ""));
    }
  };


  const AuthorChange = (event,val) => {
    if((event.target.checked)){
      setAuthor(author + val + ",");
    }else{
      setAuthor(author.replace(val + ",", ""));
    }
  };

  const getAuthors = () => {
    axios(`Institution/get_authors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setAuthors(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const [authors, setAuthors] = useState([]);


  const getLanguages = () => {
    axios(`Bookstore/get_languages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setlanguage(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const [languaes, setlanguage] = useState([]);
  

  return (
    <div style={{ width: "100%" }}>
      {/* <HeaderTwo /> */}
      <Header />

      <Box sx={{ bgcolor: "#572672" }} pt={5}>
        <Container>
          <Box sx={{ flexGrow: 1, my: 5, mt: 9 }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={3}>
                  <CardMedia
                    component={"img"}
                    sx={{
                      width: 170,
                      height: 170,
                      p: 1,
                      borderRadius: 100,
                      border: "5px solid #fff",
                      bgcolor: "#fff",
                    }}
                    src={bookstore.image}
                    alt=""
                    to="/home"
                  />
              </Grid>
              <Grid item xs={9}>
                <Typography
                  textOverflow="ellipsis"
                  overflow="hidden"
                  maxWidth="600px"
                  variant="h4"
                  gutterBottom
                  component="div"
                  color="white"
                  startIcon={<LocationOnIcon />}
                >
                  {bookstore.title}
                </Typography>
                <Box sx={{ color: "white", alignItems: "center" }}>
                  <IconButton aria-label="upload picture" component="span">
                    <LocationOnIcon sx={{ color: "white" }} />
                  </IconButton>
                  <Typography
                    color="white"
                    variant="standard"
                    startIcon={<LocationOnIcon />}
                  >
                    {bookstore.location}
                  </Typography>
                </Box>
                <ReviewRatingDialog
                  instiId={storeId}
                  ratingvalue={value}
                  rateType={2}
                  rating_count={bookstore.rating_count}
                  getinstituteProfile={getbooks}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box sx={{ bgcolor: "#f9f9f9", py: "1rem" }}>
          <Container>
            <Typography variant="body2" gutterBottom>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/">
                    <Typography>Home</Typography>
                  </Link>
                  <Link to="/EducationStores">
                    <Typography>Education Stores</Typography>
                  </Link>
                  <Typography color="#572672">{bookstore.title}</Typography>
                </Breadcrumbs>
              </div>
            </Typography>
          </Container>
        </Box>
      </Box>

      <Box sx={{ bgcolor: "background.paper" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <CardMedia
                    component={"img"}
                    sx={{
                      width: "100%",
                      height: 170,
                      my: 2,
                      borderRadius: 2,
                      border: "1px solid #f1f1f1",
                      bgcolor: "#fff",
                    }}
                    src={bookstore.banner}
                    alt=""
                    to="/home"
                  />
                </Grid>
              </Grid>
              <Typography variant="p" color="text.secondary">
                {bookstore.description && parse(bookstore.description)}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                {showButton === 1 ? (
                  <Button
                    className="btn"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Show less" : "Show more"}
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} mt={2} height="fit-content">
              <Box bgcolor="grey.100" borderRadius={4}>
                <Grid>
                  <Grid>
                    {bookstore.rating !== "" ? (
                      <Typography variant="body2" gutterBottom>
                        <Grid
                          container
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={2} md={2}>
                            <Tooltip title="Rating">
                              <IconButton aria-label="rating">
                                <StarIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={10} md={10}>
                            <Typography variant="body2" gutterBottom>
                              {bookstore.rating} Stars{" "}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    ) : (
                      ""
                    )}

                    {bookstore.address1 !== "" ? (
                      <Typography variant="body2" gutterBottom>
                        <Grid
                          container
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={2} md={2}>
                            <Tooltip title="Address">
                              <IconButton aria-label="address">
                                <LocationOnIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={10} md={10}>
                            <Typography variant="body2" gutterBottom>
                              Dubai
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {bookstore.tel !== "" ? (
                      <Typography variant="body2" gutterBottom>
                        <Grid
                          container
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={2} md={2}>
                            <Tooltip title="Teliphone">
                              <IconButton aria-label="Teliphone">
                                <CallIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={10} md={10}>
                            <Typography variant="body2" gutterBottom>
                              {showNumber === 0 ? (
                                <span
                                  className="cp"
                                  onClick={() => ShowNumber()}
                                >
                                  Show number
                                </span>
                              ) : (
                                bookstore.tel
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {bookstore.website !== "" ? (
                      <Typography
                        className="cp_bg"
                        variant="body2"
                        gutterBottom
                      >
                        <Grid
                          container
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={2} md={2}>
                            <Link target="_blank">
                              <Tooltip title="Website">
                                <IconButton aria-label="Website">
                                  <LanguageIcon />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          </Grid>
                          <Grid item xs={10} md={10}>
                            <Typography variant="body2" gutterBottom>
                              <a target="_blank" rel="noreferrer" href={bookstore.website}>
                                Store Website
                              </a>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Stack direction="row" spacing={1}>
                          <Link target="_blank" to="">
                            <Tooltip title="Instagram">
                              <IconButton aria-label="Instagram saaeh">
                                <InstagramIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <Link target="_blank" to="">
                            <Tooltip title="Twitter">
                              <IconButton aria-label="Twitter saaeh">
                                <TwitterIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <Link target="_blank" to="">
                            <Tooltip title="Facebook">
                              <IconButton aria-label="Facebook saaeh">
                                <FacebookIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <Link target="_blank" to="">
                            <Tooltip title="LinkedIn">
                              <IconButton aria-label="LinkedIn saaeh">
                                <LinkedInIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <Link target="_blank" to="">
                            <Tooltip title="YouTube">
                              <IconButton aria-label="YouTube saaeh">
                                <YouTubeIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </Stack>
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={6}
                      bgcolor="#48C851"
                      color="white"
                      p={1}
                      borderRadius="0px 0px 0px 15px"
                      textAlign="center"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <Typography>
                          <Button color="inherit">Enquiry</Button>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      bgcolor="#434343"
                      color="white"
                      p={1}
                      borderRadius="0px 0px 15px 0px"
                      textAlign="center"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button color="inherit">Own this Business</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} my={5}>
              <Grid item xs={12} md={2} className="filterBox">
                <Box sx={{ p: 3 }}>
                </Box>
                <Grid item xs={12} md={12} my={2}>
                  <Typography variant="h6" component="div" color="#572672">
                    Book Format
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ display: "flex", gap: 1, color:"GrayText" }}>
                    <Checkbox onChange={(event)=> {FormatChange(event,1)}} sx={{ p: 0 }} /> Audio CD{" "}
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ display: "flex", gap: 1, color:"GrayText" }}>
                    <Checkbox onChange={(event)=> {FormatChange(event,2)}} sx={{ p: 0 }} />Board Book{" "}
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ display: "flex", gap: 1, color:"GrayText" }}>
                    <Checkbox onChange={(event)=> {FormatChange(event,3)}} sx={{ p: 0 }} />Hardcover{" "}
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ display: "flex", gap: 1, color:"GrayText" }}>
                    <Checkbox onChange={(event)=> {FormatChange(event,4)}} sx={{ p: 0 }} />Large Print{" "}
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ display: "flex", gap: 1, color:"GrayText" }}>
                    <Checkbox onChange={(event)=> {FormatChange(event,5)}} sx={{ p: 0 }} />Loose Leaf{" "}
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ display: "flex", gap: 1, color:"GrayText" }}>
                    <Checkbox onChange={(event)=> {FormatChange(event,6)}} sx={{ p: 0 }} />Paperback{" "}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12} my={2}>
                  <Typography variant="h6" component="div" color="#572672">
                    Authors
                  </Typography>
                  {authors.map((author, index) => (
                  <Typography variant="body2" component="p" sx={{ display: "flex", gap: 1, color:"GrayText" }}>
                  <Checkbox onChange={(event)=> {AuthorChange(event,author.id)}} sx={{ p: 0 }} /> {author.title}
                  </Typography>
                  ))}
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography variant="h6" component="div" color="#572672">
                    Book Language
                  </Typography>
                  {languaes.map((row, index) => (
                  <Typography variant="body2" component="p" sx={{ display: "flex", gap: 1, color:"GrayText" }}>
                    <Checkbox onChange={(event)=> {LanaguageChange(event,row.id)}} sx={{ p: 0 }} /> {row.name}{" "}
                  </Typography>
                  ))}
                </Grid>
              </Grid>
            
            <Grid item xs={12} md={10}>
              <Grid container spacing={2} px={2}>
                <Grid item xs={12} md={3}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    component="div"
                    color="#572672"
                    sx={{ display: "flex", gap: 1 }}
                  >
                    All Books
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} alignSelf="center">
                  <Link to="/EducationStores">
                    <Typography
                      variant="body2"
                      gutterBottom
                      component="div"
                      color="gray"
                    >
                      View All Stores 
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
              
              {bookstore.books && bookstore.books.length > 0 ? (
                <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 1.9)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                      stroke: "#025b95",
                    },
                  }),
                }}
                spinner
                text="Loading ..."
              >
                <Grid container>
                  {bookstore.books &&
                    bookstore.books.map((value, index) => {
                      return (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <Box
                            p={1}
                            className="TopUniversities_box"
                          >
                            <Link to={`/EducationBookStoresDetail/${value.id}`}>
                              <Card>
                                <CardMedia
                                  component="img"
                                  height="300"
                                  image={value.images}
                                  alt={value.title}
                                />
                                <Box p={1} className="cp" bgcolor="#fff">
                                  <CardContent>
                                    <Tooltip Tooltip title={value.title}>
                                      <Typography
                                        sx={{ mt: 1.5 }}
                                        gutterBottom
                                        textAlign="start"
                                        variant="subtitle2"
                                        component="div"
                                        className="text_bg"
                                      >
                                        {value.title}
                                      </Typography>
                                    </Tooltip>
                                    <Typography
                                      className="text_bg"
                                      textAlign="start"
                                      gutterBottom
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {value.author}
                                    </Typography>
                                    <Typography
                                      className="text_bg"
                                      textAlign="start"
                                      gutterBottom
                                      variant="subtitle2"
                                      color="textSecondary"
                                    >
                                      AED {value.price}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Rating
                                       readOnly 
                                        name="hover-feedback"
                                        value={value.rating}
                                        precision={0.5}
                                        emptyIcon={
                                          <StarIcon
                                            style={{ opacity: 0.55 }}
                                            fontSize="inherit"
                                          />
                                        }
                                      />
                                      {value !== null && (
                                        <Box className="text_bg" sx={{ ml: 2 }}>
                                          {labels[hover !== -1 ? hover : value]}
                                        </Box>
                                      )}
                                    </Box>
                                  </CardContent>
                                </Box>
                              </Card>
                            </Link>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
                </LoadingOverlay>
              ) : (
                <Typography variant="h4" color={"GrayText"} p={10} component="p">
                  No Books Found
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </div>
  );
}
