import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import axios from "../Utils/Axios"; //import axios config
import "./HeaderTwo.css";
import logoTaleem from "../images/logo-taleem.webp";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useAlert } from "react-alert";
import { AuthContext } from "../Context/AuthContext";
import AccountMenu from "../Registration/AccountMenu";
import { Badge, Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ThemeProvider } from "@mui/material/styles";
// import SaaehListMenu from "./SaaehListMenu";
import EducationMenu from "./EducationMenu";
import EducationMenuMobile from "../Header/EducationMenuMobile";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";

const useStyles = makeStyles((theme) => ({
  loginIcon: {
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "8px",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  home_language_switch: {
    border: "solid 1px #B5B5B5",
    width: "35px",
    height: "35px",
  },
  select_city: {
    margin: theme.spacing(1),
    marginBottom: "auto",
    padding: "0px 20px 0px 10px",
    minWidth: 120,
  },
  dc: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ddc: {
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
}));

export default function HeaderTwo({ logo }) {
  const theme = useTheme();
  const { open5, setOpen5, login, setLogin } = useContext(AuthContext);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const history = useHistory();
  const alert = useAlert();
  const [password, setPassword] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(0);
  const [keyword, setKeyword] = React.useState("");
  const [autoData, setAutoData] = React.useState([]);
  const [profile, setProfile] = React.useState([]);
  const [cartCount, setCartCount] = React.useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);
    formdata.append("showPassword", showPassword);
    axios("Login/login", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (!response.data.error) {
          localStorage.setItem(
            "data",
            JSON.stringify(response.data.data.api_key)
          );
          setOpen5(false);
          alert.success("Login Successfull");
        } else if (response.data.exist === 1) {
          alert.error(response.data.message);

          setShowPassword(1);
        } else if (response.data.exist === 0) {
          setShowPassword(0);
          history.push({
            pathname: "/Registration",
            state: { data: true, email: username },
          });
        } else {
          alert.error(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const logOut = () => {
    localStorage.removeItem("data");
    setLogin(false);
    history.push({
      pathname: "/",
      state: { data: true },
    });
  };

  React.useEffect(() => {
    getSearchAutocomplete();
    getProfile();
    getCartCount();
  }, [keyword]);
  React.useEffect(() => {
    getProfile();
  }, [JSON.parse(localStorage.getItem("data"))]);

  const filterSearch = () => {
    history.push({
      pathname: `/EducationLisiting`,
      search: `?keyword=${keyword}`,
    });
  };

  const getProfile = () => {
    axios(`Home/get_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getSearchAutocomplete = () => {
    if (keyword && keyword != "") {
      axios(`Home/search_autocomplete_data?keyword=${keyword}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoData([]);
    }
  };

  const getCartCount = () => {
    axios(`Cart/getCartCount`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setCartCount(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ></Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Box className="c">
        {/* <SaaehListMenu /> */}
        <EducationMenuMobile />

        {localStorage.getItem("data") || login ? (
          <Box className="header_two_menu_box" mt={2}>
            <Button variant="standard" color="inherit">
              {profile.fullname}
            </Button>

            <Button variant="standard" color="#fff" onClick={logOut}>
              <LogoutIcon />
            </Button>
          </Box>
        ) : (
          <Box className="header_two_menu_box">
            <Link to="/Login">
              <Typography color="white" variant="subtitle2">
                Join with Sa`aeh{" "}
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
      {/* <AccountMenu /> */}
      <Box ml={2}>
        <MenuItem>
          <Link to="/MyAccount">My Account </Link>
        </MenuItem>
        <Link to="/Settings">
          <MenuItem>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
        </Link>
      </Box>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <Box className="header_two_bg">
        <AppBar
          elevation24
          position="fixed"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <Toolbar>
            <Link to="/education">
              <Box
                component={"img"}
                p={1}
                width={170}
                src={logoTaleem}
                alt=""
                to="/home"
              />
            </Link>
            <Box display={{ xs: "none", md: "flex" }}>
              {/* <SaaehListMenu /> */}
              <EducationMenu />
            </Box>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Box className="header_two_cart_box">
                <ThemeProvider theme={theme}>
                  <Link to="/Cart">
                    <IconButton
                      size="large"
                      aria-label="show 4 new mails"
                      color="white"
                    >
                      <Badge badgeContent={cartCount} color="error">
                        <ShoppingCartIcon color="white" />
                      </Badge>
                    </IconButton>{" "}
                  </Link>
                </ThemeProvider>
              </Box>

              <Box className="header_two_menu_box">
                {localStorage.getItem("data") || login ? (
                  <Box className="header_two_menu_box"></Box>
                ) : (
                  <Box p={2} className="header_two_menu_box">
                    <Link to="/Login">
                      <Typography color="white" variant="subtitle2">
                        Join with Sa`aeh{" "}
                      </Typography>
                    </Link>
                  </Box>
                )}
              </Box>
              {localStorage.getItem("data") || login ? (
                <AccountMenu
                  fullaname={profile && profile.fullname}
                  logo={profile && profile.profile_image}
                /> 
              ) : (
                ""
              )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
