import React from "react";
import Footer from "../Footer/Footer";
//import HeaderTwo from "../HeaderTwo/HeaderTwo";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import {Box,Card,CardContent,CardMedia,Container,Tooltip,Typography,} from "@mui/material";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import Header from "../Header/Header";

export default function EducationStores() {
  React.useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    []
  );
  const [stores, setStores] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [loadfooter, setloadfooter] = React.useState(false);


  const storeBooksList = () => {
    setLoading(true);
    axios(`Bookstore/get_all_store?division=1&category_id=1`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setStores(res.data.data); //to set response data to state
        }
        setloadfooter(true);
      })
      .catch((err) => console.log(err));
  };


  React.useEffect(() => {
    storeBooksList();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <HeaderTwo /> */}
      <Header />
      <Box sx={{ pt: "100px", pb: "48px" }}>
        <Container>
          <Typography
            variant="h4"
            color="#572672"
            fontWeight={600}
            gutterBottom
          >
            All Stores
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
          <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {stores && stores.map((value, index) => {
                return (
                <Grid item xs={2} sm={3} md={3} key={index}>
                  <Box p={1} className="TopUniversities_box">
                    <Link to={`/EducationStoresDetails/${value.id}`}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="300"
                          image={value.banner}
                          alt="title"
                        />
                        <Box p={1} className="cp">
                          <CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CardMedia
                              sx={{ width: 80, height: 80, p: 0.5, boxShadow: 3, borderRadius: "50%", overflow: "hidden", Border: "solid 5px #978d8d", mt: -9.5, bgcolor: "#fff" }}
                                component={"img"}
                              
                                src={value.logo}
                                alt=""
                                to="/EducationStoresDetails"
                              />
                            </Box>
                            <Tooltip Tooltip title="store name">
                              <Typography
                                sx={{ mt: 1.5 }}
                                gutterBottom
                                variant="subtitle2"
                                component="div"
                                textAlign="start"
                                className="text_bg"
                              >
                                {value.title}
                              </Typography>
                            </Tooltip>
                          </CardContent>
                        </Box>
                      </Card>
                    </Link>
                  </Box>
                </Grid>
                    )})}
            </Grid>
            </LoadingOverlay>
          </Box>
        </Container>
      </Box>
      {loadfooter && <Footer />}
    </div>
  );
}
