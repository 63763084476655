import React from "react";
import Footer from "../Footer/Footer";
//import HeaderTwo from "../HeaderTwo/HeaderTwo";
import Header from "../Header/Header";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useHistory } from "react-router-dom";
import {Box,Container,Grid,TextField,Typography,Button,Autocomplete,createTheme,ThemeProvider,styled,Paper,
  CardActions,Checkbox,Card,CardContent,IconButton,Stack,} from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#572672",
    },
    secondary: {
      main: "#3b184e",
    },
    white: {
      main: "#fff",
    },
  },
});

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Checkout() {
  const alert = useAlert();
  const [loading, setLoading] = React.useState(true);
  const [countriesss, setCountires] = React.useState([]);
  const [country, set_country] = React.useState("");
  const [name, set_name] = React.useState("");
  const [mobile, set_mobile] = React.useState("");
  const [city, set_city] = React.useState("");
  const [area, set_area] = React.useState("");
  const [zipcode, set_zipcode] = React.useState("");
  const [vat, set_vat] = React.useState("");
  const [address, set_address] = React.useState("");
  const [checkedItem, setcheckedItem] = React.useState(0);
  const [orderId, setOrderId] = React.useState();
  const [useraddress, setUseraddress] = React.useState([]);
  const [addressid, setaddressid] = React.useState("");
  const [editaddress, setEditaddress] = React.useState({});
  const history = useHistory();

  React.useEffect(() => {
    getUseraddress();
    getCountries();
  }, []);

  const getUseraddress = () => {
    setLoading(true);
    axios(`Profile/get_user_address`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          setLoading(false);
          setcheckedItem(0);
        } else {
          setLoading(false);
          setUseraddress(res.data.data);
          setcheckedItem(res.data.data[0].id);
        }
      })
      .catch((err) => console.log(err));
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen2 = (id) => {
    setOpen2(true);

    setLoading(true);
    axios(`Profile/edit_user_address?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          setLoading(false);
        } else {
          console.log(res.data.data);
          setEditaddress(res.data.data);
          setaddressid(res.data.data.id);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const getCountries = () => {
    axios(`Settings/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCheck = (index, checked) => {
    if (checked) {
      setcheckedItem(index);
    }
  };

  const getOrderId = () => {
    if (JSON.parse(localStorage.getItem("dian_order"))) {
      setOrderId(JSON.parse(localStorage.getItem("dian_order")));
    } else {
      setOrderId(0);
    }
  };

  React.useEffect(() => {
    getCountries();
    getOrderId();
    window.scrollTo(0, 0);
  }, []);

  const addAddress = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("country", country);
    formdata.append("name", name);
    formdata.append("mobile", mobile);
    formdata.append("city", city);
    formdata.append("area", area);
    formdata.append("address", address);
    formdata.append("zipcode", zipcode);
    formdata.append("vat", vat);

    axios(`Profile/add_address`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          getUseraddress();
          alert.success(res.data.message);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const addplaceorder = (event) => {
    console.log(checkedItem);
    if (checkedItem === 0) {
      alert.error("Add Shipping Address");
    } else {
      event.preventDefault();
      let formdata = new FormData();
      formdata.append("checkedItem", checkedItem);
      formdata.append("order_id", orderId);
      axios(`Orders/add_placeorder`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status) {
            console.log(res.data.data);
            localStorage.removeItem("dian_order");
            localStorage.setItem("dian_order", JSON.stringify(res.data.data));
            getOrderId();
            history.push({
              pathname: "/Review",
            });
          } else {
            //alert.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const editAddress = (addressid) => {
    let formdata = new FormData();
    formdata.append("addressid", addressid);
    formdata.append("name", editaddress.name);
    formdata.append("mobile", editaddress.mobile_no);
    formdata.append("country", editaddress.country);
    formdata.append("city", editaddress.city);
    formdata.append("area", editaddress.area);
    formdata.append("address", editaddress.address);
    formdata.append("zipcode", editaddress.zipcode);
    formdata.append("vat_number", editaddress.vat_number);
    setLoading(true);
    axios(`Profile/update_address`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setOpen2(false);
          setLoading(false);
          getUseraddress();
        } else {
          alert.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {/* <HeaderTwo /> */}
      <Header />
      <ThemeProvider theme={theme}>
        <Box
          sx={{ flexGrow: 1, bgcolor: "#f2f2f2" }}
          mt={5}
          py={{ xs: "50px", sm: "74px", md: "90px", lg: "90px" }}
        >
          <Container maxWidth="lg">
            <Grid item xs={12} md={8} m="auto">
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          p: 1,
                          m: 1,
                          bgcolor: "background.paper",
                          borderRadius: 1,
                        }}
                      >
                        <Box>
                          <Typography variant="h6">Shipping Address</Typography>
                        </Box>

                        <Box>
                          <Button variant="outlined" onClick={handleClickOpen}>
                            Add new Address
                          </Button>
                          <Dialog open={open} onClose={handleClose}>
                            <DialogTitle> Add new Address</DialogTitle>
                            <DialogContent>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                container
                                spacing={2}
                                py={1}
                              >
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    label="Full Name"
                                    variant="outlined"
                                    size="small"
                                    value={name}
                                    onChange={(e) => set_name(e.target.value)}
                                  />
                                </Grid>

                                <Grid item xs={12} md={6} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="Mobile"
                                    variant="outlined"
                                    size="small"
                                    value={mobile}
                                    onChange={(e) => set_mobile(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                  <Autocomplete
                                    fullWidth
                                    size="small"
                                    id="country-select-demo"
                                    options={countriesss}
                                    autoHighlight
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, value) =>
                                      value && set_country(value.id)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Choose a country"
                                        variant="outlined"
                                        value={countriesss}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="City"
                                    variant="outlined"
                                    size="small"
                                    value={city}
                                    onChange={(e) => set_city(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="Area"
                                    variant="outlined"
                                    size="small"
                                    value={area}
                                    onChange={(e) => set_area(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="Zipcode"
                                    variant="outlined"
                                    size="small"
                                    value={zipcode}
                                    onChange={(e) =>
                                      set_zipcode(e.target.value)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                  <TextField
                                    fullWidth
                                    label="Vat Number (Company Only)"
                                    variant="outlined"
                                    size="small"
                                    value={vat}
                                    onChange={(e) => set_vat(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                  <TextField
                                    label="Address - Bldg No./ Landmark / Streat"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    maxRows={4}
                                    value={address}
                                    onChange={(e) =>
                                      set_address(e.target.value)
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Cancel</Button>
                              <Button variant="contained" onClick={addAddress}>
                                Submit
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} container>
                      <Box sx={{ mt: 1 }}>
                        <LoadingOverlay
                          active={loading}
                          styles={{
                            overlay: (base) => ({
                              ...base,
                              background: "rgba(255, 255, 255, 1.9)",
                            }),
                            spinner: (base) => ({
                              ...base,
                              width: "75px",
                              "& svg circle": {
                                stroke: "#025b95",
                              },
                            }),
                          }}
                          spinner
                          text="Loading ..."
                        >
                          {useraddress && useraddress.length > 0 ? (
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignContent: "flex-start",
                                  p: 1,
                                  m: 1,
                                  bgcolor: "background.paper",
                                  borderRadius: 1,
                                }}
                              >
                                {useraddress.map((row, index) => {
                                  return (
                                    <Item sx={{ m: 1, bgcolor: "#f9f9f9" }}>
                                      <Box
                                        sx={{
                                          height: "100%",
                                          display: "flex",
                                          flexDirection: "row",
                                          textAlign: "left",
                                          maxWidth: 320,
                                        }}
                                      >
                                        <CardContent
                                          sx={{ flexGrow: 1, maxWidth: 164 }}
                                        >
                                          <Typography
                                            component="div"
                                            gutterBottom
                                            variant="p"
                                            color="GrayText"
                                          >
                                            {row.name}
                                          </Typography>
                                          <Typography
                                            component="div"
                                            gutterBottom
                                            variant="p"
                                            color="GrayText"
                                          >
                                            {row.address1}
                                          </Typography>
                                          <Typography
                                            component="div"
                                            gutterBottom
                                            variant="p"
                                            color="GrayText"
                                          >
                                            {row.city} , {row.area} ,{" "}
                                            {row.zipcode} , {row.country}
                                          </Typography>
                                          <Typography
                                            component="div"
                                            gutterBottom
                                            variant="p"
                                            color="GrayText"
                                          >
                                            {row.mobile_no}
                                          </Typography>
                                          <Typography
                                            component="div"
                                            gutterBottom
                                            variant="p"
                                            color="GrayText"
                                          >
                                            <span fontweight="800">
                                              Vat No :
                                            </span>{" "}
                                            {row.vat}
                                          </Typography>
                                        </CardContent>
                                        <CardActions>
                                          <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Checkbox
                                              id={index}
                                              {...label}
                                              checked={
                                                row.id === checkedItem
                                                  ? true
                                                  : false
                                              }
                                              size="large"
                                              onChange={(e) =>
                                                handleCheck(
                                                  row.id,
                                                  e.target.checked
                                                )
                                              }
                                            />

                                            <IconButton>
                                              <EditIcon
                                                color="primary"
                                                onClick={() =>
                                                  handleClickOpen2(row.id)
                                                }
                                              />
                                            </IconButton>
                                          </Grid>
                                        </CardActions>
                                      </Box>
                                    </Item>
                                  );
                                })}
                              </Box>
                            </div>
                          ) : (
                            <Item>
                              <Typography variant="h6" gutterBottom>
                                No address found
                              </Typography>
                            </Item>
                          )}
                        </LoadingOverlay>
                      </Box>

                      <div>
                        <Dialog open={open2} onClose={handleClose2}>
                          <DialogTitle> Edit Address</DialogTitle>
                          <DialogContent>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Autocomplete
                                  size="small"
                                  fullWidth
                                  multiple
                                  id="country-select-demo2"
                                  value={countriesss.filter(
                                    (countriesss) =>
                                      countriesss.id === editaddress.country
                                  )}
                                  options={countriesss}
                                  autoHighlight
                                  onChange={(e, value) => {
                                    setEditaddress({
                                      ...editaddress,
                                      country:
                                        value.length > 1
                                          ? value[1].id
                                          : value.length == 1
                                          ? editaddress.country != 0
                                            ? editaddress.country
                                            : value[0].id
                                          : 0,
                                    });
                                  }}
                                  getOptionLabel={(option) => option.title}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Choose a country"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Name"
                                  variant="outlined"
                                  size="small"
                                  value={editaddress.name}
                                  onChange={(e) => {
                                    setEditaddress({
                                      ...editaddress,
                                      name: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Mobile"
                                  variant="outlined"
                                  size="small"
                                  value={editaddress.mobile_no}
                                  onChange={(e) => {
                                    setEditaddress({
                                      ...editaddress,
                                      mobile: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  placeholder="City"
                                  variant="outlined"
                                  size="small"
                                  value={editaddress.city}
                                  onChange={(e) => {
                                    setEditaddress({
                                      ...editaddress,
                                      city: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Area"
                                  variant="outlined"
                                  size="small"
                                  value={editaddress.area}
                                  onChange={(e) => {
                                    setEditaddress({
                                      ...editaddress,
                                      area: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Zipcode"
                                  variant="outlined"
                                  size="small"
                                  value={editaddress.zipcode}
                                  onChange={(e) => {
                                    setEditaddress({
                                      ...editaddress,
                                      zipcode: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Vat Number (Company Only)"
                                  variant="outlined"
                                  size="small"
                                  value={editaddress.vat_number}
                                  onChange={(e) => {
                                    setEditaddress({
                                      ...editaddress,
                                      vat_number: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  placeholder="Address - Bldg No./ Landmark / Streat"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  maxRows={4}
                                  value={editaddress.address}
                                  onChange={(e) => {
                                    setEditaddress({
                                      ...editaddress,
                                      address: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose2}>Cancel</Button>
                            <Button
                              variant="contained"
                              onClick={() => editAddress(addressid)}
                            >
                              Submit
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <DialogActions className="cartbg">
                <Stack spacing={2} direction="row">
                  <Link to="/Cart">
                    <Button variant="outlined">Back to Cart</Button>
                  </Link>
                  <Button
                    className="cart-primary"
                    variant="contained"
                    onClick={addplaceorder}
                  >
                    Next
                  </Button>
                </Stack>
              </DialogActions>
            </Grid>
          </Container>
        </Box>
      </ThemeProvider>
      <Footer />
    </div>
  );
}
