import React from "react";
import Footer from "../Footer/Footer";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Header from "../Header/Header";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Map from "./Map";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import LoadingOverlay from "react-loading-overlay";
import Pagination from "@mui/material/Pagination";
import { Container, Grid } from "@mui/material";

export default function EducationLisiting(props) {
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const search = props.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  const [location, setLocation] = React.useState(
    params.get("location") ? params.get("location") : ""
  );
  const [institute_type, setInstitute_type] = React.useState(
    params.get("institute_type") ? params.get("institute_type").split(",") : 1
  );
  const [keyword, setKeyword] = React.useState(
    params.get("keyword") ? params.get("keyword") : ""
  );
  const [lists, setlists] = React.useState([]);
  const [typelists, setTypeList] = React.useState([]);
  const [citylan, setcitylan] = React.useState([]);
  const [citylon, setcitylon] = React.useState([]);

  React.useEffect(() => {
    education_list();
    typeList();
  }, [page, rowsPerPage, location, institute_type]);

  const setJob_typeSelect = async (val) => {
    setInstitute_type(val);
  };

  const education_list = () => {
    setLoading(true);
    axios(
      `Institution/institutionList?page=${page}&per_page=${rowsPerPage}&location=${location}&keyword=${keyword}&institute_type=${institute_type}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        window.scrollTo(0, 0);
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setlists([]);
            setTotal(res.data.total);
          } else {
            setLoading(false);
            setlists(res.data.data);
            setTotal(res.data.total);
            if (res.data.data.length > 0) {
              setcitylan(res.data.data[0].city_lan);
              setcitylon(res.data.data[0].city_lon);
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  //method for getting recent jobs
  const typeList = () => {
    setLoading(true);
    axios(`Home/typeList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTypeList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const InstituteView = (id, type) => {
    if (type === 3) {
      history.push({
        pathname: `/EducationExpertDetail/${id}`,
      });
    } else {
      history.push({
        pathname: `/EducationDetail/${id}`,
      });
    }
    //}
  };

  const listingView = (location) => {
    history.push({
      pathname: `/EducationLisitingMap`,
      search: `?location=${location}`,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      {/* <HeaderThree /> */}
      <Header /> 
      <Grid container spacing={3} pt={8}>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Container>
            <Typography variant="h6" pt={5} gutterBottom component="div">
              {lists[0] && lists[0].no} in {lists[0] && lists[0].city}
            </Typography>

            <Typography
              variant="body2"
              color="GrayText"
              display="block"
              gutterBottom
            >
              List of institutes in {lists[0] && lists[0].city}
            </Typography>
        
            <Box className="education_listing_btn_box" color="GrayText">
              <Stack  spacing={1} gap={2} py={2} direction="row" useFlexGap flexWrap="wrap"
             //  spacing={1}
             //   direction="row"
             //   flexWrap="wrap"
             //   width="100%"
            //    maxWidth="660px"
            //    overflow="auto"
              >
                {typelists.map((value, index) => {
                  return (
                      <Button
                        onClick={() => setJob_typeSelect(value.id)}
                        variant="outlined" 
                      >
                        {value.title}  
                      </Button>
                  );
                })}
                  <Button onClick={() => listingView(location)} variant="outlined">Filter</Button>
              </Stack>
              
            </Box>
            
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {lists.length > 0 ? (
                <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                //   sx={{
                //     display: "flex",
                //     flexWrap: "wrap",
                //     width: "100%",
                // //    maxWidth: "670px",
                //     height: "62vh",
                //     overflow: "auto",
                //     mt: "10px",
                //   }}
                >
                  {lists.slice(0, 4).map((value, index) => {
                    return (
                      <Grid item xs={12} md={6} 
                     //   className="EducationDetailNearby"
                      //  sx={{ maxWidth: 309 }}
                      //  p={1}
                        onClick={() => InstituteView(value.id, value.type)}
                      >
                        <CardMedia sx={{ borderRadius: "10px"}}
                          component="img"
                          width="100%"
                          height="215"
                          image={value.banner}
                          alt=" Business Education Trainer"
                        />
                        <Box p={1}>
                          <Typography
                           // sx={{ mt: 1.5 }}
                            sx={{
                              mt: 1.5,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                           }}
                            gutterBottom
                            variant="subtitle2"
                            color="#3f4042"
                            component="div"
                          >
                            {value.title}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5 }}
                            variant="body2"
                            color="text.secondary"
                          >
                            {value.city}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "670px",
                  }}
                >
                  <Box p={5} paddingBottom="105px" className="error">
                    <Typography variant="h6" color="primary">
                      <span className="error_text">No data</span> found.
                    </Typography>
                  </Box>
                </Box>
              )}
            </LoadingOverlay>

            <Box p={2} display="flex" justifyContent="center">
              <Stack spacing={2}>
                <Pagination count={total} page={page} onChange={handleChange} />
              </Stack>
            </Box>
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Map lists={lists} citylan={citylan} citylon={citylon} />
          </LoadingOverlay>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}
