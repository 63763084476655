import {Avatar,Box,Button,Card,CardContent,Container,createTheme,DialogActions,Divider,Grid,List,ListItem,ListItemAvatar,ListItemText,Stack,ThemeProvider,Typography,} from "@mui/material";
import React from "react";
import Footer from "../Footer/Footer";
import HeaderTwo from "../HeaderTwo/HeaderTwo";
import { Link,useHistory } from "react-router-dom";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";

const theme = createTheme({
  palette: {
    primary: {
      main: "#572672",
    },
    secondary: {
      main: "#3b184e",
    },
    white: {
      main: "#fff",
    },
  },
});
export default function Review() {


  const history = useHistory();
  const [orderdata, setOrderData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [orderId, setOrderId] = React.useState(
    JSON.parse(localStorage.getItem("dian_order"))
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getOrderDeatils();
    const last_order= (localStorage.getItem("dian_order") ? localStorage.getItem("dian_order") : "");
      if(last_order==="" ){
        window.location.replace("/");
      }
  }, []);

  console.log(orderId);
  const getOrderId = () => {
    if (JSON.parse(localStorage.getItem("dian_order"))) {
      setOrderId(JSON.parse(localStorage.getItem("dian_order")));
    } else {
      setOrderId(0);
    }
  };

  const getOrderDeatils = () => {
    setLoading(true);
    axios(
      `Orders/getOrderDeatils?order_id=${JSON.parse(localStorage.getItem("dian_order"))}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setOrderData([]);
          } else {
            setLoading(false);
            setOrderData(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const addplaceorder = (event) => {
    
      event.preventDefault();
      let formdata = new FormData();
      axios(`Orders/successOrderDeatils`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status) {
            console.log(res.data.data);
            localStorage.removeItem("dian_order");
            localStorage.setItem("dian_order", JSON.stringify(res.data.data.id));
            getOrderId();
            history.push({
              pathname: "/Success",
            });
          } else {
            //alert.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    };

 
  return (
    <div>
      <HeaderTwo />
      <ThemeProvider theme={theme}>
        <Box
          sx={{ flexGrow: 1, bgcolor: "#f2f2f2" }}
          mt={5}
          py={{ xs: "50px", sm: "74px", md: "90px", lg: "90px" }}
        >
          <Container maxWidth="lg">
            <Grid item xs={12} md={8} m="auto">
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Review your order
                          </Typography>
                          <Divider sx={{ py: 0.5 }} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <LoadingOverlay
                        active={loading}
                        styles={{
                          overlay: (base) => ({
                            ...base,
                            background: "rgba(255, 255, 255, 1.9)",
                          }),
                          spinner: (base) => ({
                            ...base,
                            width: "75px",
                            "& svg circle": {
                              stroke: "#025b95",
                            },
                          }),
                        }}
                        spinner
                        text="Loading ..."
                      >
                      <Grid item xs={12} md={12} container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Typography variant="h6">Order summary</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Card sx={{ bgcolor: "#f9f9f9" }}>
                            <CardContent>
                              <List disablePadding>

                              {orderdata.orderitems &&
                                orderdata.orderitems.map((product) => (
                                  <ListItem key={product.name} sx={{ py: 1, px: 0, pr: 1 }}>
                                    <ListItemAvatar>
                                      <Avatar src={product.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={product.name}
                                      secondary={product.qty}
                                    />
                                    <Typography variant="body2">
                                      {orderdata.currency} {product.price}
                                    </Typography>
                                  </ListItem>
                                ))}

                                  <ListItem sx={{ py: 1, px: 0 }}>
                                    <ListItemText primary="Net" />
                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                                      {orderdata.currency} {orderdata.gross}
                                    </Typography>
                                  </ListItem>

                                  {orderdata.discount > 0 && (
                                  <ListItem sx={{ py: 1, px: 0 }}>
                                  <ListItemText primary="Discount" />
                                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                                    {orderdata.currency} {orderdata.discount}
                                  </Typography>
                                  </ListItem>
                                  )}

                                  <ListItem sx={{ py: 1, px: 0 }}>
                                    <ListItemText primary="Total" />
                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                                      {orderdata.currency} {orderdata.net}
                                    </Typography>
                                  </ListItem>
                              </List>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={12} md={12}>
                            <Typography variant="h6" gutterBottom>
                              Shipping address
                            </Typography>
                          </Grid>
                          <Card sx={{ bgcolor: "#f9f9f9" }}>
                            <CardContent>
                              <Grid container spacing={2}>
                              <Grid
                                    item
                                    sx={{
                                      position: "relative",
                                      overflow: "auto",
                                      textAlign: "Start",
                                      maxHeight: 300,
                                      "& ul": { padding: 0 },
                                    }}
                                    subheader={<li />}
                                  >
                                    <Typography gutterBottom>
                                      {orderdata.address && orderdata.address.name}
                                    </Typography>
                                    <Typography gutterBottom>
                                      {orderdata.address && orderdata.address.address}
                                    </Typography>
                                    <Typography gutterBottom>
                                      {orderdata.address && orderdata.address.country},{" "}
                                      {orderdata.address && orderdata.address.city}
                                    </Typography>
                                    <Typography gutterBottom>
                                      {orderdata.address && orderdata.address.mobile_no}
                                    </Typography>
                                  </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      </LoadingOverlay>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <DialogActions>
              <Stack spacing={2} direction="row">
                <Link to="/Checkout">
                  <Button variant="outlined">Back </Button>
                </Link>
                <Button variant="contained" onClick={addplaceorder}>Place Order</Button>
                </Stack> 
              </DialogActions>
            </Grid>
          </Container>
        </Box>
      </ThemeProvider>

      <Footer />
    </div>
  );
}
