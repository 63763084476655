import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CardContent, Grid, Stack, autocompleteClasses } from "@mui/material";
import Card from "@mui/material/Card";
import parse from "html-react-parser";

export default function EducationGuide() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    newsList(newValue);
  };

  const [loading, setLoading] = React.useState(false);
  const [typelist, setTypeList] = React.useState([]);
  const [news, setNews] = React.useState([]);
  React.useEffect(() => {
    catList();
    newsList(value);
  }, []);

  React.useEffect(() => {
    newsList(value);
  }, [value]);

  const newsList = (value) => {
    setLoading(true);
    axios(`Article/get_news_category_byid?category_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          console.log(res.data.data[0]);
          setNews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const catList = () => {
    setLoading(true);
    axios(`Article/catList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTypeList(res.data.data); //to set response data to state
          console.log(res.data.data[0].id);
          setValue(res.data.data[0].id);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        className="caro_bg"
        sx={{ bgcolor: "#faf0fc", pt: "48px", pb: "48px" }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3} pb={3} p={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              lg={3}
              sx={{ display: "flex", alignItems: "center", gap: 3 }}
            >
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="#572672"
                noWrap
              >
                News and Articles
              </Typography>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
              <Link to="/EducationGuideArticles">
                <Typography
                  noWrap
                  className="cp"
                  variant="body2"
                  color="InactiveCaptionText"
                  gutterBottom
                  component="div"
                >
                  View All
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={7} lg={9} className="TopUniversities_bg">
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
                aria-label="secondary tabs example"
              >
                {typelist &&
                  typelist.map((value, index) => {
                    return <Tab value={value.id} label={value.title} />;
                  })}
              </Tabs>
            </Grid>
          </Grid>

          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Box p={2} className="TopUniversities_carousel_bg">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={4}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 5,
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: "100%",
                        maxHeight: "200px",
                      }}
                      image={news[0] && news[0].images}
                      alt="Saaeh"
                    />
                    <CardContent sx={{ flexGrow: 1, bgcolor: "#fff" }}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="h6"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {news[0] && parse(news[0].title.substring(0, 200) + "")}
                      </Typography>
                      <Stack
                        spacing={{ xs: 1, sm: 2 }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        useFlexGap
                        flexWrap="wrap"
                      >
                        <Typography color="gray" variant="body2">
                          {news[0] && news[0].created}
                        </Typography>
                        <Link
                          to={`/EducationGuideArticlesDetail/${
                            news[0] && news[0].id
                          }`}
                        >
                          <Typography
                            display="block"
                            textAlign="right"
                            color="primary"
                            variant="p"
                            fontSize="small"
                          >
                            More
                          </Typography>
                        </Link>{" "}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {news[0] &&
                      news[0].datas.map((data, index) => (
                        <Grid item xs={12} sm={12} md={6} key={index}>
                          <Card
                            sx={{
                              height: "100%",
                              display: "flex",
                              borderRadius: 5,
                              flexDirection: {
                                xs: "column",
                                sm: "row",
                              },
                            }}
                          >
                            <CardContent sx={{ flexGrow: 1, bgcolor: "#fff" }}>
                              <Grid container spacing={2}>
                                <Grid item>
                                  <CardMedia
                                    component="img"
                                    //  width="150"
                                    //  height="150"
                                    alt="Taleem"
                                    src={data.images}
                                    sx={{
                                      borderRadius: 3,
                                      width: { xs: "100%", sm: 160 },
                                      mr: { sm: 1.5 },
                                      mb: { xs: 1.5, sm: 0 },
                                    }}
                                  />
                                  {/* <ButtonBase sx={{ width: 200, height: 128 }}>
                                    <Img
                                      alt="complex"
                                      src={data.images}
                                      sx={{ borderRadius: 1,  width: "100%",
                                      maxHeight: "200px", }}
                                    />
                                  </ButtonBase> */}
                                </Grid>
                                <Grid item xs={12} sm container>
                                  <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    spacing={2}
                                  >
                                    <Grid item xs>
                                      <Typography
                                        variant="subtitle2"
                                        component="div"
                                        sx={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          display: "-webkit-box",
                                          WebkitLineClamp: "2",
                                          WebkitBoxOrient: "vertical",
                                        }}
                                      >
                                        {parse(
                                          data.title.substring(0, 60) + " "
                                        )}
                                      </Typography>
                                      <Stack
                                        spacing={{ xs: 1, sm: 2 }}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        useFlexGap
                                        flexWrap="wrap"
                                      >
                                        <Typography
                                          color="gray"
                                          variant="body2"
                                        >
                                          {news[0] && news[0].created}
                                        </Typography>
                                        <Typography
                                          sx={{ cursor: "pointer" }}
                                          variant="body2"
                                        >
                                          <Link
                                            to={`/EducationGuideArticlesDetail/${data.id}`}
                                          >
                                            <Typography
                                              color="primary"
                                              fontSize="small"
                                              textAlign="right"
                                            >
                                              More
                                            </Typography>
                                          </Link>
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </LoadingOverlay>
        </Container>
      </Box>
    </div>
  );
}
