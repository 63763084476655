import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HeaderFour from "../HeaderFour/HeaderFour";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import CustomeFieldDialogEdit from "./CustomeFieldDialogEdit";
import CustomeFieldDialog from "./CustomeFieldDialog";
import image2 from "../images/logo.webp";
import {
  Autocomplete,
  createTheme,
  Card,
  CardContent,
  Tabs,
  Tab,
  ThemeProvider,
  Divider,
  Grid,
  Box,
  Paper,
  styled,
  Typography,
  IconButton,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import MyAccountManageListingAddCoursesEditCourseOverviewTextEditor from "./MyAccount_ManageListingAddCoursesEditCourseOverviewTextEditor";
import DeleteConfirmDialog from "./DeleteConfirmDialog";

const theme = createTheme({
  palette: {
    primary: {
      light: "#833baa",
      main: "#572672",
      dark: "#381849",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const columns = [
  { field: "id", headerName: "Action", width: 70 },
  { field: "firstName", headerName: " Name", width: 350 },
  { field: "lastName", headerName: "Category", width: 150 },
  {
    field: "age",
    headerName: "Level",
    type: "number",
    width: 190,
  },
  {
    field: "fullName",
    headerName: "Tutor",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
  {
    field: "type",
    headerName: "Type",
    type: "number",
    width: 190,
  },
];

const rows = [
  {
    id: 1,
    lastName: "Foreign Language ",
    firstName: "Master of Science in Special Education",
    age: "Post graduation",
    type: "Full Time/Part Time",
  },
  {
    id: 2,
    lastName: "Arts & Design",
    firstName: "Master of Education in Educational Leadership",
    age: "Post graduation",
    type: "Full Time/Part Time",
  },
];

// const useStyle = makeStyles({
//   image: {
//     borderRadius: 100,
//   },
// });
const useStyle = makeStyles({
  image: {
    borderRadius: 10,
    maxWidth: "100%",
  },
  image2: {
    borderRadius: 10,
    maxWidth: "100%",
  },
});
const Input = styled("input")({
  display: "none",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

window.scrollTo(0, 0);

export default function MyAccount_ManageListingAddCoursesEdit(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //const [uploadImage, setUploadImage] = useState("");
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  var loadFileBanner = (event) => {
    if (event.target.files[0]) {
      setUploadBanner(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setBanner(event.target.files[0]);
    }
  };

  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [courseName, setCourse_name] = React.useState("");
  const [profile, setProfile] = React.useState([]);
  const [courselevel, setCourseLevel] = React.useState([""]);
  const [coursetutor, setCourseTutor] = React.useState([""]);
  const [coursetype, setCourseType] = React.useState([""]);
  const [coursecategories, setCourseCategory] = React.useState([""]);
  const [insti_crslevel, setInsti_crslevel] = React.useState("");
  const [insti_crstutor, setInsti_crstutor] = React.useState("");
  const [insti_crstype, setInsti_crstype] = React.useState("");
  const [insti_crscategory, setInsti_crscategory] = React.useState("");
  const [uploadImage, setUploadImage] = React.useState("");
  const [uploadBanner, setUploadBanner] = React.useState("");
  const [image, setImage] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [overview, setOverview] = React.useState("");
  const [minfee, setInstit_minfee] = React.useState("");
  const [maxfee, setInstit_maxfee] = React.useState("");

  const [customfields, setCustomlist] = React.useState([]);

  const config = {
    readonly: false,
    height: 400,
  };

  const [course_id] = React.useState(
    props.match && props.match.params && props.match.params.keyid
      ? props.match.params.keyid
      : ""
  );

  const [insti_id] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );

  React.useEffect(() => {
    getProfile();
    getCourseLevel();
    getCourseTutor();
    getCourseType();
    getCourseCategory();
    editPost();
    customList(course_id);
  }, [JSON.parse(localStorage.getItem("data"))]);

  const getProfile = () => {
    axios(`Home/get_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const editPost = () => {
    setLoading(true);
    axios(`Course/get_course_profile?courseId=${course_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error === true) {
        } else {
          setLoading(false);
          setCourse_name(res.data.data.name);
          setInsti_crslevel(res.data.data.level_id);
          setInsti_crstutor(res.data.data.tutor_id);
          setInsti_crstype(res.data.data.type_id);
          setInsti_crscategory(res.data.data.category_id);
          setUploadImage(res.data.data.logo);
          setUploadBanner(res.data.data.banner);
          setOverview(res.data.data.description);
          setInstit_minfee(res.data.data.min_fee);
          setInstit_maxfee(res.data.data.max_fee);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCourseLevel = () => {
    axios(`Course/get_course_level`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCourseLevel(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCourseTutor = () => {
    axios(`Course/get_course_tutor`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCourseTutor(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCourseType = () => {
    axios(`Course/get_course_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCourseType(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCourseCategory = () => {
    axios(`Course/get_course_category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCourseCategory(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("courseId", course_id);
    formdata.append("courseName", courseName);
    formdata.append("insti_crslevel", insti_crslevel);
    formdata.append("insti_crstutor", insti_crstutor);
    formdata.append("insti_crscategory", insti_crscategory);
    formdata.append("insti_crstype", insti_crstype);
    formdata.append("min_fee", minfee);
    formdata.append("max_fee", maxfee);
    formdata.append("uploadImage", image);
    formdata.append("bannerImage", banner);
    axios(`Course/update_course`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const overviewsubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("courseId", course_id);
    formdata.append("overview", overview);
    axios(`Course/update_course_overview`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const customList = (course_id) => {
    setLoading(true);
    axios(`Course/get_custom_fields?course_id=${course_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCustomlist(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <HeaderFour />
      <Box
        sx={{ bgcolor: "#F6F9FC" }}
        py={{ xs: "1rem", sm: "2rem", md: "3rem", lg: "4rem" }}
      >
        <Container maxWidth="xl">
          <ThemeProvider theme={theme}>
            <Box
              pt={{ xs: "100px", sm: "60px", md: "48px", lg: "48px" }}
              pb={{ xs: "16px", sm: "16px", md: "48px", lg: "48px" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Tabs
                        className="MyAccountEditTabs"
                        orientation="vertical"
                        fullWidth
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        //  sx={{ borderRight: 1, borderColor: "divider" }}
                      >
                        <Tab
                          className="tabac"
                          label="Course Details"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="tabac"
                          label="Course Overview"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="tabac"
                          label="Custome Fields +"
                          {...a11yProps(2)}
                        />
                      </Tabs>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={8} lg={9}>
                  <Card>
                    <TabPanel value={value} index={0}>
                      <Grid container item spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Typography
                            textAlign="start"
                            gutterBottom
                            variant="h6"
                            sx={{ mb: 1.5 }}
                          >
                            Course Details
                          </Typography>
                          <Typography
                            variant="body2"
                            textAlign="start"
                            gutterBottom
                          >
                            The information can be edited
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Link to={`/MyAccountEdit/${insti_id}`}>
                            <Typography textAlign="end">
                              <IconButton>
                                <ArrowBackIcon />
                              </IconButton>
                            </Typography>
                          </Link>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <TextField
                            size="small"
                            fullWidth
                            required
                            label="Course Name"
                            variant="outlined"
                            value={courseName}
                            onChange={(e) => setCourse_name(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            multiple
                            options={courselevel}
                            getOptionLabel={(option) => option.name}
                            value={courselevel.filter(
                              (courselevel) => courselevel.id == insti_crslevel
                            )}
                            onChange={(e, value) => {
                              setInsti_crslevel(
                                value.length > 1
                                  ? value[1].id
                                  : value.length == 1
                                  ? insti_crslevel != 0
                                    ? insti_crslevel
                                    : value[0].id
                                  : 0
                              );
                            }}
                            autoHighlight
                            renderInput={(params) => (
                              <TextField {...params} label="Course Level" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            multiple
                            options={coursetutor}
                            getOptionLabel={(option) => option.name}
                            value={coursetutor.filter(
                              (coursetutor) => coursetutor.id == insti_crstutor
                            )}
                            onChange={(e, value) => {
                              setInsti_crstutor(
                                value.length > 1
                                  ? value[1].id
                                  : value.length == 1
                                  ? insti_crstutor != 0
                                    ? insti_crstutor
                                    : value[0].id
                                  : 0
                              );
                            }}
                            autoHighlight
                            renderInput={(params) => (
                              <TextField {...params} label="Course Tutor" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            multiple
                            id="combo-box-demo"
                            options={coursecategories}
                            getOptionLabel={(option) => option.name}
                            value={coursecategories.filter(
                              (coursecategories) =>
                                coursecategories.id == insti_crscategory
                            )}
                            onChange={(e, value) => {
                              setInsti_crscategory(
                                value.length > 1
                                  ? value[1].id
                                  : value.length == 1
                                  ? insti_crscategory != 0
                                    ? insti_crscategory
                                    : value[0].id
                                  : 0
                              );
                            }}
                            autoHighlight
                            renderInput={(params) => (
                              <TextField {...params} label="Course Category" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            multiple
                            options={coursetype}
                            getOptionLabel={(option) => option.name}
                            value={coursetype.filter(
                              (coursetype) => coursetype.id == insti_crstype
                            )}
                            onChange={(e, value) => {
                              setInsti_crstype(
                                value.length > 1
                                  ? value[1].id
                                  : value.length == 1
                                  ? insti_crstype != 0
                                    ? insti_crstype
                                    : value[0].id
                                  : 0
                              );
                            }}
                            autoHighlight
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Course Type"
                                value={insti_crstype}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <TextField
                            size="small"
                            fullWidth
                            required
                            label="Course Fees Minimum"
                            variant="outlined"
                            value={minfee}
                            onChange={(e) => setInstit_minfee(e.target.value)}  
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <TextField
                            size="small"
                            fullWidth
                            required
                            label="Course Fees Maximum"
                            variant="outlined"
                            value={maxfee}
                            onChange={(e) => setInstit_maxfee(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}> </Grid>

                        <Grid item xs={12} md={3} lg={3}>
                          <Paper style={{ width: "100%" }}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              flexDirection="column"
                            >
                              <input
                                type="file"
                                accept="image2/*"
                                name="image2"
                                id="file"
                                onChange={loadFile}
                                style={{ display: "none" }}
                              />
                              <Typography variant="body2">
                                Course Logo
                              </Typography>

                              <img
                                src={uploadImage ? uploadImage : image2}
                                className={classes.image2}
                                id="output"
                                width="auto"
                                maxWidth="100%"
                                height="135px"
                                alt="test"
                              />
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              padding="5px 20px"
                            >
                              <label
                                htmlFor="file"
                                style={{ cursor: "pointer" }}
                              >
                                <PhotoCamera />
                              </label>
                            </Box>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                          <Paper style={{ width: "100%" }}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              flexDirection="column"
                            >
                              <input
                                type="file"
                                accept="image2/*"
                                name="image2"
                                id="file2"
                                onChange={loadFileBanner}
                                style={{ display: "none" }}
                              />
                              <Typography variant="body2">
                                Course Banner
                              </Typography>

                              <img
                                src={uploadBanner ? uploadBanner : image2}
                                className={classes.image2}
                                id="output2"
                                width="auto"
                                maxWidth="100%"
                                height="135px"
                                alt="test"
                              />
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              padding="5px 20px"
                            >
                              <label
                                htmlFor="file2"
                                style={{ cursor: "pointer" }}
                              >
                                <PhotoCamera />
                              </label>
                            </Box>
                          </Paper>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography textAlign="right">
                            <Button>Cancel</Button>
                            <Button
                              onClick={submitForm}
                              endIcon={<ArrowForwardIcon />}
                              size="small"
                              variant="contained"
                            >
                              Submit
                            </Button>
                          </Typography>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                          p: 1,
                        }}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" color="GaryText">
                            Course Overview
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography textAlign="end">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={overviewsubmit}
                            >
                              Save
                            </Button>
                          </Typography>
                        </Grid>
                      </Box>
                      <Divider fullwidth />
                      <MyAccountManageListingAddCoursesEditCourseOverviewTextEditor
                        overview={overview}
                        setOverview={setOverview}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Grid container item spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" color="GaryText">
                            Custom Fields
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography textAlign="end">
                            <CustomeFieldDialog
                              insti_id={course_id}
                              type={2}
                              customList={customList}
                            />
                          </Typography>
                        </Grid>

                        <Grid item xs={12} mb={2}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          {customfields && customfields.length > 0 ? (
                            <div>
                              <Grid item xs={12}>
                                {customfields &&
                                  customfields.map((value, index) => {
                                    return (
                                      <Grid container spacing={1} py={1}>
                                        <Grid item xs={4} sm={2} md={3}>
                                          <ListItemText primary={value.title} />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={7}>
                                          <ListItemText
                                            primary={value.description}
                                          />
                                        </Grid>
                                        <Grid item xs={2} sm={1} md={1}>
                                          <CustomeFieldDialogEdit custom_id={value.id} insti_id={course_id} type={2} customList={customList}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                          <DeleteConfirmDialog type="course_custom" id={value.id} Lists={customList}/>
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </div>
                          ) : (
                            <Box className="EducationProgramsContainer">
                              <Box
                                p={1}
                                className="EducationDetailPrograms_bg cp"
                              >
                                <Box p={1}>
                                  <Typography
                                    sx={{ mt: 1.5 }}
                                    gutterBottom
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    No Data Found
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </ThemeProvider>
        </Container>
      </Box>

      <Footer />
    </div>
  );
}
