import React from "react";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import LoadingOverlay from "react-loading-overlay";
import axios from "../Utils/Axios"; //import axios config
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Avatar, Button, Card, CardContent, Divider, FormControl, Grid, InputLabel, List, ListItemAvatar, ListItemText, MenuItem, Pagination, Rating, Select, Stack } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReviewsIcon from "@mui/icons-material/Reviews";
//import HeaderTwo from "../HeaderTwo/HeaderTwo";
import Header from "../Header/Header";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "solid 1px gray",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
 // marginLeft: 0,
  width: "100%",
  // [theme.breakpoints.up("sm")]: {
  //   marginLeft: theme.spacing(1),
  //   width: "auto",
  // },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function EducationCategory(props) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [category, setCourseList] = React.useState([]);
  const [categorycourse, setCategorycourseList] = React.useState([]);
  const [cities, setcities] = React.useState([]);
  const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const search = props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const [location, setLocation] = React.useState(
      params.get("location") ? params.get("location") : ""
    );
    const [categoryType,setCategoryType] = React.useState(
      props.match && props.match.params && props.match.params.id
        ? props.match.params.id
        : params.get("categoryType").split(",")
    );

    const [searchlist, setSearchList] = React.useState([]);

  React.useEffect(() => {
    courseList();
    getcities();
    educourseList();
  }, [page, rowsPerPage, location, categoryType,searchlist]);

  const courseList = () => {
    setLoading(true);
    axios(`Course/all_course_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setLoading(false);
          setCourseList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const CourseView = (id) => {
    history.push({
      pathname: `/EducationCourseDetail/${id}`,
    });
  };


  const getcities = () => {
    setLoading(true);
    axios(`Home/popularCities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setcities(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const [total_rows, settotal_rows] = React.useState(0);

  const tutorfilterChange = (value) => {
    setCategoryType(value);
  };


  const educourseList = () => {
    setLoading(true);
    axios(
      `Course/all_courseList?page=${page}&per_page=${rowsPerPage}&location=${location}&categoryId=${categoryType}&keyword=${searchlist}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        window.scrollTo(0, 0);
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setCategorycourseList([]);
            setTotal(res.data.total);
            settotal_rows(res.data.total_rows);
          } else {
            setLoading(false);
            setCategorycourseList(res.data.data);
            setTotal(res.data.total);
            settotal_rows(res.data.total_rows);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const locationfilterChange = (value) => {
    setLocation(value);
  };

  
  return (
    <div style={{ width: "100%" }}>
      {/* <HeaderTwo /> */}
      <Header />
      <Box
        className="caro_bg"
        sx={{ bgcolor: "#F7F7F7", pt: "100px", pb: "48px" }}
      >
        <Container> 
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={10} md={10} lg={10}>
                  <Typography variant="h5" component="div" gutterBottom>
                    List of {total_rows} Courses 
                  </Typography>
                  <Typography variant="body2" color="GrayText" component="div">
                    Courses Listing
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid item p={1.5}>
            {" "}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 1.9)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                      stroke: "#025b95",
                    },
                  }),
                }}
                spinner
                text="Loading ..."
              >
                <Card>
                  <CardContent>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchList(e.target.value)}
                      />
                    </Search>
                    <FormControl fullWidth size="small" sx={{ my: 2 }}>
                      <InputLabel htmlFor="grouped-select">
                        Select city
                      </InputLabel>
                      <Select
                        defaultValue="0"
                        id="grouped-select"
                        label="Select city"
                        onChange={(e) => locationfilterChange(e.target.value)}
                      >
                        <MenuItem value={0}>Select All</MenuItem>
                        {cities.map((value, index) => {
                          return (
                            <MenuItem value={value.id}>{value.title}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth size="small">
                      <InputLabel htmlFor="grouped-select">
                        Select type of education
                      </InputLabel>
                      <Select
                        defaultValue="0"
                        id="grouped-select"
                        label="Select type of education "
                        onChange={(e) => tutorfilterChange(e.target.value)}
                      >
                        <MenuItem value={0}>Select All</MenuItem>
                         {category.map((value, index) => {
                          return ( 
                            <MenuItem value={value.id}>{value.name}</MenuItem>
                             );
                        })} 
                      </Select>
                    </FormControl>
                  </CardContent>
                </Card>
              </LoadingOverlay>
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={9}>
              <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 1.9)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                      stroke: "#025b95",
                    },
                  }),
                }}
                spinner
                text="Loading ..."
              >
                <Card>
                  <CardContent>
                    <List>
                    {categorycourse &&
                        categorycourse.map((data2) => {
                          return (
                          <Grid container spacing={3}>
                            <Grid item xs={2} sm={1} md={1} lg={1}>
                              <ListItemAvatar>
                                <Avatar
                                  alt="education listing"
                                  src={data2.banner}
                                />
                              </ListItemAvatar>
                            </Grid>
                            <Grid item xs={10} sm={5} md={7} lg={7}>
                              <ListItemText
                                className="cp"
                                color="text.primary"
                             //   component="div" sx={{ textOverflow: 'ellipsis' }}
                             sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                              pl: 1,
                           }}
                                onClick={() => CourseView(data2.id)}
                                primary={data2.title}
                                secondary={
                                  <React.Fragment>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        p: 1,
                                      }}
                                    >
                                      <LocationOnIcon
                                        size="small"
                                        color="disabled"
                                      />
                                      <Typography
                                      //  sx={{ display: "inline" }}
                                        sx={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          display: '-webkit-box',
                                          WebkitLineClamp: '1',
                                          WebkitBoxOrient: 'vertical',
                                       }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                       {data2.city} ,  {data2.insti_title}
                                      </Typography>
                                      {/* <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {data2.insti_title}
                                      </Typography> */}
                                    </Box>
                                  </React.Fragment>
                                }
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={2} lg={2}>
                              <Rating name="half-rating-read" defaultValue={data2.rating} readOnly />
                            </Grid>
                            <Grid item xs={6} sm={2} md={2} lg={2}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "end",
                                  flexDirection: "column",
                                }}
                              >
                                <Button
                                  className="newBtn"
                                  size="small"
                                  color="info"
                                  onClick={() => CourseView(data2.id)}
                                >
                                  Details
                                </Button>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    p: 1,
                                    pr: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                  Reviews
                                  </Typography>
                                  <ReviewsIcon size="small" color="disabled" />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              mb={1}
                              sx={{ padding: "0px" }}
                            >
                              {" "}
                              <Divider variant="inset" component="li" />{" "}
                            </Grid>
                          </Grid>
                          );
                        })}
                    </List>
                    <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                      <Box p={2} display="flex" justifyContent="center">
                        <Stack spacing={2}>
                          <Pagination
                            count={total}
                            page={page}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Box>
                    </Grid>
                  </CardContent>
                </Card>
              </LoadingOverlay>
            </Grid>
          </Grid>
        </Container>
      </Box>




      {/* <Box
        className="caro_bg"
        sx={{ bgcolor: "#F7F7F7", pt: "100px", pb: "48px" }}
      >
      <Container>
        <Card>
          <CardContent>
            <Grid container spacing={3} >
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <Typography variant="h5" component="div">
                  Top Category List (2022)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>  
</Box> */}


      {/* <CarouselThreeTextBanner latestprogram={latestprogram} /> */}

      {/* <Box className="TopContainer" sx={{ bgcolor: "#fff", pb: "48px" }}>
        <Box className="TopUniversities_bg" sx={{ display: "flex" }}></Box>

        <Box>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          ></LoadingOverlay>
        </Box>
      </Box>
      <Box className="caro_bg" sx={{ bgcolor: "#fff", pb: "48px" }}>
        <Container maxWidth="xl">
          {category &&
            category.map((data) => {
              return (
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      p: 1,
                      overflow: "hidden",
                    }}
                  >
                    <Box sx={{ p: 1 }}>
                      <Typography variant="h5" gutterBottom component="div">
                        {data.name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box width="100%">
                    <ItemsCarousel
                      className="TopContainer"
                      responsive={responsive}
                      autoPlay
                      infiniteLoop={true}
                      gutter={18}
                      timeout={1}
                      activePosition={"center"}
                      chevronWidth={60}
                      disableSwipe={false}
                      alwaysShowChevrons={false}
                      numberOfCards={5}
                      slidesToScroll={2}
                      outsideChevron={true}
                      showSlither={false}
                      firstAndLastGutter={false}
                      activeItemIndex={active}
                      requestToChangeActive={(value) => setaAtive(value)}
                      rightChevron={<KeyboardArrowRight />}
                      leftChevron={<KeyboardArrowLeft />}
                    >
                      {data.courses &&
                        data.courses.map((data2) => {
                          return (
                            <Box
                              p={1}
                              className="TopUniversities_bg"
                              bgcolor="#0303038"
                              onClick={() => CourseView(data2.id)}
                            >
                              <CardMedia
                                alt="SAAEH Education Banner"
                                class="education_category_banner_bg"
                                image={data2.banner_image}
                              >
                                <Box className="ECL_text_bg_100 cp">
                                  <Box
                                    sx={{
                                      m: "5%",
                                      position: "absolute",
                                      left: "0",
                                      bottom: "0",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          variant="subtitle2"
                                          color="white"
                                          gutterBottom
                                        >
                                          {data2.name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </CardMedia>
                            </Box>
                          );
                        })}
                    </ItemsCarousel>
                  </Box>
                </div>
              );
            })}
        </Container>
      </Box> */}

      <Footer />
    </div>
  );
}
