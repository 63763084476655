import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "../Utils/Axios"; //import axios config
import { AuthContext } from "../Context/AuthContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ArrowBackOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  Box,
  Button,
  CssBaseline,
  IconButton,
  Container,
  Checkbox,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#572672",
    },
    secondary: {
      main: "#11cb5f",
    },
    linkcolor: {
      main: "#013A4B",
    },
  },
});

export default function Login() {
  const history = useHistory();
  const [password, setPassword] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [showPassword2, setShowPassword] = React.useState(0);
  const [userMsg, setUserMsg] = React.useState(false);
  const [passwordMsg, setPasswordMsg] = React.useState(false);
  const [usertext, setUsertext] = React.useState("");
  const [passwordtext, setPasswordtext] = React.useState("");

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //const [loginopen, setLoginOpen] = React.useState(false);
  const { loginopen, setLoginOpen, login, setLogin } = useContext(AuthContext);
  const handleClickOpen = () => {
    setLoginOpen(true);
  };
  const handleClose = () => {
    setLoginOpen(false);
  };

  const handleBack = () => {
    setShowPassword(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", values.password);
    formdata.append("showPassword", showPassword2);

    axios("Login/login", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (!response.data.error) {
          localStorage.setItem(
            "data",
            JSON.stringify(response.data.data.api_key)
          );
          setLoginOpen(false);
          history.push({
            pathname: "/",
          });
          //alert.success("Login Successfull");
        } else if (response.data.exist === 1) {
          //alert.error(response.data.message);

          setShowPassword(1);
        } else if (response.data.exist === 0) {
          setShowPassword(0);
          history.push({
            pathname: "/Registration",
            state: { data: true, email: username },
          });
        } else {
          //alert.error(response.data.message);
          if (response.data.errortype === 1) {
            setUserMsg(true);
            setUsertext(response.data.message);
          } else if (response.data.errortype === 2) {
            setPasswordMsg(true);
            setPasswordtext(response.data.message);
          } else {
            setUserMsg(false);
            setUsertext("");
            setPasswordMsg(true);
            setPasswordtext(response.data.message);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={12}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              //  my: 20,
              //  mx: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            my={{
              xs: 5,
              sm: 10,
              lg: 20,
            }}
            mx={{
              xs: 5,
              sm: 10,
              lg: 20,
            }}
          >
            <Box
              component={"img"}
              pb={8}
              width={200}
              src="https://taleem.saaehapp.com/static/media/logo.cf0de840.webp"
              alt=""
              to="/"
            />
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              {showPassword2 === 0 ? (
                <TextField
                  fullWidth
                  label="Email Address"
                  variant="outlined"
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  value={username}
                  error={userMsg}
                  helperText={usertext}
                />
              ) : (
                ""
              )}
              {showPassword2 === 1 ? (
                <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                  <OutlinedInput
                    fullWidth
                    placeholder="Type your password"
                    id="filled-error-helper-text"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    onKeyDown={handleKeyDown}
                    error={passwordMsg}
                    helperText="Incorrect entry2."
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              ) : (
                ""
              )}
              <Grid
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox defaultChecked value="remember" color="primary" />
                  }
                  label="Remember me"
                />
                <Link to="/ForgotPassword">
                  <Typography variant="body2" color="primary">
                    Forgot password?
                  </Typography>
                </Link>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                //  sx={{ mt: 3, mb: 2 }}
                sx={{
                  bgcolor: "#572672",
                  color: "white",
                  borderRadius: 10,
                  opacity: 50,
                  mt: 3,
                  mb: 2,
                }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid
                  item
                  xs
                  sm={12}
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  alignItems="center"
                >
                   <Link to="/">
                  <Typography variant="body2" color="primary" display="flex"  alignItems="center">
                  <ArrowBackOutlined fontSize="10" />  Back to Home
                  </Typography>
                </Link>
                  <Link to="/Registration" variant="body2">
                    Don't have an account?{" "}
                    <Typography
                      variant=""
                      color="primary"
                      sx={{ fontWeight: "bold" }}
                    >
                      Sign Up
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={12}
          md={6}
          sx={{
            backgroundImage:
              "url(https://jobportal.gligx.com/img/saaeh/reg_background.webp)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              //  my: 20,
              //  mx: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            my={{
              xs: 5,
              sm: 10,
              lg: 20,
            }}
            mx={{
              xs: 5,
              sm: 10,
              lg: 20,
            }}
          >
            <Typography component="h1" variant="h5">
              Why Saaeh?
            </Typography>
            <Typography color="GrayText" variant="body2" gutterBottom>
              Plus Saaeh will be the major platform for so many aspects and
              economical sectors , the country would like to take care of and
              develop :
            </Typography>
            <Box color="GrayText">
              <List sx={{ width: "100%", maxWidth: 360 }} aria-label="contacts">
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Touris, with its various types." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Investment." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Freelancing." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Advertising." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Recruitment." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sustainability." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Communication." />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowForwardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Financial Transaction." />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
