import React from "react";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Divider, Grid, Box, Paper, Typography } from '@mui/material';
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { makeStyles } from "@material-ui/core/styles";
import image2 from "../images/logo.webp";
const useStyle = makeStyles({
    image2: {
      borderRadius: 100,
    },
  });

export default function MyAccountEditCoursesDialog({insti_id,manageList}) {
 
    const classes = useStyle();
    var loadFile = (event) => {
      if (event.target.files[0]) {
        setUploadImage(URL.createObjectURL(event.target.files[0]));
        console.log(URL.createObjectURL(event.target.files[0]));
        setImage(event.target.files[0]);
      }
    };


    var loadFileBanner = (event) => {
      if (event.target.files[0]) {
        setUploadBanner(URL.createObjectURL(event.target.files[0]));
        console.log(URL.createObjectURL(event.target.files[0]));
        setBanner(event.target.files[0]);
      }
    };

    const alert = useAlert();
    const [loading, setLoading] = React.useState(false);
    const [courseName, setCourse_name] = React.useState("");
    const [courselevel, setCourseLevel] = React.useState([""]);
    const [coursetutor, setCourseTutor] = React.useState([""]);
    const [coursetype, setCourseType] = React.useState([""]);
    const [courseDuration , setCourse_duration] = React.useState();
    const [coursecategories, setCourseCategory] = React.useState([""]);
    const [insti_crslevel, setInsti_crslevel] = React.useState("");
    const [insti_crstutor, setInsti_crstutor] = React.useState("");
    const [insti_crstype, setInsti_crstype] = React.useState("");
    const [insti_crscategory, setInsti_crscategory] = React.useState("");
    const [uploadImage, setUploadImage] = React.useState("");
    const [uploadBanner, setUploadBanner] = React.useState("");
    const [image, setImage] = React.useState("");
    const [banner, setBanner] = React.useState("");

    
  
    React.useEffect(() => {
      getCourseLevel();
      getCourseTutor();
      getCourseType();
      getCourseCategory();
      manageList(insti_id);
    }, []);
  
  
    const getCourseLevel = () => {
      axios(`Course/get_course_level`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error === true) {
          } else {
            if (!res.data.status) {
            } else {
              setCourseLevel(res.data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    };
  
  
    const getCourseTutor = () => {
      axios(`Course/get_course_tutor`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error === true) {
          } else {
            if (!res.data.status) {
            } else {
              setCourseTutor(res.data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    };
  
  
    const getCourseType = () => {
      axios(`Course/get_course_type`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error === true) {
          } else {
            if (!res.data.status) {
            } else {
              setCourseType(res.data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    };
  
    const getCourseCategory = () => {
      axios(`Course/get_course_category`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error === true) {
          } else {
            if (!res.data.status) {
            } else {
              setCourseCategory(res.data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    };
    
    const submitForm = (event) => {
        event.preventDefault();
        let formdata = new FormData();
        formdata.append("insti_id", insti_id);
        formdata.append("course_name", courseName);
        formdata.append("course_level", insti_crslevel);
        formdata.append("course_tutor", insti_crstutor);
        formdata.append("course_category", insti_crscategory);
        formdata.append("course_type", insti_crstype);
        formdata.append("course_duration", courseDuration);
        formdata.append("logo", image);
        formdata.append("banner", banner);
        axios(`Course/add_course`, {
          method: "POST",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        })
          .then((res) => {
            if (res.data.status) {
              alert.success(res.data.message);
              manageList(insti_id);
              setOpen(false);
            } else {
              alert.error(res.data.message);
            }
          })
          .catch((err) => console.log(err));
      };

      
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
  return (
    <div>
    {/* //  <MyAccount_ManageListingAddCoursesEdit/> */}
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
        New Course
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Course</DialogTitle>
        <DialogContent>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <TextField size='small'
                fullWidth
                required
                label="Course Name"
                variant="outlined"
                value={courseName}
                onChange={(e) => setCourse_name(e.target.value)}
              />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                <Autocomplete fullWidth size='small'
                  multiple
                  options={courselevel}
                  getOptionLabel={(option) => option.name}
                  value={courselevel.filter(
                    (courselevel) => courselevel.id == insti_crslevel
                  )}
                  onChange={(e, value) => {
                    setInsti_crslevel(
                      value.length > 1
                        ? value[1].id
                        : value.length == 1
                        ? insti_crslevel != 0
                          ? insti_crslevel
                          : value[0].id
                        : 0
                    );
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Level"
                    />
                  )}
                /></Grid>
                <Grid item xs={12} md={6} lg={6}>
                <Autocomplete fullWidth size='small'
                  multiple
                  options={coursetutor}
                  getOptionLabel={(option) => option.name}
                  value={coursetutor.filter(
                    (coursetutor) => coursetutor.id == insti_crstutor
                  )}
                  onChange={(e, value) => {
                    setInsti_crstutor(
                      value.length > 1
                        ? value[1].id
                        : value.length == 1
                        ? insti_crstutor != 0
                          ? insti_crstutor
                          : value[0].id
                        : 0
                    );
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Tutor"
                    />
                  )}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                /></Grid>
                <Grid item xs={12} md={6} lg={6}>
                <Autocomplete fullWidth size='small'
                  multiple
                  id="combo-box-demo"
                  options={coursecategories}
                  getOptionLabel={(option) => option.name}
                  value={coursecategories.filter(
                    (coursecategories) => coursecategories.id == insti_crscategory
                  )}
                  onChange={(e, value) => {
                    setInsti_crscategory(
                      value.length > 1
                        ? value[1].id
                        : value.length == 1
                        ? insti_crscategory != 0
                          ? insti_crscategory
                          : value[0].id
                        : 0
                    );
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Category"
                    />
                  )}
                />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                <Autocomplete fullWidth size='small'
                  multiple
                  options={coursetype}
                  getOptionLabel={(option) => option.name}
                  value={coursetype.filter(
                    (coursetype) => coursetype.id == insti_crstype
                  )}
                  onChange={(e, value) => {
                    setInsti_crstype(
                      value.length > 1
                        ? value[1].id
                        : value.length == 1
                        ? insti_crstype != 0
                          ? insti_crstype
                          : value[0].id
                        : 0
                    );
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Type"
                      value={insti_crstype}
                    />
                  )}
                /></Grid>
                 <Grid item xs={12} md={6} lg={6}>
                 <TextField size='small'
                    fullWidth
                    label="Course Duration"
                    variant="outlined"
                    value={courseDuration}
                    onChange={(e) => setCourse_duration(e.target.value)}
                  />
                 </Grid>
             
                <Grid item xs={12} md={6} lg={6}>
                <Paper style={{ width: "250px" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file2"
                    onChange={loadFileBanner}
                    style={{ display: "none" }}
                  />
                  <Typography variant="body2">Course Banner</Typography> 

                  <img
                    src={uploadBanner ? uploadBanner : image2}
                    className={classes.image2}
                    id="output2"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file2" style={{ cursor: "pointer" }}>
                    <PhotoCamera />
                  </label>
                </Box>
                </Paper>
              </Grid>
                <Grid item xs={12} md={6} lg={6}>
                <Paper style={{ width: "250px" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="body2">Course Logo </Typography> 

                  <img
                    src={uploadImage ? uploadImage : image2}
                    className={classes.image2}
                    id="output"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCamera />
                  </label>
                </Box>
                </Paper></Grid>
                <Grid item xs={12} md={6} lg={6}></Grid>


                </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submitForm} endIcon={<ArrowForwardIcon />}  size="small"  variant="contained">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
