import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-multi-carousel";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Card, CardContent, Container, Grid } from "@mui/material";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function EducationCourseDetail_RelatedPrograms({
  courseId,
  setCourseId,
}) {
  
  const history = useHistory();
  const [relatedCourses, setCourseList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    relatedCourseList(courseId);
  }, []);

  const relatedCourseList = (courseId) => {
    setLoading(true);
    axios(`Course/related_courses?courseId=${courseId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCourseList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const CourseView = (id) => {
    setCourseId(id);
    history.push({
      pathname: `/EducationCourseDetail/${id}`,
    });
  };

  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ pt: "48px", pb: "48px" }}>
        <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                overflow: "hidden",
              }}
            >
              <Box sx={{ p: 1 }}>
                <Typography variant="h5" gutterBottom component="div">
                  Related Courses
                </Typography>
              </Box>
              {/* <Box sx={{ p: 1 }}>
                <Divider
                  color="white"
                  sx={{ height: 28, m: 0.5 }}
                  orientation="vertical"
                />
              </Box> */}
              {/* <Box sx={{ p: 1, flexGrow: 1 }}>
                <Typography
                  className="cp"
                  variant="body2"
                  color="InactiveCaptionText"
                  gutterBottom
                  component="div"
                >
                  View All
                </Typography>
              </Box> */}
            </Box>

            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              <Box className="cp">
                <Carousel
                  responsive={responsive}
                  rightChevron={<KeyboardArrowRight />}
                  leftChevron={<KeyboardArrowLeft />}
                >
                  {relatedCourses &&
                    relatedCourses.map((value, index) => {
                      return (
                        <Box
                          p={2}
                          className="TopUniversities_box"
                          onClick={() => CourseView(value.id)}
                        >
                          <Card className={classes.TopUniversitiesCard}>
                            <CardMedia
                              component="img"
                              width="100%"
                              height="300"
                              image={value.banner}
                              alt={value.title}
                            />
                            <Box className="cp">
                              <CardContent>
                                <Box p={1}>
                                  <Typography
                                    className="text_bg"
                                    gutterBottom
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    {value.title}
                                  </Typography>
                                </Box>
                              </CardContent>
                            </Box>
                          </Card>
                        </Box>
                      );
                    })}
                </Carousel>
              </Box>
            </LoadingOverlay>
          </Grid>
        </Grid>
        </Container>
      </Box>
    </div>
  );
}
