import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const theme = createTheme();
export default function RegistrationTwo() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid
          item
          xs={false}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 20,
              mx: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Link to="/home">
              <Box
                component={"img"}
                pb={8}
                width={200}
                src="https://jobportal.gligx.com/img/saaeh/logo.webp"
                alt=""
                to="/home"
              />
            </Link>
            <Typography component="h1" variant="h5">
              Location
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                //  id="Company Name"
                label="Country"
                name="Country"
                autoComplete="text"
                autoFocus
              />
              <Autocomplete
                freeSolo
                //    id="SelectCity"
                disableClearable
                options={SelectCity.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField
                    margin="normal"
                    {...params}
                    label="City"
                    variant="outlined"
                    className="log_text"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="Area"
                label="Area"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="Location"
                label="Location"
              />

              <Box display="flex">
                <Stack width="100%" direction="row" spacing={2}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Link to="/Registration" className="btn_gray">
                      <Button
                        variant="contained"
                        sx={{ mt: 3, mb: 2, p: 0.3, minWidth: "200px" }}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Back
                      </Button>
                    </Link>
                  </Box>
                  <Box>
                    <Link to="/Education" className="btn_black">
                      <Button
                        variant="contained"
                        sx={{ mt: 3, mb: 2, p: 0.3, minWidth: "200px" }}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Next
                      </Button>
                    </Link>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage:
              "url(https://jobportal.gligx.com/img/saaeh/reg_background.webp)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div>
            <Box
              sx={{
                my: 25,
                mx: 25,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography component="h1" variant="h5" gutterBottom>
                Ta’leem تعليم
              </Typography>
              <Typography
                color="GrayText"
                variant="body1"
                gutterBottom
                component="div"
              >
                1- linked to tabarr’u to offer free online educational platform
                , for those in need to continue their education
              </Typography>
              <Typography color="GrayText" variant="body2" gutterBottom>
                2- providing a space to sell and donate used educational books ,
                going straight to the student , to ensure direct receiving.{" "}
              </Typography>
              <Typography color="GrayText" variant="body1" gutterBottom>
                3-It will be a platform , for all those who have anything to
                teach , from knitting , carpeting , tailoring , sign language ,
                cooking, singing , playing instruments ,etc. With all languages
                , and locations provided around the world .{" "}
              </Typography>
              <Typography color="GrayText" variant="body2" gutterBottom>
                4-offering special section , for people of determination ,
                online in different languages.
              </Typography>
              <Typography color="GrayText" variant="body1" gutterBottom>
                5- it will have in sha’allah , a personalized interface for
                those in need to enter some courses free , in order to help
                continue their education , with special ID given to each
                student.{" "}
              </Typography>
              <Typography color="GrayText" variant="body2" gutterBottom>
                6- It will house all different schools, universities ,
                institutes, learning centers, in UAE , and other countries, to
                make it easier for tourists and education seekers , to find
                their best learning destinations, by filtering options ,
                according , to type , location , prices , experience, etc. ..
                Especially the online universities that has been established to
                encourage students to enroll from all over the world{" "}
              </Typography>
            </Box>
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const SelectCity = [
  { title: " SelectCity 1 ", year: 2021 },
  { title: "SelectCity 2", year: 2021 },
  { title: " SelectCity 3", year: 2021 },
];
