import {
  Avatar,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  createTheme,
  ThemeProvider,
  CardContent,
  Box,
} from "@mui/material";
import React from "react";
import axios from "../Utils/Axios"; //import axios config
import { useHistory } from "react-router-dom";
import MyAccountContributionsDialog from "./MyAccountContributionsDialog";
import LoadingOverlay from "react-loading-overlay";
import MyAccountContributionsDialogeEdit from "./MyAccountContributionsDialogeEdit";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import { ConfirmProvider } from "material-ui-confirm";

export default function MyAccountContributionsTabsBook() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [profile, setProfile] = React.useState({});
  const [storebooks, setstoreBooks] = React.useState([]);

  //const [hasg, setHash] = React.useState(props.location.hash);

  React.useEffect(() => {
    getProfile();
    storeBooksList();
  }, []);

  const getProfile = () => {
    setLoading(true);
    axios(`Profile/edit_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          setLoading(false);
        } else {
          setLoading(false);
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const storeBooksList = () => {
    setLoading(true);
    axios(`Home/get_contributes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setstoreBooks(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if(hasg=='#contribute'){
  //   var s =2;
  // }
  // else
  // {
  //   var s =0;
  // }
  // const [value, setValue] = React.useState(s);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const theme = createTheme({
    palette: {
      primary: {
        light: "#833baa",
        main: "#572672",
        dark: "#381849",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
          <Grid container spacing={3}>
            <Grid item xs={12} pl={0}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs></Grid>
                    <Grid item xs="auto">
                      <MyAccountContributionsDialog
                        storeBooksList={storeBooksList}
                      />
                    </Grid>
                  </Grid>
                  {storebooks.length > 0 ? (
                  <div>
                  {storebooks.map((value, index) => {
                    return (
                      <div>
                        <List>
                          <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                            <ListItemAvatar>
                              <Avatar alt={value.title} src={value.images} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={value.title}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    by {value.author}
                                  </Typography>
                                  <p>{value.description}</p>
                                </React.Fragment>
                              }
                            />
                            <Box sx={{ alignSelf: "center" }}>
                              <ConfirmProvider>
                                <DeleteConfirmDialog type="books_review" id={value.id} Lists={storeBooksList}/>
                              </ConfirmProvider>
                            </Box>
                            <Box sx={{ alignSelf: "center" }}>
                              <MyAccountContributionsDialogeEdit book_id={value.id} storeBooksList={storeBooksList}/>
                            </Box>
                          </ListItem>

                          <Divider variant="inset" component="li" />
                        </List>
                      </div>
                    );
                  })}
                  </div>
                  ) : (
                    <div>
                    <Grid item xs={12} sx={{ ml: 2, mt: 2 , mb: 2}}>
                      <Typography>
                        No Review Found
                      </Typography>
                    </Grid>
                  </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </LoadingOverlay>
      </ThemeProvider>
    </div>
  );
}
