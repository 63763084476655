import React from "react";
import Footer from "../Footer/Footer";
import HeaderThree from "../HeaderThree/HeaderThree";
import LoadingOverlay from "react-loading-overlay";
import axios from "../Utils/Axios"; //import axios config
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import {Card,CardContent,Checkbox,Grid,Tooltip,Rating} from "@mui/material";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function EducationStoresAllbooks() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [languages, setLanguages] = React.useState("");
  const [format, setFormat] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [books, setBooks] = React.useState([]);

  const BookView = (id) => {
    history.push({
      pathname: `/EducationBookStoresDetail/${id}`,
    });
  };

  React.useEffect(() => {
    getbooks();
    getAuthors();
    getLanguages();
  }, [languages,format,author]);
  const getbooks = () => {
    setLoading(true);
    axios(`Bookstore/get_store_all_books?languages=${languages}&format=${format}&author=${author}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setBooks(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    []
  );


  const LanaguageChange = (event,val) => {
    if((event.target.checked)){
      setLanguages(languages + val + ",");
    }else{
      setLanguages(languages.replace(val + ",", ""));
    }
  };

  
  const FormatChange = (event,val) => {
    if((event.target.checked)){
      setFormat(format + val + ",");
    }else{
      setFormat(format.replace(val + ",", ""));
    }
  };


  const AuthorChange = (event,val) => {
    if((event.target.checked)){
      setAuthor(author + val + ",");
    }else{
      setAuthor(author.replace(val + ",", ""));
    }
  };

  const getAuthors = () => {
    axios(`Institution/get_authors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setAuthors(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const [authors, setAuthors] = React.useState([]);


  const getLanguages = () => {
    axios(`Bookstore/get_languages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setlanguage(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const [languaes, setlanguage] = React.useState([]);

  return (
    <div style={{ width: "100%" }}>
      <HeaderThree />

      <Box className="TopContainer" sx={{ bgcolor: "#fff", pb: "48px" }}>
        <Box>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          ></LoadingOverlay>
        </Box>
      </Box>
      <Box className="caro_bg" sx={{ bgcolor: "#fff", pb: "48px" }}>
        <Container maxWidth="xl">
          <Grid container spacing={3} sx={{ pt: 5 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="filterBox"
              display={{ xs: "block", sm: "none" }}
            >
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography> Book Format </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="h6" component="div">
                    Book Format
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,1)}} sx={{ p: 0 }} /> Audio CD{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,2)}} sx={{ p: 0 }} />Board Book{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,3)}} sx={{ p: 0 }} />Hardcover{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,4)}} sx={{ p: 0 }} />Large Print{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,5)}} sx={{ p: 0 }} />Loose Leaf{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,6)}} sx={{ p: 0 }} />Paperback{" "}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography> Authors</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {authors.map((author, index) => (
                  <Typography variant="body2" component="p">
                  <Checkbox onChange={(event)=> {AuthorChange(event,author.id)}} sx={{ p: 0 }} /> {author.title}{" "}
                  </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                >
                  <Typography> Book Language </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {languaes.map((row, index) => (
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {LanaguageChange(event,row.id)}} sx={{ p: 0 }} /> {row.name}{" "}
                  </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className="filterBox"
              display={{ xs: "none", sm: "block" }}
            >
              <Box sx={{ p: 2 }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  color="#572672"
                ></Typography>
              </Box>

              <Grid item xs={12} md={12} my={2}>
                  <Typography variant="h6" component="div">
                    Book Format
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,1)}} sx={{ p: 0 }} /> Audio CD{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,2)}} sx={{ p: 0 }} />Board Book{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,3)}} sx={{ p: 0 }} />Hardcover{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,4)}} sx={{ p: 0 }} />Large Print{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,5)}} sx={{ p: 0 }} />Loose Leaf{" "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {FormatChange(event,6)}} sx={{ p: 0 }} />Paperback{" "}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12} my={2}>
                  <Typography variant="h6" component="div">
                    Authors
                  </Typography>
                  {authors.map((author, index) => (
                  <Typography variant="body2" component="p">
                  <Checkbox onChange={(event)=> {AuthorChange(event,author.id)}} sx={{ p: 0 }} /> {author.title}{" "}
                  </Typography>
                  ))}
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography variant="h6" component="div">
                    Book Language
                  </Typography>
                  {languaes.map((row, index) => (
                  <Typography variant="body2" component="p">
                    <Checkbox onChange={(event)=> {LanaguageChange(event,row.id)}} sx={{ p: 0 }} /> {row.name}{" "}
                  </Typography>
                  ))}
                </Grid>
            </Grid>

            <Grid item xs={12} sm={10} md={10}>
              <Grid>
                <Typography
                  variant="h5"
                  fontWeight={600}
                  gutterBottom
                  component="div"
                >
                  All Stores Books
                </Typography>
              </Grid>
              <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 1.9)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                      stroke: "#025b95",
                    },
                  }),
                }}
                spinner
                text="Loading ..."
              >
              <Grid container spacing={0}>
                {books.map((data2, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        onClick={() => BookView(data2.id)}
                      >
                        <Card>
                          <CardMedia
                            component="img"
                            height="300"
                            image={data2.images}
                            alt={data2.title}
                          />

                          <Box className="cp">
                            <Grid container spacing={0}>
                              <Grid item xs={12} sm={12} md={12}>
                                <CardContent fullwidth>
                                  <Tooltip Tooltip title={data2.title}>
                                    <Typography
                                      Tooltip
                                      title={data2.title}
                                      className="text_bg"
                                      textAlign="start"
                                      sx={{ mt: 1.5 }}
                                      gutterBottom
                                      variant="subtitle2"
                                      component="div"
                                    >
                                      {data2.title}
                                    </Typography>
                                  </Tooltip>
                                  <Typography
                                    className="text_bg"
                                    textAlign="start"
                                    gutterBottom
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {data2.author}
                                  </Typography>
                                  <Rating
                                    name="read-only"
                                    value={data2.rating}
                                    readOnly
                                  />
                                </CardContent>
                              </Grid>
                            </Grid>
                          </Box>
                        </Card>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              </LoadingOverlay>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </div>
  );
}
