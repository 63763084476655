import * as React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import logo112 from "../images/logo-icon.webp";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "../Utils/Axios"; //import axios config

const theme = createTheme({
  palette: {
    primary: {
      main: "#013A4B",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#013A4B",
    "&:hover": {
      backgroundColor: "#1b7d9b",
    },
  },
  welcomeText: {
    fontWeight: 800,
    textAlign: "center",
    color: theme.palette.grey[600],
  },
  link: {
    color: "#013A4B",
  },
}));

export default function Success() {

  const [newCurrency, setnewCurrency] = React.useState();

  const [orderId, setOrderId] = React.useState();

  const [orderdata, setOrderData] = React.useState([]);
  
  const getOrderId = () => {
    setnewCurrency(localStorage.getItem("dian_currency") ? localStorage.getItem("dian_currency") : "AED");
    if (JSON.parse(localStorage.getItem("dian_order"))) {
      setOrderId(JSON.parse(localStorage.getItem("dian_order")));
    } else {
      setOrderId(0);
    }
  };

  React.useEffect(
    () => {
      const new_currency= (localStorage.getItem("dian_currency") ? localStorage.getItem("dian_currency") : "AED");
      getOrderId();
      getOrderDeatils(new_currency);
      const last_order= (localStorage.getItem("dian_order") ? localStorage.getItem("dian_order") : "");
      if(last_order==="" ){
        window.location.replace("/");
      }
    }, []);


    const getOrderDeatils = (new_currency) => {
      axios(
        `Orders/successOrderDeatils?currency=${new_currency}&order_id=${JSON.parse(
          localStorage.getItem("dian_order")
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        }
      )
        .then((res) => {
          if (res.data.error) {
          } else {
            if (res.data.data.length === 0) {
              setOrderData([]);
            } else {
              setOrderData(res.data.data);
              localStorage.removeItem("dian_cart");
              localStorage.removeItem("dian_order");
              console.log(res.data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    };

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="#013a4b4e"
        p={1}
        sx={{ height: "100vh" }}
      >
        <Box p={1} bgcolor="white" borderRadius="8px">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Link to="/">
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                >
                  <Avatar alt="Saaeh logo" src={logo112} />
                </IconButton>
              </Link>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <CheckCircleOutlineIcon
                  sx={{ fontSize: 140, color: "#4caf50" }}
                />
              </Box>

              <Box p={2} mb={3}>
                <Typography
                  className={classes.welcomeText}
                  gutterBottom
                  fontFamily="Merriweather"
                  variant="h4"
                  fontWeight="bold"
                  component="div"
                  display="block"
                >
                  Thank you for your order.
                </Typography>
                <Typography variant="p" color="gray">
                  Your order number is #{orderdata.order_no}. We have emailed your order
                  confirmation, and will send you an update when your order has
                  shipped.
                </Typography>
              </Box>

              <Box p={2}>
                <Link to="/Login">
                  <Typography
                    className={classes.link}
                    gutterBottom
                    color="primary"
                    variant="p"
                    component="p"
                  >
                    Back to Login
                  </Typography>
                </Link>
              </Box>
            </div>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
