import {Box,Container,Grid,styled,Stack,Typography,Button,CardMedia,
  CardContent,CardActions,ThemeProvider,Divider,} from "@mui/material";
import React from "react";
import Footer from "../Footer/Footer";
//import HeaderTwo from "../HeaderTwo/HeaderTwo";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ButtonBase from "@mui/material/ButtonBase";
import { useTheme } from "@mui/material/styles";
import LoadingOverlay from "react-loading-overlay";
import PropTypes from "prop-types";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "../Utils/Axios"; //import axios config
import parse from "html-react-parser";
import Header from "../Header/Header";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "grey.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderTop: "8px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        borderTopColor: "#572672",
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function ItemHead(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "grey.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderLeft: "8px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        borderLeftColor: "#572672",
        fontSize: "0.875rem",
        fontWeight: "bold",
        ...sx,
      }}
      {...other}
    />
  );
}
ItemHead.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default function EducationGuideArticlesNew() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const [category, setCategory] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const [popularnews, setPopularNews] = React.useState([]);
  const [second_news, setsecondNews] = React.useState([]);
  const params = useParams();
  const [articeId, setArticeId] = React.useState(params && params.id);

  React.useEffect(() => {
    categoryList();
    newsList();
    popularList(4);
    second_newsList(articeId);
    window.scrollTo(0, 0);
  }, []);

  const categoryList = () => {
    setLoading(true);
    axios(`Article/get_news_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCategory(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const ArticleView = (id) => {
    history.push({
      pathname: `/EducationGuideArticlesLists/${id}`,
    });
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const newsList = () => {
    setLoading(true);
    axios(`Article/get_all_news`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setNews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const popularList = () => {
    setLoading(true);
    axios(`Article/get_popular_news`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setPopularNews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const second_newsList = (category_id) => {
    setLoading(true);
    axios(`Article/get_news_category_byid?category_id=${category_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setsecondNews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      {/* <HeaderTwo /> */}
      <Header />
      <ThemeProvider theme={theme}>
        <Box
          className="caro_bg"
          sx={{ bgcolor: "#fff", pt: "70px", pb: "48px" }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h3"
                      textAlign="center"
                      color="#572672"
                      fontWeight={600}
                      component="h3"
                      gutterBottom
                      mt={3}
                    >
                      News and Articles
                    </Typography>
                    <Stack
                      spacing={2}
                      direction="row"
                      flexWrap="wrap"
                      justifyContent="center"
                      color="#572672"
                    >
                      <Link to="/EducationGuideArticlesLists/0">
                        <Button color="secondary" variant="text">
                          Latest News
                        </Button>
                      </Link>
                      {category.map((value, index) => {
                        return (
                          <Button
                            color="secondary"
                            variant="text"
                            onClick={() => ArticleView(value.id)}
                          >
                            {value.title}
                          </Button>
                        );
                      })}
                    </Stack>
                  </Grid>

                  {second_news[0] && second_news[0].datas.length > 0 ? (
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <Item>
                        {second_news[0] &&
                          second_news[0].datas.slice(0, 3).map((data, index) => (
                            <Link
                              to={`/EducationGuideArticlesDetail/${data.id}`}
                            >
                              <CardContent>
                                <Typography
                                  display="inline-block"
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  fontWeight={600}
                                  gutterBottom
                                >
                                  LATEST
                                </Typography>
                                <Typography
                                  display="inline-block"
                                  sx={{ fontSize: 14, pl: 1 }}
                                  color="primary"
                                  fontWeight={600}
                                  gutterBottom
                                >
                                  MOST READ
                                </Typography>

                                <Typography variant="h6" lineHeight={1.2}>
                                  {data.title}
                                </Typography>
                                <Typography color="text.secondary">
                                  {data.short_description}
                                </Typography>
                                <Typography color="text.secondary">
                                  {data.time}
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                              </CardContent>
                            </Link>
                          ))}
                      </Item>
                    </Grid>
                  ) : null}

                  {second_news && second_news.length > 0 ? (
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <LoadingOverlay
                        active={loading}
                        styles={{
                          overlay: (base) => ({
                            ...base,
                            background: "rgba(255, 255, 255, 1.9)",
                          }),
                          spinner: (base) => ({
                            ...base,
                            width: "75px",
                            "& svg circle": {
                              stroke: "#025b95",
                            },
                          }),
                        }}
                        spinner
                        text="Loading ..."
                      >
                        <Item>
                          <CardContent>
                            <CardMedia
                              sx={{ borderRadius: 1 }}
                              component="img"
                              alt="green iguana"
                              image={second_news[0] && second_news[0].images}
                            />
                          </CardContent>
                          <CardContent>
                            <Typography
                              nowrap
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {second_news[0] && second_news[0].title}
                            </Typography>
                            <Typography
                              nowrap
                              variant="body2"
                              color="text.secondary"
                            >
                              {second_news[0] &&
                                parse(
                                  second_news[0].description.substring(0, 20) + "..."
                                )}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button size="small">
                              Paperback – {second_news[0] && second_news[0].created}
                            </Button>
                            <Link
                              to={`/EducationGuideArticlesDetail/${
                                second_news[0] && second_news[0].id
                              }`}
                            >
                              <Button size="small">More...</Button>
                            </Link>
                          </CardActions>
                        </Item>
                      </LoadingOverlay>
                    </Grid>
                  ) : null}

                  {second_news[0] && second_news[0].datas.length > 0 ? (
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Item>
                            <LoadingOverlay
                              active={loading}
                              styles={{
                                overlay: (base) => ({
                                  ...base,
                                  background: "rgba(255, 255, 255, 1.9)",
                                }),
                                spinner: (base) => ({
                                  ...base,
                                  width: "75px",
                                  "& svg circle": {
                                    stroke: "#025b95",
                                  },
                                }),
                              }}
                              spinner
                              text="Loading ..."
                            >
                              <Carousel
                                autoPlay={true}
                                showArrows={false}
                                showIndicators={false}
                                showStatus={false}
                                showThumbs={false}
                                infiniteLoop={true}
                                stopOnHover={false}
                                axis="vertical"
                              >
                                {news[0] &&
                                  news[0].datas.map((data, index) => (
                                    <Box key={index} >
                                      <Typography gutterBottom>
                                        <b>{data.title}</b>
                                      </Typography>
                                    </Box>
                                  ))}
                              </Carousel>
                              {news[0] &&
                                news[0].datas.map((data, index) => (
                                  <Grid
                                    container
                                    spacing={1}
                                    mb={0}
                                    key={index}
                                  >
                                    <Grid item xs={12} sm container>
                                      <CardContent>
                                        <Grid item>
                                          <Link
                                            to={`/EducationGuideArticlesDetail/${data.id}`}
                                          >
                                            <ButtonBase
                                              sx={{ width: 100, height: 68 }}
                                            >
                                              <Img
                                                alt="complex"
                                                src={data.images}
                                                sx={{ borderRadius: 1 }}
                                              />
                                            </ButtonBase>
                                          </Link>
                                        </Grid>
                                      </CardContent>
                                      <Grid item xs={12} sm container>
                                        <Link
                                          to={`/EducationGuideArticlesDetail/${data.id}`}
                                        >
                                          <Grid item xs>
                                            <CardContent sx={{ px: "0px" }}>
                                              <Typography
                                                variant="subtitle1"
                                                component="div"
                                                fontWeight={600}
                                              >
                                                {parse(data.title.substring(0, 20) )}
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                              >
                                                {parse(data.short_description.substring(0, 20) )}
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                color="textSecondary"
                                              >
                                                Paperback – {data.created}
                                              </Typography>
                                            </CardContent>
                                          </Grid>
                                        </Link>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                            </LoadingOverlay>
                          </Item>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}

                  {second_news[0] && second_news[0].datas.length > 0 ? (
                    <Box>
                      <Grid
                        container
                        spacing={{ xs: 1, md: 1 }}
                        columns={{ xs: 4, sm: 12, md: 12 }}
                      >
                        <Grid item xs={12}>
                          <ItemHead>
                            <Typography
                              pl={1}
                              gutterBottom
                              variant="h5"
                              fontWeight={500}
                              color="#572672"
                              component="div"
                            >
                              Most Popular
                            </Typography>
                          </ItemHead>
                        </Grid>
                        {popularnews.map((data, index) => (
                          <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                            <Item>
                              <CardContent>
                                <Box sx={{ display: "flex" }}>
                                  <Link
                                    to={`/EducationGuideArticlesDetail/${data.id}`}
                                  >
                                    <Grid item>
                                      <ButtonBase
                                        sx={{ width: 230, height: 153 }}
                                      >
                                        <Img
                                          alt="complex"
                                          src={data.images}
                                          sx={{ borderRadius: 1 }}
                                        />
                                      </ButtonBase>
                                    </Grid>
                                  </Link>
                                  <Link
                                    to={`/EducationGuideArticlesDetail/${data.id}`}
                                  >
                                    <Grid item xs={12} sm container>
                                      <Grid item xs>
                                        <CardContent
                                          sx={{
                                            py: "0px",
                                            px: "0px",
                                            pl: "16px",
                                          }}
                                        >
                                          <Typography
                                            gutterBottom
                                            variant="subtitle1"
                                            component="div"
                                            fontWeight={600}
                                          >
                                            {parse(data.title.substring(0, 20) + "...")}
                                          </Typography>

                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                          >
                                            Paperback – {data.created}
                                          </Typography>
                                          <Typography
                                            variant="p"
                                            color="textSecondary"
                                            fontWeight={600}
                                          >
                                            {parse(
                                              data.description.substring(0, 70)
                                            )}
                                          </Typography>
                                        </CardContent>
                                      </Grid>
                                    </Grid>
                                  </Link>
                                </Box>
                              </CardContent>
                            </Item>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  ) : null}
                </Grid>
              </Box>
            </Box>
            {second_news && second_news.length === 0 ? (
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <ThemeProvider theme={theme}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      py={5}
                    >
                      <Typography variant="h6" gutterBottom>
                        We could not find what you were looking for
                      </Typography>
                      <Typography
                        variant="body2"
                        color="InactiveCaptionText"
                        gutterBottom
                      >
                        Keep calm and search again. We have so many other
                        products that you will like!
                      </Typography>
                      <Link to="/EducationGuideArticles">
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                        >
                          More Articles
                        </Button>
                      </Link>
                    </Box>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Box>
            ) : null}
          </Container>
        </Box>
      </ThemeProvider>
      <Footer />
    </div>
  );
}
