import React from "react";
import Footer from "../Footer/Footer";
import HeaderTwo from "../HeaderTwo/HeaderTwo";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import logo from "../images/logo-icon.webp";
import axios from "../Utils/Axios"; //import axios config
import {Card,CardActionArea,CardContent,CardMedia,Container,CssBaseline,Stack,Typography,IconButton,} from "@mui/material";
import { Link } from "react-router-dom";
import Rating from '@mui/material/Rating';


export default function EducationBookStores() {

  const [value, setValue] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const [books, setBooks] = React.useState([]);
  const [storebooks, setstoreBooks] = React.useState([]);

  const storeBooksList = () => {
    setLoading(true);
    axios(`Home/get_store_books`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setstoreBooks(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };


  React.useEffect(() => {
    storeBooksList();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <CssBaseline />            
      <HeaderTwo />  
      <Box sx={{ bgcolor: "#fff", pt: "80px", pb: "48px" }}>
        <Box sx={{ bgcolor: "#572672" }}>
        <Container>
      <Grid container spacing={2}>
      <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Link to="/education">
                    <Box
                      component={"img"}
                      p={1}
                      width={170}
                      height={170}
                     src={logo}
                      bgcolor="white"
                      borderRadius="50%"
                      alt=""
                      to="/home"
                    />
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    textOverflow="ellipsis"
                    overflow="hidden"
                    maxWidth="600px"
                    variant="h4"
                    gutterBottom
                    component="div"
                    color="white"
                    startIcon={<LocationOnIcon />}
                  >
                      Store Name
                  </Typography>
                  <Box sx={{ color: "white", alignItems: "center" }}>
                    <IconButton aria-label="upload picture" component="span">
                      <LocationOnIcon sx={{ color: "white" }} />
                    </IconButton>
                    <Typography
                      color="white"
                      variant="standard"
                      startIcon={<LocationOnIcon />}
                    >
                       Al Rafa Building - 36 - 105 37 D St - Dubai , United Arab Emirates 

                    </Typography>
                  </Box>
                  <Grid item xs={12} spacing={5} pt={3}>
                      <Box
                        className="star_rating_box"
                        display="flex"
                        sx={{
                          "& > legend": { pb: 5 },
                        }}
                      >
                        <Typography
                          component="legend"
                          color="white"
                        ></Typography>
                         <Stack spacing={1}>
      <Rating name="half-rating" defaultValue={2.5} precision={0.5} />
    </Stack> <span className="education_detail_rating_count"> 0 (0)</span>
                      </Box>
                    </Grid>
                </Grid>

                </Grid>

</Grid>
</Container>
</Box>
      <Container  sx={{  mt: "50px" }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {Array.from(Array(6)).map((_, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Link to="/EducationBookStoresDetail">
                <Card sx={{ maxWidth: "100%" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="https://saaehapp.com/uploads/taleem/store/bookstore/books/logo_1646815668.jpg"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Book Name
                    </Typography>
                    <Typography variant="body2" color="gray">
                    by Markus Zusak (Author)
                    </Typography>
                    <Typography variant="body2" fontWeight={600} color="text.secondary">
                    Price
                    </Typography>
                    
                    <Typography variant="body2" color="text.secondary">
                      Book Description 
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              </Link>
                  
                  
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Footer />
    </div>
  );
}
