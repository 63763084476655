import {
  Avatar,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Rating
} from "@mui/material";
import React from "react";
import axios from "../Utils/Axios"; //import axios config
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

export default function MyAccountContributionsTabsGeneral(props) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [reviews, setReviews] = React.useState([]);
  const [instireviews, setinstiReviews] = React.useState([]);

  React.useEffect(() => {
    generalList();
    institutionList();
  }, []);

  const generalList = () => {
    setLoading(true);
    axios(`Home/get_general_reviews`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setReviews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };


  const institutionList = () => {
    setLoading(true);
    axios(`Home/get_institute_reviews`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setinstiReviews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Grid item xs={12} pl={0}>
      <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{p: 3 }}>

        <Typography
            textAlign="start"
            gutterBottom
            variant="h6"
            sx={{ ml: 2, mt: 2 }}
          >
            Books Reviews 
          </Typography>
          </Grid>
          </Grid>
          {reviews.length > 0 ? (
          <div>
          {reviews.map((value, index) => {
            return (
              <div>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt={value.title} src={value.images} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={value.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {value.title}
                          </Typography>
                          {value.description}
                        </React.Fragment>
                      }
                    />
                    <Rating value={value.rating} readOnly />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              </div>
            );
          })}
          </div>
          ) : (
            <div>
              <Grid item xs={12} sx={{ ml: 2, mb: 2}}>
                <Typography>
                  No Review Found
                </Typography>
              </Grid>
            </div>
          )}

        </Card>
        </LoadingOverlay>

        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
        <Card sx={{ mt: 2 }}>
          <Typography
            textAlign="start"
            gutterBottom
            variant="h6"
            sx={{ ml: 2, mt: 2 }}
          >
            Institution Reviews
          </Typography>
          {instireviews.length > 0 ? (
          <div>
          {instireviews && instireviews.map((value, index) => {
            return (
              <div>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt={value.title} src={value.images} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={value.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {value.title}
                          </Typography>
                          {value.description}
                        </React.Fragment>
                      }
                    />
                    <Rating value={value.rating} readOnly/>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              </div>
            );
          })}
          </div>
          ) : (
            <div>
              <Grid item xs={12} sx={{ ml: 2, mt: 2 , mb: 2}}>
                <Typography>
                  No Review Found
                </Typography>
              </Grid>
            </div>
          )}
        </Card>
        </LoadingOverlay> 
      </Grid>
    </div>
  );
}
