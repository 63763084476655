import React from 'react';
import MeetCareerExperts from '../Education/MeetCareerExperts';
import TopCourses from '../Education/TopCourses';
import TopUniversities from '../Education/TopUniversities';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

export default function Home() {
    return (
        <div> 
            <Header />
            <TopUniversities />
            <TopCourses />
            <MeetCareerExperts />
            <Footer />
        </div>
    )
}
