
import {createContext,useState} from "react";
export const AuthContext = createContext(null)
export default function  Context ({children}) {
  const [loginopen, setLoginOpen] = useState(false);
  const [login, setLogin] = useState();
  return (
      <AuthContext.Provider value={{loginopen, setLoginOpen ,login, setLogin}}>
          {children}
        </AuthContext.Provider> 
  )
}