import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "../Utils/Axios"; //import axios config
import { Link, useHistory } from "react-router-dom";
import { APP_URL } from "../Constants/constants";
import { useAlert } from "react-alert";
import { Avatar, Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
      Saaeh
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#572672",
    },
    secondary: {
      main: "#11cb5f",
    },
    linkcolor: {
      main: "#013A4B",
    },
  },
});

export default function ForgotPassword() {
  const history = useHistory();
  const alert = useAlert();
  const [email, setEmail] = React.useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("email", email);
    formdata.append("url", APP_URL);
    axios("Login/user_forgotpassword", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.status === true) {
          history.push({
            pathname: "/forgotpassword",
            state: { data: true },
          });
          alert.success("Email Sent Successfully");
        } else {
          alert.error(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };


  return (
    <ThemeProvider theme={defaultTheme}>
           <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="#57267229"
        p={1}
        sx={{ height: "100vh" }}
      >
        <Box p={1} bgcolor="white" borderRadius="8px">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        ><Link to="/">
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar></Link>
          <Typography component="h1" variant="h5">
          Forgot Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
             Forgot Password
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/Login" variant="body2">
                Back to Login
                </Link>
              </Grid>
            
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      </Box>
      </Box>
    </ThemeProvider>
  );
}