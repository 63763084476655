import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CardContent, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};



export default function CentersforSpecialNeeds() {
  const [active, setaAtive] = useState(0);

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    topCoursesList(newValue);
  };

  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  //get data from json  start
  const [topCourses, setCourseList] = React.useState([]);
  const [topCities, setCitiesList] = React.useState([]);
  React.useEffect(() => {
    topCoursesList(value);
    topCitiesList();
  }, []);

  //method for getting recent jobs
  const topCoursesList = (value) => {
    setLoading(true);
    axios(`Home/topSpecialneeds?city=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCourseList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  //method for getting recent jobs
  const topCitiesList = () => {
    setLoading(true);
    axios(`Home/popularCities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCitiesList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const CourseView = (id) => {
    history.push({
      pathname: `/EducationDetail/${id}`,
    });
  };

  const viewall= (id, institute_type) => {
    history.push({
      pathname: `/EducationLisiting`,
      search: `?location=${id}&institute_type=${institute_type}`,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ bgcolor: "#faf0fc", pt: "48px", pb: "48px" }}>
        <Container maxWidth="xl">
        <Grid container spacing={3} pb={3} p={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
                <Typography noWrap variant="h5" gutterBottom component="div" color="#572672">
                Centers for Special Needs
              </Typography>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
                <Typography noWrap
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
                onClick={() => viewall(0, 5)}
              >
                View All
              </Typography>

              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={8} className="TopUniversities_bg" sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  aria-label="secondary tabs example"
                >
                  {topCities.map((value, index) => {
                    return <Tab value={value.id} label={value.title} />;
                  })}
                </Tabs>
</Grid>
              </Grid>

          
          <Box className="TopUniversities_carousel_bg">
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {topCourses.length > 0 ? (
                <Box
                  width="100%"
                >
                <Carousel
                    responsive={responsive}
                    rightChevron={<KeyboardArrowRight />}
                    leftChevron={<KeyboardArrowLeft />}
                  >
                    {topCourses.map((value, index) => {
                      return (
                        <Box
                          p={2}
                          className="TopUniversities_box"
                          onClick={() => CourseView(value.id)}
                        >
                          <Card 
                          >
                            <CardMedia
                              component="img"
                              height="300"
                              image={value.banner}
                              alt={value.course_title}
                            />
                            <Box width="100%" className="cp" >
                              <CardContent>
                              <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "Start",
                                  }}
                                >
                                  <Link to=" ">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={value.small_logo}
                                      alt=""
                                      to=""
                                    />
                                  </Link>
                                </Box>
                                <Tooltip Tooltip title={value.course_title}>
                                  <Typography
                                    sx={{ mt: 1.5 }}
                                    gutterBottom
                                    textAlign="start"
                                    variant="subtitle2"
                                    component="div"
                                    className="text_bg"
                                  >
                                    {value.course_title}
                                  </Typography>
                                </Tooltip>
                               
                              </CardContent>
                            </Box>
                          </Card>
                        </Box>
                      );
                    })}
                    </Carousel>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "650px",
                  }}
                >
                  <Box p={5} paddingBottom="105px" className="error">
                    <Typography variant="h6" color="primary">
                      <span className="error_text">No data</span> found.
                    </Typography>
                  </Box>
                </Box>
              )}
            </LoadingOverlay>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
