import React from "react";
import Footer from "../Footer/Footer";
//import HeaderTwo from "../HeaderTwo/HeaderTwo";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {Box,ButtonBase,styled,Container,FormControl,Grid,IconButton,Paper,TextField,
  Typography,Button,Stack,Divider,CardContent,createTheme,ThemeProvider,AppBar,Toolbar} from "@mui/material";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";

const theme = createTheme({
  palette: {
    primary: {
      main: "#572672",
    },
    secondary: {
      main: "#3b184e",
    },
    white: {
      main: "#fff",
    },
  },
});

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function Cart() {
  const [loading, setLoading] = React.useState(true);
  const [cartItems, setCartItems] = React.useState([]);
  const [cartCount, setCartCount] = React.useState([]);
  const [cartTotal, setCartTotal] = React.useState([]);
  const [grandTotal, serCartGrandTotal] = React.useState([]);

  React.useEffect(() => {
    getCart();
    getCartCount();
    getCartTotal();
    window.scrollTo(0, 0);
  }, []);

  const getCart = () => {
    setLoading(true);
    axios(`Cart/getCart?currency=AED`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          if (res.data.data.length === 0) {
            setCartItems([]);
            setLoading(false);
          } else {
            setCartItems(res.data.data);
            getCartTotal();
            getCartCount();
            console.log(res.data.data);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCartTotal = () => {
    axios(`Cart/getCartTotal`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          if (res.data.data.length === 0) {
            setCartTotal([]);
          } else {
            setCartTotal(res.data.data);
            serCartGrandTotal(res.data.data[0].grandtotal);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCartCount = () => {
    axios(`Cart/getCartCount`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setCartCount(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChangeQty = (index, dataType, value) => {
    const newState = cartItems.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setCartItems(newState);

    setLoading(true);
    let formdata = new FormData();
    formdata.append("cartitems", JSON.stringify(newState));
    axios(`Cart/Cartupdate`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          getCart();
          alert.error(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            getCart();
            setLoading(false);
          } else {
            getCart();
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const removeCartLogin = (store_item_id) => {
    setLoading(true);
    axios(`Cart/removeCart?store_item_id=${store_item_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          getCart();
          setLoading(false);
        } else {
          getCart();
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {/* <HeaderTwo /> */}
      <Header />
      <ThemeProvider theme={theme}>
        <Box
          sx={{ flexGrow: 1, bgcolor: "#f2f2f2" }}
          mt={5}
          py={{ xs: "50px", sm: "74px", md: "90px", lg: "90px" }}
        >
          {cartItems && cartItems.length > 0 ? (
            <div>
              <Container maxWidth="lg">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8} lg={8}>
                    <LoadingOverlay
                      active={loading}
                      styles={{
                        overlay: (base) => ({
                          ...base,
                          background: "rgba(255, 255, 255, 1.9)",
                        }),
                        spinner: (base) => ({
                          ...base,
                          width: "75px",
                          "& svg circle": {
                            stroke: "#025b95",
                          },
                        }),
                      }}
                      spinner
                      text="Loading ..."
                    >
                      {cartItems.map((row, index) => (
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <Paper
                              sx={{
                                p: 2,
                                mb: 2,
                                flexGrow: 1,
                                backgroundColor: (theme) =>
                                  theme.palette.mode === "dark"
                                    ? "#1A2027"
                                    : "#fff",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item>
                                  <Link
                                    to={`/EducationBookStoresDetail/${row.store_item_id}`}
                                  >
                                    <ButtonBase
                                      sx={{
                                        width: 200,
                                        height: "100px",
                                        maxHeight: "250px",
                                      }}
                                    >
                                      <Img
                                        alt="complex"
                                        src={row.image}
                                        sx={{ borderRadius: 1 }}
                                      />
                                    </ButtonBase>
                                  </Link>
                                </Grid>
                                <Grid item xs={12} sm container>
                                  <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    spacing={2}
                                  >
                                    <Grid item xs>
                                      <Link
                                        to={`/EducationBookStoresDetail/${row.store_item_id}`}
                                      >
                                        <Typography
                                          color="primary"
                                          gutterBottom
                                          variant="subtitle1"
                                          component="div"
                                        >
                                          {row.title}
                                        </Typography>
                                      </Link>
                                      <Typography variant="body2">
                                        {row.product_price}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <FormControl
                                        variant="standard"
                                        sx={{ minWidth: 90 }}
                                      >
                                        <TextField
                                          label="Quantity"
                                          variant="outlined"
                                          size="small"
                                          type="number"
                                          value={row.qty}
                                          onChange={(e) =>
                                            handleChangeQty(
                                              index,
                                              "qty",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <IconButton
                                      onClick={() =>
                                        removeCartLogin(row.store_item_id)
                                      }
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      ))}
                    </LoadingOverlay>

                    <Box className="cartbg" sx={{ mt: 2, bgcolor: "none" }}>
                      <Grid>
                        <ThemeProvider theme={theme}>
                          <Link to="/">
                            <Button
                              className="cart-primary"
                              size="small"
                              variant="contained"
                              startIcon={<ArrowBackIcon />}
                            >
                              Continue Shopping
                            </Button>
                          </Link>
                        </ThemeProvider>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4} spacing={3}>
                    <Item
                      sx={{
                        flexGrow: 0,
                        bgcolor: "#fcf7ff",
                        textAlign: "center",
                      }}
                    >
                      <CardContent>
                        <Box>
                          <Grid item sm container>
                            <Grid item xs container direction="row" spacing={1}>
                              <Grid item xs>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="div"
                                >
                                  ORDER SUMMARY
                                </Typography>

                                <LoadingOverlay
                                  active={loading}
                                  styles={{
                                    overlay: (base) => ({
                                      ...base,
                                      background: "rgba(255, 255, 255, 1.9)",
                                    }),
                                    spinner: (base) => ({
                                      ...base,
                                      width: "75px",
                                      "& svg circle": {
                                        stroke: "#025b95",
                                      },
                                    }),
                                  }}
                                  spinner
                                  text="Loading ..."
                                >
                                  <Grid item py={1}>
                                    <Divider />
                                  </Grid>
                                  <Stack
                                    className="similar"
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography variant="button">
                                      Total items
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {cartCount}
                                    </Typography>
                                  </Stack>

                                  <Grid item py={1}>
                                    <Divider />
                                  </Grid>
                                  <Stack
                                    className="similar"
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      fontWeight={600}
                                      component="div"
                                    >
                                      Grand Total
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      fontWeight={600}
                                      component="div"
                                    >
                                      {cartTotal[0] && cartTotal[0].subtotal}
                                    </Typography>
                                  </Stack>
                                </LoadingOverlay>
                                <Box
                                  className="cartbg"
                                  display={{ xs: "none", sm: "block" }}
                                >
                                  <ThemeProvider theme={theme}>
                                    <Link to="/Checkout">
                                      <Button
                                        variant="contained"
                                        className="cart-primary"
                                        fontWeight="bold"
                                      >
                                        <IconButton
                                          color="inherit"
                                          aria-label="open drawer"
                                        >
                                          <CheckCircleIcon />
                                        </IconButton>
                                        Proceed to checkout
                                      </Button>
                                    </Link>
                                  </ThemeProvider>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Item>
                  </Grid>
                </Grid>
              </Container>
            </div>
          ) : (
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  display: { xs: "block", sm: "none" },
                }}
              >
                <AppBar
                  position="fixed"
                  color="secondary"
                  sx={{ top: "auto", bottom: 0 }}
                >
                  <Toolbar sx={{ justifyContent: "center" }}>
                    <ThemeProvider theme={theme}>
                      <Link to="/Checkout">
                        <IconButton color="white" aria-label="open drawer">
                          <CheckCircleIcon />
                        </IconButton>
                        <Button color="white">Proceed to checkout</Button>
                      </Link>
                    </ThemeProvider>
                  </Toolbar>
                </AppBar>
              </Box>

              <ThemeProvider theme={theme}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ShoppingCartIcon color="secondary" sx={{ fontSize: 240 }} />
                  <Typography variant="h6" gutterBottom>
                    Your shopping cart looks empty
                  </Typography>
                  <Typography
                    variant="body2"
                    color="InactiveCaptionText"
                    gutterBottom
                  >
                    Looks like you haven't added anything to your cart yet!
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Link to="/">
                      <Button
                        size="small"
                        color="secondary"
                        startIcon={<ArrowBackIcon />}
                        variant="contained"
                      >
                        Continue Shopping
                      </Button>
                    </Link>
                  </ThemeProvider>
                </Box>
              </ThemeProvider>
            </LoadingOverlay>
          )}
        </Box>
      </ThemeProvider>
      <Footer />
    </div>
  );
}
