import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { createTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import "react-multi-carousel/lib/styles.css";
import logo from "../images/logo-icon.webp";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // alignItems: "center",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
      //  transform: "scale(1.1)",
    },
  }),
}));

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
};

export default function EducationCourseListing_BusinessStudies({
  categoryId,
  setcategoryId,
}) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    categoryList(newValue);
  };

  //get data from json  start
  const [categorylist, setCategoryList] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [topCities, setCitiesList] = React.useState([]);
  React.useEffect(() => {
    categoryList(value);
    topCitiesList();
    getcategory();
  }, []);

  //method for getting recent jobs
  const categoryList = (value) => {
    setLoading(true);
    axios(
      `Course/course_category_list?categoryId=${categoryId}&city=${value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCategoryList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const getcategory = () => {
    setLoading(true);
    axios(`Course/get_category?categoryId=${categoryId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCategory(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  //method for getting recent jobs
  const topCitiesList = () => {
    setLoading(true);
    axios(`Home/popularCities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCitiesList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const CourseView = (id) => {
    history.push({
      pathname: `/EducationCourseDetail/${id}`,
    });
  };

  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ bgcolor: "#fff", pt: "98px", pb: "48px" }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="#572672"
              >
                {category[0] && category[0].name}
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box className="cp_bg" sx={{ p: 1, flexGrow: 1 }}>
              <Typography
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
              >
                26 Courses in {category[0] && category[0].name}
              </Typography>
            </Box>
            <Box className="TopUniversities_bg">
              <Box
                sx={{
                  maxWidth: { xs: 320, sm: 480 },
                  bgcolor: "background.paper",
                }}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  aria-label="secondary tabs example"
                >
                  {topCities.map((value, index) => {
                    return <Tab value={value.id} label={value.title} />;
                  })}
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box className="TopUniversities_carousel_bg">
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {categorylist.length > 0 ? (
                <Box width="100%">
                  <Grid container spacing={3}>
                    {categorylist.map((value, index) => {
                      return (
                        <Grid item xs={3}
                          className="TopUniversities_box"
                          p={2}
                          onClick={() => CourseView(value.id)}
                        >
                          <Card className={classes.TopUniversitiesCard}>
                            <CardMedia
                              component="img"
                              height="300"
                              image={value.banner}
                              alt={value.title}
                            />
                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CardMedia
                                    sx={{
                                      width: 80,
                                      height: 80,
                                      p: 0.5,
                                      boxShadow: 3,
                                      borderRadius: "50%",
                                      overflow: "hidden",
                                      Border: "solid 5px #978d8d",
                                      mt: -9.5,
                                      bgcolor: "#fff",
                                    }}
                                    component={"img"}
                                    src={logo}
                                    alt=""
                                  />
                                </Box>
                                <Tooltip Tooltip title={value.title}>
                                  <Typography
                                    Tooltip
                                    title={value.title}
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    gutterBottom
                                    textAlign="start"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    {value.title}
                                  </Typography>
                                </Tooltip>
                              </CardContent>
                            </Box>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "650px",
                  }}
                >
                  <Box p={5} paddingBottom="105px" className="error">
                    <Typography variant="h6" color="primary">
                      <span className="error_text">No data</span> found.
                    </Typography>
                  </Box>
                </Box>
              )}
            </LoadingOverlay>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
