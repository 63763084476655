import React, { useState , useRef} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import logo_skeleton from "../images/skeleton/logo_skeleton.webp";
import { CardMedia } from "@mui/material";
import { styled } from "@mui/material/styles";

const Input = styled("input")({
  display: "none",
});

export default function MyAccount_ManageListingAddCourses() {

  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [courselevel, setCourseLevel] = React.useState([""]);
  const [coursetutor, setCourseTutor] = React.useState([""]);
  const [coursetype, setCourseType] = React.useState([""]);
  const [coursecategories, setCourseCategory] = React.useState([""]);
  const [insti_crslevel, setInsti_crslevel] = React.useState("");
  const [insti_crstutor, setInsti_crstutor] = React.useState("");
  const [insti_crstype, setInsti_crstype] = React.useState("");
  const [insti_crscategory, setInsti_crscategory] = React.useState("");
  const [course_name, setCourse_name] = React.useState("");
  const [uploadImage, setUploadImage] = React.useState("");
  const [uploadBanner, setUploadBanner] = React.useState("");
  


  React.useEffect(() => {
    getCourseLevel();
    getCourseTutor();
    getCourseType();
    getCourseCategory();
  }, []);

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("course_name", course_name);
    formdata.append("logo", uploadImage);
    formdata.append("banner", uploadBanner);
    formdata.append("course_level", insti_crslevel);
    formdata.append("course_tutor", insti_crstutor);
    formdata.append("course_type", insti_crstype);
    formdata.append("course_category", insti_crscategory);
    formdata.append("add_type", "company");
    console.log(uploadImage);
    axios(`Course/add_course`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };


  const getCourseLevel = () => {
    axios(`Course/get_course_level`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCourseLevel(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const getCourseTutor = () => {
    axios(`Course/get_course_tutor`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCourseTutor(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const getCourseType = () => {
    axios(`Course/get_course_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCourseType(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };



  const getCourseCategory = () => {
    axios(`Course/get_course_category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCourseCategory(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "47%" },
      }}
      sm={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      md={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          required
          label="Course Name"
          defaultValue="Name of Institution"
          value={course_name}
          onChange={(e) => setCourse_name(e.target.value)}
        />
        <Autocomplete
                  options={courselevel}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => value && setInsti_crslevel(value.id)}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Level"
                      value={insti_crslevel}
                    />
                  )}
                />
        
        <Autocomplete
                  options={coursetutor}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => value && setInsti_crstutor(value.id)}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Tutor"
                      value={insti_crstutor}
                    />
                  )}
                />
      <Autocomplete
                  options={coursecategories}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => value && setInsti_crscategory(value.id)}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Category"
                      value={insti_crscategory}
                    />
                  )}
                />



      <Autocomplete
                  options={coursetype}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => value && setInsti_crstype(value.id)}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Type"
                      value={insti_crstype}
                    />
                  )}
                />

        <Box display="flex" flexGrow={1}>
              <label>
                <Input
                  //accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => setUploadImage(e.target.files[0])}
                />
                <Button variant="contained" component="span">
                  Institute Logo
                </Button>
              </label>
              <label htmlFor="contained-button-file">
                {/* <Input accept="image/*" id="icon-button-file" type="file" /> */}
                <CardMedia
                  image={logo_skeleton}
                  border="solid 1px #0f0d0d"
                  sx={{
                    width: 70,
                    height: 70,
                    m: 1,
                    mt: 0,
                    mb: 3,
                    border: "solid 1px #d8d5d5",
                    borderRadius: 1,
                  }}
                ></CardMedia>
              </label>
        </Box>


        <Box display="flex" flexGrow={1}>
              <label>
                <Input
                  accept="image/*"
                  id="contained-button-file2"
                  multiple
                  type="file"
                  onChange={(e) => setUploadBanner(e.target.files[0])}
                />
                <Button variant="contained" component="span">
                  Institute Banner
                </Button>
              </label>
              <label htmlFor="contained-button-file2">
                {/* <Input accept="image/*" id="icon-button-file" type="file" /> */}
                <CardMedia
                  image={logo_skeleton}
                  border="solid 1px #0f0d0d"
                  sx={{
                    width: 150,
                    height: 70,
                    m: 1,
                    mt: 0,
                    mb: 3,
                    border: "solid 1px #d8d5d5",
                    borderRadius: 1,
                  }}
                ></CardMedia>
              </label>
        </Box>

        <Box
          className="AddCourses_bg"
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Stack spacing={2} direction="row">
            <Link to="/MyAccount_ManageListingAddCoursesEdit">
              <Button
                color="info"
                variant="contained"
                size="small"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Link>
            <Button
              color="info"
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIcon />}
              onClick={submitForm}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </div>
    </Box>
  );
}
