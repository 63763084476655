import React from "react";
import Footer from "../Footer/Footer";
//import HeaderTwo from "../HeaderTwo/HeaderTwo";
import { Link, useParams } from "react-router-dom";
import {Box,Rating,Container,Typography,Grid,ButtonBase,createTheme,ThemeProvider,IconButton,Tooltip,Avatar,Card,CardMedia,} from "@mui/material";
import { useAlert } from "react-alert";
import axios from "../Utils/Axios"; //import axios config
import StarIcon from "@mui/icons-material/Star";
import { ArrowBackOutlined } from "@material-ui/icons";
import Logo from "../images/logo-icon.webp";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import CommentDialog from "./CommentDialog";
import AffiliationLinkDialog from "./AffiliationLinkDialog";
import parse from "html-react-parser";
import Header from "../Header/Header";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};
const theme = createTheme({
  palette: {
    primary: {
      light: "#7b37a0",
      main: "#572672",
      dark: "#3f1b52",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        fontSize: "0.875rem",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default function EducationBooksDetail(props) {
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const [books, setBooks] = React.useState({});
  const [booksId, setBookId] = React.useState(params && params.id);
  const [allrating, setRating] = React.useState([]);

  React.useEffect(() => {
    setBookId(params && params.id ? params.id : "");
  }, [params]);

  React.useEffect(() => {
    getbooks();
  }, [booksId]);
  const getbooks = () => {
    setLoading(true);
    axios(`Home/get_book_details?booksId=${booksId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setBooks(res.data.data[0]);
          setRating(res.data.data[0].all_rating);
          setLoading(false);
          setValue(res.data.data[0].rating);
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(
    () => {
      window.scrollTo(0, 0);
    }, 
    []
  );
  return (
    <div>
      {/* <HeaderTwo /> */}
      <Header />
      <ThemeProvider theme={theme}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svfg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
          <Box
            className="caro_bg"
            sx={{ bgcolor: "#fff", pt: "110px", pb: "48px" }}
          >
            <Container maxWidth="xl">
              <Box
                sx={{
                  p: 2,
                  margin: "auto",
                  flexGrow: 1,
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <ButtonBase sx={{ maxWidth: "750px", maxHeight: "800px" }}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="740"
                          image={books.image}
                          alt="green iguana"
                        />
                      </Card>
                    </ButtonBase>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm
                    container
                    className="MyAccountProfileBg"
                  >
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography
                          gutterBottom
                          variant="h6"
                          color="primary"
                          component="div"
                        >
                          {books.title}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Paperback – {books.created}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                        >
                          by {books.author}
                        </Typography>
                        <AffiliationLinkDialog links={books.links}/>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          {books.description && parse(books.description)}
                        </Typography>
                        <Box
                          sx={{
                            width: 200,
                            display: "flex",
                            alignItems: "center",
                            my: 2,
                          }}
                        >
                          <Rating
                           readOnly 
                            name="hover-feedback"
                            value={value}
                            precision={0.5}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          {value !== null && (
                            <Box sx={{ ml: 2 }}>
                              {labels[hover !== -1 ? hover : value]}
                            </Box>
                          )}
                        </Box>
                        <Typography gutterBottom>
                          Share your thoughts with other customers
                        </Typography>
                        <CommentDialog bookid={books.id} getbooks={getbooks}/>
                        {allrating &&
                          allrating.map((value, index) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  p: 1,
                                  m: 1,
                                  height: "auto",
                                  width: "100%",
                                  borderRadius: 1,
                                }}
                              >
                                <Item>
                                  <Avatar
                                    alt="Education Books"
                                    src={Logo}
                                    sx={{ width: 56, height: 56 }}
                                  />
                                </Item>
                                <Item>
                                  <Typography fontWeight={600}>
                                    {value.title}
                                  </Typography>
                                  {value.review}
                                </Item>
                              </Box>
                            );
                          })}

                        <Link to="/EducationAllbooks">
                          <Tooltip title=" Back to Books">
                            <Typography display="inline-block">
                              <IconButton>
                                <ArrowBackOutlined />
                              </IconButton>
                            </Typography>
                          </Tooltip>
                          <Typography
                            display="inline-block"
                            variant="subtitle1"
                            component="div"
                          >
                            Back to Books
                          </Typography>{" "}
                        </Link>
                      </Grid>
               
                    </Grid>
                    {/* <Grid item display={{ xs: "none", md: "block" }}>
                      <Link to="/EducationAllbooks">
                        <Tooltip title=" Back to Books">
                          <Typography display="inline-block">
                            <IconButton>
                              <ArrowBackOutlined />
                            </IconButton>
                          </Typography>
                        </Tooltip>   
                      </Link>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </LoadingOverlay>
      </ThemeProvider>
      <Footer />
    </div>
  );
}
