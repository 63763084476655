import React, { useState } from "react";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import image2 from "../../images/logo.webp";
import axios from "../../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

const useStyle = makeStyles({
  image2: {
    borderRadius: 100,
  },
});

export default function CreateNewGroupDialog({id,Lists}) {

  const [communities, setCommunities] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const alert = useAlert();
  const [image, setImage] = React.useState("");
  const [banner, setBanner] = React.useState("");

  const [uploadImage, setUploadImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");

  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };


  var loadBanner = (event) => {
    if (event.target.files[0]) {
      setBannerImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setBanner(event.target.files[0]);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  const handleClickOpen = () => {
    setOpen(true);
    setLoading(true);
    axios(`Home/get_group_details?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setCommunities(res.data.data[0]);
          setUploadImage(res.data.data[0].logo);
          setBannerImage(res.data.data[0].banner);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };


  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("title", communities.title);
    formdata.append("description", communities.description);
    formdata.append("logo", image);
    formdata.append("banner", banner);
    axios(`Home/update_group`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setOpen(false);
          Lists();
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };


  return (
    <div>
      <IconButton edge="end" aria-label="edit" onClick={handleClickOpen}>
          <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Edit Community </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField  
                size="small"
                fullWidth
                required
                label="Community Name"
                defaultValue="Community Name"
                variant="outlined"
                value={communities.title}
                onChange={(e) => {
                  setCommunities({ ...communities, title: e.target.value });
                }}
              />
            </Grid>
 
            
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Community description"  
                defaultValue="Community description"
                multiline
                rows={4}
                value={communities.description}
                onChange={(e) => {
                  setCommunities({ ...communities, title: e.target.value });
                }}
              />
            </Grid>
           
          
            <Grid item xs={12} md={6} lg={6}>
              <Paper>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="body2">Logo</Typography>

                  <img
                    src={uploadImage ? uploadImage : image2}
                    className={classes.image2}
                    id="output"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCameraIcon />
                  </label>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file2"
                    onChange={loadBanner}
                    style={{ display: "none" }}
                  />
                  <Typography variant="body2">Banner</Typography>

                  <img
                    src={bannerImage ? bannerImage : image2}
                    className={classes.image2}
                    id="output2"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file2" style={{ cursor: "pointer" }}>
                    <PhotoCameraIcon />
                  </label>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="contained"
            onClick={submitForm}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
