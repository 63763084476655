import React, { useState, useRef } from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import image from "../images/user.webp";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import JoditEditor from "jodit-react";

const useStyle = makeStyles({
  image: {
    borderRadius: 100,
  },
});

const currencies = [
  {
    value: "ONE",
    label: "Active",
  },
  {
    value: "TWO",
    label: "In-Active",
  },
];

export default function MyAccountContributionsArticlesDialog({ articlesList }) {
  const [Category, setCategory] = React.useState("");

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const [state, setState] = React.useState({
    Active: true,
  });

  const handleChangeActive = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const editor = useRef(null);
  const [content, setContent] = useState("Start writing");
  const config = {
    readonly: false,
    height: 400,
  };

  const alert = useAlert();
  const [uploadImage, setUploadImage] = useState("");
  const [name, set_name] = React.useState("");
  const [author, set_author] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [article, setArticles] = React.useState([]);
  const [categories, setCategories] = React.useState({});
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };
  const [proimage, setImage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    getCategories();
  }, []);

  const [countriesss, setCountires] = useState([]);

  const setCategorySelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setCategory(arr);
  };

  const getCategories = () => {
    axios(`Article/get_news_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const addArticle = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("author", author);
    formdata.append("status", JSON.stringify(state));
    formdata.append("description", desc);
    formdata.append("logo", proimage);
    formdata.append("categories", JSON.stringify(Category));

    axios(`Article/add_article`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          setArticles(res.data.data);
          articlesList();
          alert.success(res.data.message);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const [status2, setStatus2] = React.useState("ONE");

  const handleChange = (event) => {
    setStatus2(event.target.value);
  };

  return (
    <div className="MyAccount_bg">
      <Tooltip title="Add Contributions">
        <Button
          color="primary"
          variant="outlined"
          startIcon={<AddCircleIcon />}
          noWrap
          onClick={handleClickOpen}
        >
          Add Articles
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Articles </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography guttterBottom>
              Add Articles to your account here. You can add multiple
              contributions.
            </Typography>
          </DialogContentText>

          <Grid container item spacing={3}>
            <Grid item xs={12} md={4}>
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  mt={3}
                >
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="body2">Banner, logo </Typography>

                  <img
                    src={uploadImage ? uploadImage : image}
                    className={classes.image}
                    id="output"
                    width="100%"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCamera />
                  </label>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                sx={{ pt: 4 }}
                autoFocus
                id="outlined-textarea"
                //  label="Title"
                placeholder="Title"
                variant="outlined"
                fullWidth
                //     multiline
                value={name}
                onChange={(e) => set_name(e.target.value)}
              />
              <FormControl
                variant="standard"
                fullWidth
                size="small"
                sx={{ py: 2 }}
              >
                <Autocomplete
                  size="small"
                  multiple
                  id="country-select-demo"
                  options={countriesss}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  value={countriesss.filter(
                    (countriesss) =>
                      Category && Category.includes(countriesss.id)
                  )}
                  onChange={(e, value) => {
                    setCategorySelect(value);
                  }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a category"
                      //     variant="outlined"
                    />
                  )}
                />
              </FormControl>
              {/* <FormControl
                component="fieldset"
                variant="standard"
                fullWidth
                sx={{ py: 2 }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.Active}
                        onChange={handleChangeActive}
                        name="Active"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </FormControl> */}

              <TextField
                fullWidth
                size="small"
                id="outlined-select-status-native"
                select
                label="Select status"
                value={status2}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                //   helperText="Please select your status"
              >
                {currencies.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <JoditEditor
                ref={editor}
                value={desc}
                config={config}
                onBlur={(e) => {
                  setDesc(e);
                }}
                onChange={(newContent) => {}}
              />
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="MyAccountProfileBg">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addArticle} className="primaryButton">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
