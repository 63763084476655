import React from "react";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,

  Typography,
} from "@mui/material";
import RadialBarChart from "./dashboard/RadialBarChart";
import RadialBartwo from "./dashboard/RadialBartwo";
import RadialBarThree from "./dashboard/RadialBarThree";
import TopCards from "./dashboard/TopCards";

export default function MyAccountEditDashboard({ insti_id }) {
  const [page, setPage] = React.useState(1);
  const [experts, setExpertsList] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    manageList(insti_id);
  }, [page, rowsPerPage]);

  const manageList = (insti_id) => {
    setLoading(true);
    axios(
      `Course/course_instituitions?institue_id=${insti_id}&page=${page}&per_page=${rowsPerPage}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTotal(res.data.total);
          setExpertsList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setPage(newValue);
  };

  const history = useHistory();
  //  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);

  const getInstituitionEdit = (insti_id, id) => {
    history.push({
      pathname: `/MyAccount_ManageListingAddCoursesEdit/${insti_id}/${id}`,
    });
  };

  return (
    <div>
      <Grid container item spacing={3}>
  
        {experts && experts.length > 0 ? (
          <Grid item xs={12}>
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {experts &&
                experts.map((value, index) => {
                  return (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TopCards />
                      </Grid>
                      <Grid item xs={12} sm={12} md={9}>
                        <Card>
                          <CardContent>
                            <RadialBarChart />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} spacing={3}>
                        <Grid item xs={12} md={12} >
                          <Card>
                            <CardContent>
                              <RadialBartwo />
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={12} pt={3}>
                          <Card>
                            <CardContent>
                              <RadialBarThree />
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>

                    </Grid>
                  );
                })}
            </LoadingOverlay>
           
          </Grid>
        ) : (
          <Box className="EducationProgramsContainer">
            <Box p={1} className="EducationDetailPrograms_bg cp">
              <Box p={1}>
                <Typography
                  sx={{ mt: 1.5 }}
                  gutterBottom
                  variant="subtitle2"
                  component="div"
                >
                  No Course Found
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
    </div>
  );
}
