import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MyAccountManageListingRegister from "./MyAccount_ManageListingRegister";
import MyAccountPagesCategory from "./MyAccountPagesCategory";
import MyAccount_ManageListingAddCourses from "./MyAccount_ManageListingAddCourses";
import { Grid } from "@mui/material";
import { green, purple } from "@mui/material/colors";
import { useAlert } from "react-alert";
import axios from "../Utils/Axios"; //import axios config

const theme = createTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: green[500],
    },
  },
});
const steps = ["Choose business type ", "Registration details"];

//const theme = createTheme();

export default function MyAccountPagesStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [checkedItem, setcheckedItem] = React.useState(0);
  const alert = useAlert();

  const [institute, setInstitute] = React.useState({});
  const [proimage, setLogo] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [content, setDescription] = React.useState("");
  const [city, setInsti_cities] = React.useState("");
  

    function getStepContent(step) {
      switch (step) {
        case 0:
          return <MyAccountPagesCategory checkedItem={checkedItem} setcheckedItem={setcheckedItem}/>;
        case 1:
          return <MyAccountManageListingRegister checkedItem={checkedItem} setcheckedItem={setcheckedItem} setInstitute={setInstitute} institute={institute} setLogo={setLogo} setBanner={setBanner} setDescription={setDescription} setInsti_cities={setInsti_cities} />;
        default:
          throw new Error('Unknown step');
      }
    }

    const handleNext = (event) => {
      if (checkedItem === 0) {
        alert.error("Select any Category");
      } else {
      setActiveStep(activeStep + 1);
      if (activeStep === 1) {
        setActiveStep(activeStep);
        event.preventDefault();
        let formdata = new FormData();
        formdata.append("instituiontype", checkedItem);
        formdata.append("logo", proimage);
        formdata.append("banner", banner);
        formdata.append("description", content);
        formdata.append("city", city);
        formdata.append("institute", JSON.stringify(institute));
        if(checkedItem!=6)
        {
        axios(`Institution/add_institution`, {
          method: "POST",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        })
          .then((res) => {
            if (res.data.status) {
              console.log(res.data.data);
              alert.success("Order placed successfully");
            } else {
              alert.error(res.data.message);

            }
          })
          .catch((err) => console.log(err));
        }
        else
        {
          axios(`Bookstore/add_bookstore`, {
            method: "POST",
            data: formdata,
            headers: {
              "Content-Type": "multipart/form-data",
              "x-api-key": JSON.parse(localStorage.getItem("data")),
            },
          })
            .then((res) => {
              if (res.data.status) {
                console.log(res.data.data);
                alert.success("Order placed successfully");
              } else {
                alert.error(res.data.message);

              }
            })
            .catch((err) => console.log(err));
        }
      }
      }
    };
  
    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };
  

    console.log(institute);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your registration.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your registration number is #2001539. We have emailed your
                    registration details, confirmation. If you have any
                    questions, please let me know!
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <Box
                    className="MyAccountProfileBg"
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Back
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      className="primaryButton"
                      onClick={handleNext}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1 ? "submit" : "Next"}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
