import React from "react";
import Footer from "../Footer/Footer";
import HeaderTwo from "../HeaderTwo/HeaderTwo";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import calicutbook from "../images/Education_Detail/calicutbook.webp";
import { Link, useParams } from "react-router-dom";
import axios from "../Utils/Axios"; //import axios config
import {Avatar,Card,CardActionArea,CardContent,CardMedia,Container,createTheme,IconButton,ThemeProvider,Typography,} from "@mui/material";
import LoadingOverlay from "react-loading-overlay";

const theme = createTheme({
  palette: {
    primary: {
      light: "#7b37a0",
      main: "#572672",
      dark: "#3f1b52",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

export default function EducationBookStoresDetailAddressBook() {

  const params = useParams();
  const [bookstore, setBookStore] = React.useState([]);
  const [storeId, setStoreId] = React.useState(params && params.id);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getbooks();
  }, [storeId]);
  const getbooks = () => {
    setLoading(true);
    axios(`Bookstore/get_bookstore_info?store_id=${storeId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setBookStore(res.data.data[0]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <HeaderTwo />
      <ThemeProvider theme={theme}>
        <Box
          className="caro_bg"
          sx={{ bgcolor: "#f9f9f9", pt: "110px", pb: "48px" }}
        >
          <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                  <Typography variant="h5" textAlign="center" fontWeight={600} gutterBottom color="primary">
                  {bookstore.title}
                  </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                <Grid item xs={12} md={3} lg={2} >
                  <Card sx={{ bgcolor: "#f7f7f7 " }}>
                  <LoadingOverlay
                      active={loading}
                      styles={{
                        overlay: (base) => ({
                          ...base,
                          background: "rgba(255, 255, 255, 1.9)",
                        }),
                        spinner: (base) => ({
                          ...base,
                          width: "75px",
                          "& svg circle": {
                            stroke: "#025b95",
                          },
                        }),
                      }}
                      spinner
                      text="Loading ..."
                    >
                    <CardActionArea>
                      <Avatar
                        alt="Taleem"
                        src={calicutbook}
                        sx={{ width: 156, height: 156, margin: "auto", mt: 2 }}
                      />

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                         {bookstore.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                        >
                          {bookstore.description}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          fontWeight={600}
                          gutterBottom
                        >
                          {bookstore.tel}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                        >
                          {bookstore.website}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          fontWeight={600}
                          gutterBottom
                        >
                          email:{bookstore.email}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    </LoadingOverlay>
                  </Card>
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                <LoadingOverlay
                      active={loading}
                      styles={{
                        overlay: (base) => ({
                          ...base,
                          background: "rgba(255, 255, 255, 1.9)",
                        }),
                        spinner: (base) => ({
                          ...base,
                          width: "75px",
                          "& svg circle": {
                            stroke: "#025b95",
                          },
                        }),
                      }}
                      spinner
                      text="Loading ..."
                    >
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {bookstore.books && bookstore.books.map((value, index) => {
                      return (
                      <Grid item xs={12} md={4} lg={3} key={index}>
                        <Card>
                        <Link to={`/EducationBookStoresDetail/${value.id}`}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image={value.images}
                              alt="Taleem"
                            />
                            <CardContent>
                              <Typography
                                variant="subtitle2"
                                component="div"
                              >
                                {value.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {value.author}
                              </Typography>
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                color="text.secondary"
                              >
                                AED {value.price}
                              </Typography> 
                            </CardContent>
                          </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                      );
                    })}
                  </Grid>
                  </LoadingOverlay>
                  <Grid container xs={12}>
                    <Link to="/EducationBookStores">
                      <IconButton>
                        <ArrowBackIcon />
                      </IconButton>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>

      <Footer />
    </div>
  );
}
