import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HeaderFour from "../HeaderFour/HeaderFour";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import logo_skeleton from "../images/skeleton/logo_skeleton.webp";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import {
  Autocomplete,
  createTheme,
  Card,
  CardContent,
  Tabs,
  Tab,
  Grid,
  Box,
  Divider,
  Stack,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import MyAccountManageListingAddCoursesEditCourseOverviewTextEditor from "./MyAccount_ManageListingAddCoursesEditCourseOverviewTextEditor";
import Orders from "../Cart/Orders";
import MyAccountBookStoreEditProductDialog from "./MyAccountBookStoreEditProductDialog";
import MyAccountBookStoreEditProductDialogEdit from "./MyAccountBookStoreEditProductDialogEdit";

const theme = createTheme({
  palette: {
    primary: {
      light: "#833baa",
      main: "#572672",
      dark: "#381849",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const useStyle = makeStyles({
  image: {
    maxWidth: "100%",
  },
  image2: {
    borderRadius: 10,
    maxWidth: "100%",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export default function MyAccountBookStoreEdit(props) {
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [value, setValue] = React.useState(0);
  const [books, setBooksList] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [status, setStatus] = React.useState("");
  const StatushandleChange = (event) => {
    setStatus(event.target.value);
  };

  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  var loadFile2 = (event) => {
    if (event.target.files) {
      setUploadBanner(URL.createObjectURL(event.target.files[0]));
      setBanner(event.target.files[0]);
    }
  };

  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [insti_name, setInsti_name] = React.useState("");
  const [insti_email, setInsti_email] = React.useState("");
  const [insti_mobile, setInsti_mobile] = React.useState("");
  const [insti_address, setInsti_address] = React.useState("");
  const [insti_city, setInsti_city] = React.useState("");
  const [insti_zipcode, setInsti_zipcode] = React.useState("");
  const [insti_website, setInsti_website] = React.useState("");
  const [countriesss, setCountires] = React.useState([]);
  const [instituioncities, setInstitutionCities] = React.useState([]);
  const [country, set_country] = React.useState("");
  const [uploadImage, setUploadImage] = React.useState("");
  const [bannerImage, setUploadBanner] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [image, setImage] = React.useState("");
  const [overview, setOverview] = React.useState("");

  const [store_id] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );

  const getCountries = () => {
    axios(`Settings/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = () => {
    axios(`Institution/getCities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setInstitutionCities(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    editPost();
    getCountries();
    getCities();
    manageListing(store_id);
  }, [page, rowsPerPage]);

  const editPost = () => {
    setLoading(true);
    axios(`Home/get_store_info?store_id=${store_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error === true) {
        } else {
          setLoading(false);
          setInsti_name(res.data.data[0].title);
          setInsti_email(res.data.data[0].email);
          setInsti_mobile(res.data.data[0].mobile);
          setInsti_address(res.data.data[0].address);
          setInsti_city(res.data.data[0].city);
          setInsti_zipcode(res.data.data[0].zipcode);
          setInsti_website(res.data.data[0].website);
          setImage(res.data.data[0].logo);
          setUploadBanner(res.data.data[0].banner);
          setOverview(res.data.data[0].description);
          setStatus(res.data.data[0].status);
        }
      })
      .catch((err) => console.log(err));
  };

  let inst_image;
  if (image !== "") {
    inst_image = image;
  } else {
    inst_image = logo_skeleton;
  }

  let inst_banner;
  if (banner !== "") {
    inst_banner = banner;
  } else {
    inst_banner = logo_skeleton;
  }

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("store_id", store_id);
    formdata.append("name", insti_name);
    formdata.append("country", country);
    formdata.append("logo", image);
    formdata.append("banner", banner);
    formdata.append("email", insti_email);
    formdata.append("mobile", insti_mobile);
    formdata.append("address", insti_address);
    formdata.append("city", insti_city);
    formdata.append("zipcode", insti_zipcode);
    formdata.append("website", insti_website);
    axios(`Bookstore/update_store`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const overviewsubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("store_id", store_id);
    formdata.append("overview", overview);
    axios(`Bookstore/update_store_overview`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const manageListing = (store_id) => {
    setLoading(true);
    axios(
      `Bookstore/store_books?store_id=${store_id}&page=${page}&per_page=${rowsPerPage}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTotal(res.data.total);
          setBooksList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <HeaderFour />
      <Box
        sx={{ bgcolor: "#F6F9FC" }}
        py={{ xs: "1rem", sm: "2rem", md: "3rem", lg: "4rem" }}
      >
        <Container maxWidth="xl">
          <ThemeProvider theme={theme}>
            <Box
              pt={{ xs: "100px", sm: "60px", md: "48px", lg: "48px" }}
              pb={{ xs: "16px", sm: "16px", md: "48px", lg: "48px" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Tabs
                        className="MyAccountEditTabs"
                        orientation="vertical"
                        fullWidth
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                      >
                        <Tab
                          className="tabac"
                          label="Store Details"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="tabac"
                          label="Overview"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="tabac"
                          label="Products"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="tabac"
                          label="Orders"
                          {...a11yProps(3)}
                        />
                      </Tabs>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={8} lg={9}>
                  <Card>
                    <TabPanel value={value} index={0}>
                      <LoadingOverlay
                        active={loading}
                        styles={{
                          overlay: (base) => ({
                            ...base,
                            background: "rgba(255, 255, 255, 1.9)",
                          }),
                          spinner: (base) => ({
                            ...base,
                            width: "75px",
                            "& svg circle": {
                              stroke: "#025b95",
                            },
                          }),
                        }}
                        spinner
                        text="Loading ..."
                      >
                        <Grid container item spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Typography
                              textAlign="start"
                              gutterBottom
                              variant="h6"
                              sx={{ mb: 1.5 }}
                            >
                              Store Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Link to="/MyAccount">
                              <Typography textAlign="end">
                                <Tooltip title="MyAccount">
                                  <IconButton>
                                    <ArrowBackIcon />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </Link>
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              size="small"
                              fullWidth
                              required
                              label="Store Name"
                              variant="outlined"
                              value={insti_name}
                              onChange={(e) => setInsti_name(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              size="small"
                              fullWidth
                              required
                              label="Email"
                              variant="outlined"
                              value={insti_email}
                              onChange={(e) => setInsti_email(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              size="small"
                              fullWidth
                              required
                              label="Phone Number"
                              type="number"
                              variant="outlined"
                              value={insti_mobile}
                              onChange={(e) => setInsti_mobile(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <TextField
                              size="small"
                              fullWidth
                              required
                              label="Address"
                              variant="outlined"
                              value={insti_address}
                              onChange={(e) => setInsti_address(e.target.value)}
                            />
                          </Grid>
                          {/* <Grid item xs={12} md={6} lg={6}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            id="country-select-demo"
                            options={countriesss}
                            autoHighlight
                            getOptionLabel={(option) => option.title}
                            onChange={(event, value) => value && set_country(value.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Choose a country"
                                variant="outlined"
                                value={countriesss}
                              />
                            )}
                          />
                        </Grid> */}
                          <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                              size="small"
                              multiple
                              id="combo-box-demo2"
                              options={instituioncities}
                              getOptionLabel={(option) => option.name}
                              value={instituioncities.filter(
                                (instituioncities) =>
                                  instituioncities.id == insti_city
                              )}
                              onChange={(e, value) => {
                                setInsti_city(
                                  value.length > 1
                                    ? value[1].id
                                    : value.length == 1
                                    ? insti_city != 0
                                      ? insti_city
                                      : value[0].id
                                    : 0
                                );
                              }}
                              autoHighlight
                              renderInput={(params) => (
                                <TextField {...params} label="City" />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <TextField
                              size="small"
                              fullWidth
                              label="Zip Code / Postal Code"
                              variant="outlined"
                              value={insti_zipcode}
                              onChange={(e) => setInsti_zipcode(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                            <TextField
                              size="small"
                              fullWidth
                              label="Website"
                              variant="outlined"
                              value={insti_website}
                              onChange={(e) => setInsti_website(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="Status"
                                onChange={StatushandleChange}
                              >
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={2}>In-Active</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Paper style={{ width: "100%" }}>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                              >
                                <input
                                  type="file"
                                  accept="image2/*"
                                  name="image2"
                                  id="file2"
                                  onChange={loadFile}
                                  style={{ display: "none" }}
                                />
                                <Typography variant="body2">
                                  Store Logo{" "}
                                </Typography>

                                <img
                                  src={uploadImage ? uploadImage : inst_image}
                                  className={classes.image2}
                                  id="output"
                                  width="auto"
                                  maxWidth="100%"
                                  height="135px"
                                  alt="test"
                                />
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                padding="5px 20px"
                              >
                                <label
                                  htmlFor="file2"
                                  style={{ cursor: "pointer" }}
                                >
                                  <PhotoCamera />
                                </label>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={6} py={2}>
                            <Paper style={{ width: "100%" }}>
                              <Box>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  flexDirection="column"
                                >
                                  <Typography variant="body2">
                                    Store Banner{" "}
                                  </Typography>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    name="image"
                                    id="file"
                                    onChange={loadFile2}
                                    style={{ display: "none" }}
                                  />
                                  <img
                                    src={
                                      bannerImage ? bannerImage : inst_banner
                                    }
                                    className={classes.image}
                                    id="output2"
                                    width="auto"
                                    maxWidth="100%"
                                    height="136px"
                                    borderRadius={3}
                                  />
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="flex-end"
                                  padding="5px 20px"
                                >
                                  <label
                                    htmlFor="file"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <PhotoCamera />
                                  </label>
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography textAlign="right">
                              <Button>Cancel</Button>
                              <Button
                                onClick={submitForm}
                                endIcon={<ArrowForwardIcon />}
                                size="small"
                                variant="contained"
                              >
                                Submit
                              </Button>
                            </Typography>
                          </Grid>
                        </Grid>
                      </LoadingOverlay>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                          p: 1,
                        }}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" color="GaryText">
                            Store Overview
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography textAlign="end">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={overviewsubmit}
                            >
                              Save
                            </Button>
                          </Typography>
                        </Grid>
                      </Box>
                      <MyAccountManageListingAddCoursesEditCourseOverviewTextEditor
                        overview={overview}
                        setOverview={setOverview}
                      />
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                      <Grid container item spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" color="GaryText">
                            Products
                          </Typography>
                          <Typography variant="body2" color="GrayText">
                            Manage your Products details
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography textAlign="end">
                            {/* <MyAccountEditCoursesDialog insti_id={insti_id} manageList={manageList}/> */}
                            <MyAccountBookStoreEditProductDialog
                              store_id={store_id}
                              manageListing={manageListing}
                            />
                            {/* <Link to="/MyAccountEditCoursesDialog">
                            <Button variant="contained" color="primary">
                              Add New Products
                            </Button></Link> */}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} mb={2}>
                          <Divider />
                        </Grid>
                        
                        {books.length > 0 ? (
                        <Grid item xs={12}>
                          {books &&
                            books.map((value, index) => {
                              return (
                                <Grid container spacing={1} py={1}>
                                  <Grid item xs={2} sm={2} md={2}>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <Avatar src={value.image} alt="logo" />
                                      </Avatar>
                                    </ListItemAvatar>
                                  </Grid>
                                  <Grid item xs={10} sm={8} md={8}>
                                    <ListItemText primary={value.title} />
                                  </Grid>
                                  <Grid item xs={2} sm={1}>
                                    <MyAccountBookStoreEditProductDialogEdit book_id={value.id} store_id={store_id} manageListing={manageListing} />
                                  </Grid>
                                  <Grid item xs={2} sm={1}>
                                    <DeleteConfirmDialog type="store_books" id={value.id} Lists={manageListing}/>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          <Box p={2} display="flex" justifyContent="center">
                            <Stack spacing={2}>
                              <Pagination
                                count={total}
                                page={page}
                                onChange={handleChange}
                              />
                            </Stack>
                          </Box>
                        </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="body2" color="GrayText">
                              No Products Found
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Grid container item spacing={3}>
                        <Orders />
                      </Grid>
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </ThemeProvider>
        </Container>
      </Box>

      <Footer />
    </div>
  );
}
