//import React from 'react'
import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { Card, CardContent, Tab, Tabs, Tooltip } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function EducationDetail_Programs({
  institueId,
  setInstitutionId,
}) {
  const history = useHistory();
  const [courseLists, setCourseList] = React.useState([]);
  const [categoryLists, setCategoryList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    topCoursesList(institueId);
    categoryList(institueId);
  }, [institueId]);

  //method for getting recent jobs
  const topCoursesList = (institueId) => {
    setLoading(true);
    axios(`Course/courselists?institue_id=${institueId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCourseList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const categoryList = (institueId) => {
    setLoading(true);
    axios(`Course/categoryList?institue_id=${institueId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCategoryList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const CourseView = (id) => {
    setInstitutionId(id);
    history.push({
      pathname: `/EducationCourseDetail/${id}`,
    });
  };

  const ViewAllCourse = (id) => {
    setInstitutionId(id);
    history.push({
      pathname: `/EducationDetail_Programs_ViewAll/${id}`,
    });
  };

  const [value, setValue] = React.useState(0);

  const handleChangePrograms = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ pt: "48px", pb: "48px" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography variant="h5" gutterBottom component="div">
                Programs
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box sx={{ p: 1, flexGrow: 1 }}>
              <Typography
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
                onClick={() => ViewAllCourse(institueId)}
              >
                View All
              </Typography>
            </Box>
            <Box className="TopUniversities_bg">
              <Box
                sx={{
                  maxWidth: { xs: 320, sm: 380, md: 580 },
                  bgcolor: "background.paper",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChangePrograms}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {categoryLists.map((value, index) => {
                    return <Tab label={value.title} />;
                  })}
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box className="TopUniversities_carousel_bg">
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {courseLists && courseLists.length > 0 ? (
                <Box width="100%">
                  <Carousel
                    responsive={responsive}
                    rightChevron={<KeyboardArrowRight />}
                    leftChevron={<KeyboardArrowLeft />}
                  >
                    {courseLists.map((value, index) => {
                      return (
                        <Box
                          p={1}
                          className="TopUniversities_box"
                          onClick={() => CourseView(value.id)}
                        >
                          <Card className={classes.TopUniversitiesCard}>
                            <CardMedia
                              component="img"
                              width="fit-content"
                              height="300"
                              image={value.image}
                              alt={value.title}
                            />
                            <Box className="cp">
                              <CardContent>
                                <Box p={1}>
                                  <Tooltip title={value.title}>
                                    <Typography
                                      className="text_bg"
                                      gutterBottom
                                      variant="subtitle2"
                                      component="div"
                                    >
                                      {value.title}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </CardContent>
                            </Box>
                          </Card>
                        </Box>
                      );
                    })}
                  </Carousel>
                </Box>
              ) : (
                <Box className="EducationProgramsContainer">
                  <Box p={1} className="EducationDetailPrograms_bg cp">
                    <Box p={1}>
                      <Typography
                        sx={{ mt: 1.5 }}
                        gutterBottom
                        variant="subtitle2"
                        component="div"
                      >
                        No Programs Found
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </LoadingOverlay>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
