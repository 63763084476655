import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { Grid, Button } from "@mui/material";

export default function AccountMenu({ fullaname, logo }) {
  const history = useHistory();
  const { open5, setOpen5, login, setLogin } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.removeItem("data");
    setLogin(false);
    history.push({
      pathname: "/",
      state: { data: true },
    });
  };

  return (
    <React.Fragment>
      <Box className="account_menu_box">
        <Box
          className="Account_menu_bg"
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Tooltip title="Account settings">
            <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
              <Box className="header_two_menu_box">
                <Button
                  className="header_two_menu_btn"
                  variant="standard"
                  color="#fff"
                >
                  {fullaname}
                </Button>
              </Box>
              <Avatar
                sx={{ width: 32, height: 32, bgcolor: "white" }}
                src={logo}
              ></Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Grid
          container
          display="flex"
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <Box>
              <Menu
                anchorEl={anchorEl}
                className="Account_menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    flexDirection: "column",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem mt={2}>
                  <Avatar
                    sx={{ width: 32, height: 32, bgcolor: "white" }}
                    src={logo}
                  />{" "}
                  <Link to="/MyAccount">My Account </Link>
                </MenuItem>
                <Divider />
                <Link to="/Settings">
                  <MenuItem>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                  </MenuItem>
                </Link>
                <div>
                  <MenuItem onClick={logOut}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </div>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
