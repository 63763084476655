import React from "react";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "@mui/material";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({ 
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",                 
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function TopUniversities() {
  const classes = useStyles();

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    topCoursesList(newValue);
  };

  const history = useHistory();
  const [topUniversities, setUniversityList] = React.useState([]);
  const [typelist, setTypeList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    topCoursesList(value);
    typeList();
  }, []);

  //method for getting recent jobs
  const topCoursesList = (value) => {
    setLoading(true);
    axios(`Home/topUniversities?instituion=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setUniversityList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const InstituteView = (id, type) => {
    if (type === 3) {
      history.push({
        pathname: `/EducationExpertDetail/${id}`,
      });
    } else {
      history.push({
        pathname: `/EducationDetail/${id}`,
      });
    }
  };

  const viewalleducation = (id, type) => {
    history.push({
      pathname: `/EducationLisiting`,
      search: `?location=${id}&type=${type}`,
    });
  };

  //method for getting recent jobs
  const typeList = () => {
    setLoading(true);
    axios(`Home/typeList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTypeList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ bgcolor: "#fff", pt: "48px", pb: "48px" }}>
        <Container maxWidth="xl"> 
        <Grid container spacing={3} pb={3} p={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{ display: "flex", alignItems: "center", gap: 3 }}
            >
               <Typography noWrap
                variant="h5"
                gutterBottom
                component="div"
                color="#572672"
              >
                Top Institutions 
              </Typography>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
               <Typography noWrap
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
                onClick={() => viewalleducation(0, 1)}
              >
                View All
              </Typography>

              </Grid>  
              <Grid item xs={12} sm={6} md={8} lg={9} className="TopUniversities_bg" sx={{ display: "flex", justifyContent: "flex-end" }} >
              <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  aria-label="secondary tabs example"
                >
                  {typelist.map((value, index) => {
                    return <Tab value={value.id} label={value.title} />;
                  })}
                </Tabs>
</Grid>
              </Grid>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="#572672"
              >
                Top Institutions 
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box className="cp_bg" sx={{ p: 1, flexGrow: 1 }}>
              <Typography
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
                onClick={() => viewalleducation(0, 1)}
              >
                View All
              </Typography>
            </Box>
            <Box className="TopUniversities_bg">
              <Box
                sx={{
                  maxWidth: { xs: 370, sm: 480, md: 570, xl:800 },
                }}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  aria-label="secondary tabs example"
                >
                  {typelist.map((value, index) => {
                    return <Tab value={value.id} label={value.title} />;
                  })}
                </Tabs>
              </Box>
            </Box>
          </Box> */}
          <Box className="TopUniversities_carousel_bg">
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {topUniversities.length > 0 ? (
                <Box width="100%">
                  <Carousel
                    responsive={responsive}
                    rightChevron={<KeyboardArrowRight />}
                    leftChevron={<KeyboardArrowLeft />}
                  >
                    {topUniversities.map((value, index) => {
                      return (
                        <Box
                          className="TopUniversities_box"
                          p={2}
                          onClick={() => InstituteView(value.id, value.type)}
                        >
                          <Card
                            className={classes.TopUniversitiesCard}
                          >
                            <CardMedia
                              component="img"
                              height="300"
                              image={value.logo}
                              alt={value.title}
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/education">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={value.small_logo}
                                      alt=""
                                      to="/EducationStoresDetails"
                                    />
                                  </Link>
                                </Box>
                                <Tooltip Tooltip title={value.title}>
                                  <Typography
                                    Tooltip
                                    title={value.title}
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    gutterBottom
                                    textAlign="start"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    {value.title}
                                  </Typography>
                                </Tooltip>
                         
                              </CardContent>
                            </Box>
                          </Card>
                        </Box>
                      );
                    })}
                  </Carousel>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "650px",
                  }}
                >
                  <Box p={5} paddingBottom="105px" className="error">
                    <Typography variant="h6" color="primary">
                      <span className="error_text">No data</span> found.
                    </Typography>
                  </Box>
                </Box>
              )}
            </LoadingOverlay>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
