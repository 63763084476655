import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { ButtonBase } from "@mui/material";
import amazon from "../images/skeleton/logo_skeleton.webp";
import { Link } from "react-router-dom";

const affiliation = ["aamzon.ae/bookname", "noon.ae/bookname"];

function SimpleDialog(props) {
  const { onClose, selectedValue, open,links } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ color:"#572672"}}>Buy online </DialogTitle>
      <List sx={{ pt: 0 }}>
          <Link href={links}>
            <ListItem
              onClick={() => handleListItemClick(links)}
              key={links}
            >
              <ListItemAvatar>
                <Avatar src={amazon}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={links} />
            </ListItem>
          </Link>
      </List>
    </Dialog>
  );
}

export default function AffiliationLinkDialog({links}) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(affiliation[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <ButtonBase>
        <Button
          onClick={handleClickOpen}
          className="primaryButton"
          variant="contained"
          color="primary"
        >
          Buy Online
        </Button>
      </ButtonBase>

      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        links={links}
      />
    </div>
  );
}
