import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import HeaderFour from "../HeaderFour/HeaderFour";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import LogoSkeleton from "../images/skeleton/logo_skeleton.webp";
import Footer from "../Footer/Footer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const columns = [
  { field: "id", headerName: "id", width: 110 },
  { field: "CourseName", headerName: "Course Name", width: 190 },
  { field: "Category", headerName: "Category", width: 170 },
  {
    field: "Level",
    headerName: "Level",
    type: "number",
    width: 130,
  },

  {
    field: "Type",
    headerName: "Type",
    type: "number",
    width: 130,
  },
  {
    field: "CourseFee",
    headerName: "Course Fee",
    width: 170,
  },
  {
    field: "Duration",
    headerName: "Duration",
    width: 170,
  },
  {
    field: "DurationType",
    headerName: "Duration Type",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, "Level") || ""} ${
        params.getValue(params.id, "Duration") || ""
      }`,
  },
];

const rows = [
  {
    id: 1,
    Category: "Tutor 1",
    CourseName: "Name of Institution",
    Level: "Postgraduate",
    Type: "Fulltime",
    CourseFee: "11000 AED",
    Duration: "1 Year",
    DurationType: "hs",
  },
  {
    id: 2,
    Category: "Tutor 2",
    CourseName: "Name of Institution 2",
    Level: "MBA",
    Type: "Partime",
    CourseFee: "12000 AED",
    Duration: "2 Year",
    DurationType: "Courses",
  },
  {
    id: 3,
    Category: "Tutor 3",
    CourseName: "Name of Institution 3",
    Level: "MCA",
    CourseFee: "18000 AED",
    Duration: "6 Months",
    DurationType: "School",
  },
  {
    id: 4,
    Category: "Tutor 4",
    CourseName: "Name of Institution 4",
    Level: "BCA",
    CourseFee: "13000 AED",
    Duration: "3 Year",
    DurationType: "collage",
  },
  {
    id: 5,
    Category: "Tutor 5",
    CourseName: "Name of Institution 5",
    Level: null,
    CourseFee: "14000 AED",
    Duration: "4 Year",
    DurationType: "hs",
  },
  { id: 6, Category: "Tutor 6", CourseName: null, Level: "MBBS" },
  {
    id: 7,
    Category: "Tutor 7",
    CourseName: "Name of Institution 7",
    Level: "MBBS",
  },
  {
    id: 8,
    Category: "Tutor 8",
    CourseName: "Name of Institution 8",
    Level: "MD",
  },
  {
    id: 9,
    Category: "Tutor 9",
    CourseName: "Name of Institution 9",
    Level: "BMS",
  },
];

export default function MyAccount_ManageListingCourseListEdit() {
  return (
    <div>
      <HeaderFour />
      <Box
        className="caro_bg"
        sx={{ bgcolor: "#F7F7F7", pt: "80px", pb: "25px" }}  
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
            }}
          >
            <Box sx={{ p: 1 }}>
              <Box
                component={"img"}
                p={1}
                width={170}
                src={LogoSkeleton}
                alt=""
                to="/home"
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="#3E92D2"
                sx={{ height: 100, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box
              className="education_detail_admission_box cp_bg"
              sx={{ p: 1, flexGrow: 1 }}
            >
              <Typography
                className="cp education_detail_text_head"
                variant="body2"
                //  color="InactiveCaptionText"
                gutterBottom
                component="div"
                //   onClick={() => viewalleducation(0,1)}
              >
                Johan Martiz
              </Typography>
              <Typography
                className="cp"
                variant="body2"
                //    color="InactiveCaptionText"
                gutterBottom
                component="div"
                //   onClick={() => viewalleducation(0,1)}
              >
                <span className="education_detail_text_head"> Phone:</span>{" "}
                0563019849
              </Typography>
              <Typography
                className=" cp"
                variant="body2"
                //   color="InactiveCaptionText"
                gutterBottom
                component="div"
                //   onClick={() => viewalleducation(0,1)}
              >
                <span className="education_detail_text_head">Email:</span>{" "}
                johan@gmail.com
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Stack direction="row" spacing={2}>
                <Link to="/MyAccount">
                  <Button
                    variant="contained"
                    color="info"
                    startIcon={<ArrowBackIcon />}
                  >
                    Back
                  </Button>
                </Link>
                <Button
                  variant="outlined"
                  color="inherit"
                  startIcon={<LogoutIcon />}
                >
                  Logout
                </Button>
              </Stack>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container>
        <Box py="30px">
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={7}
              rowsPerPageOptions={[7]}
              checkboxSelection
              EditIcon
            />
          </div>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}
