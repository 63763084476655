import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { Link } from "react-router-dom";

export default function EducationMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: "purple", m: 1, alignSelf: "center"}}
      >
      <MenuIcon />  
      {/* Education  */}
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <div> 
        <Box sx={{ flexGrow: 1, maxWidth: 800, }}>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Tutors</MenuItem> 
        <Divider />
        <Link to="/EducationLisiting?location=0&institute_type=3">
        <MenuItem onClick={handleClose}>Teachers</MenuItem></Link>
        <Link to="/EducationLisiting?location=0&institute_type=3"> 
        <MenuItem onClick={handleClose}>Undergraduate</MenuItem></Link>
        <Link to="/EducationLisiting?location=0&institute_type=3"> 
        <MenuItem onClick={handleClose}>Post-graduate</MenuItem></Link>
        <Link to="/EducationLisiting?location=0&institute_type=3"> 
        <MenuItem onClick={handleClose}>Career experts</MenuItem></Link>
        </Grid>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Charity</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>CSR</MenuItem>
        <MenuItem onClick={handleClose}>Companies</MenuItem>
        </Grid>
        <Grid item xs={6} lg={4}>
        
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Institutions</MenuItem> 
        <Divider />
        <Link to="/EducationLisiting?location=0&type=1"> 
        <MenuItem onClick={handleClose}>Universities</MenuItem></Link>
        <Link to="/EducationLisiting?location=0&type=1"> 
        <MenuItem onClick={handleClose}>Colleges</MenuItem></Link>
        </Grid>
        <Grid item xs={6} lg={4}>
        
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Books</MenuItem>
        <Divider />
        <Link to="/EducationAllbooks"> 
        <MenuItem onClick={handleClose}>E-Commerce</MenuItem></Link>
        <Link to="/EducationStores"> 
        <MenuItem onClick={handleClose}>bookshope</MenuItem></Link>
        </Grid>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Business</MenuItem>
        <Divider />
        <Link to="/EducationLisitingMap?location=0"> 
        <MenuItem onClick={handleClose}>Educational Tourism</MenuItem></Link>
        <Link to="/Registration"> 
        <MenuItem onClick={handleClose}>Join our</MenuItem></Link>
        </Grid>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Special Services</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>People of determination</MenuItem>
        <MenuItem onClick={handleClose}>Distinguished packages</MenuItem>
        </Grid>
      </Grid>
    </Box>
        </div>
       
       
      </Menu>
    </div>
  );
}
