import { Typography } from "@mui/material";
import React, { Component } from "react";
import Chart from "react-apexcharts";

class RadialBartwo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        plotOptions: {
          radialBar: {
            // startAngle: -180,
            // endAngle: 180,

            track: {
              background: "#f2f2f2",
              strokeWidth: "97%"
            },
            dataLabels: {
              show: true,
              value: {
                show: true,
                fontSize: "16px"
              },
              total: {
                show: true,
                label: "Student",
                color: "#373d3f"
              }
            }
          }
        }
      },

      series: [69]
    };
  }

  render() {
    return (
      <div className="donut">
        <Typography sx={{ fontSize: 16, textAlign:'center' }}  gutterBottom>
        Student attendance rate
        </Typography>
        <Chart
          options={this.state.options}
          series={this.state.series} 
          type="radialBar"
          width="100%"
        />
      </div>
    );
  }
}

export default RadialBartwo;
