import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Typography,
  Rating,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import image from "../images/user.webp";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";

const useStyle = makeStyles({
  image: {
    borderRadius: 100,
  },
});
export default function MyAccountContributionsDialog({storeBooksList}) {
  const alert = useAlert();
  const [uploadImage, setUploadImage] = useState("");
  const [name, set_name] = React.useState("");
  const [author, set_author] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [links, setLink] = React.useState("");
  const [rating, setRating] = React.useState();
  const [contributes, setContributes] = React.useState([]);
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };
  const [proimage, setImage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addContribute = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("author", author);
    formdata.append("links", links);
    formdata.append("description", desc);
    formdata.append("logo", proimage);
    formdata.append("rating", rating);

    axios(`Profile/add_contribute`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          setContributes(res.data.data);
          alert.success(res.data.message);
          setOpen(false);
          storeBooksList();
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="MyAccount_bg">
      <Tooltip title="Add Contributions">
        <Button
          variant="outlined"
          startIcon={<AddCircleIcon />}
          color="primary"
          noWrap
          onClick={handleClickOpen}
        >
          Add Book Review
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Book Review </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography guttterBottom>
              Add Book Review to your account here. You can add multiple
              contributions.
            </Typography>
          </DialogContentText>

          <Grid container item spacing={3}>
            <Grid item xs={12} md={4}>
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  mt={3}
                >
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="body2">Banner, logo </Typography>

                  <img
                    src={uploadImage ? uploadImage : image}
                    className={classes.image}
                    id="output"
                    width="100%"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCamera />
                  </label>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                autoFocus
                id="outlined-textarea"
                label="Books Name"
                placeholder="Books Name"
                variant="standard"
                fullWidth
                multiline
                value={name}
                onChange={(e) => set_name(e.target.value)}
              />
              <TextField
                id="outlined-textarea"
                label="Auther Name"
                placeholder="Auther Name"
                variant="standard"
                fullWidth
                multiline
                value={author}
                onChange={(e) => set_author(e.target.value)}
              />
              <TextField
                fullWidth
                multiline
                maxRows={4}
                margin="dense"
                id="name"
                label="Affiliate links"
                variant="standard"
                value={links}
                onChange={(e) => setLink(e.target.value)}
              />
                <Stack py={2}>
              <Rating name="half-rating" 
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
               precision={0.5} />
    </Stack>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Description"
                variant="standard"
                multiline
                margin="dense"
                rows={4}
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="MyAccountProfileBg">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addContribute} className="primaryButton">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
