import React , { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo_skeleton from "../images/skeleton/logo_skeleton.webp";
import Stack from "@mui/material/Stack";
import { CardMedia } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AvatarGroup from "@mui/material/AvatarGroup";
import TextField from "@mui/material/TextField";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import HeaderFour from "../HeaderFour/HeaderFour";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import { Link, useHistory } from "react-router-dom";
import axios from "../Utils/Axios"; //import axios config
import Autocomplete from "@material-ui/lab/Autocomplete";
import JoditEditor from "jodit-react";
import { useAlert } from "react-alert";
import LogoutIcon from "@mui/icons-material/Logout";

import LogoSkeleton from "../images/skeleton/logo_skeleton.webp";
import Footer from "../Footer/Footer";

const Input = styled("input")({
  display: "none",
});


function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        //   bgcolor: 'primary.main',
        color: "white",
        //   p: 1,
        m: 0.5,
        borderRadius: 1,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};


export default function MyAccount_ManageListingEdit(props) {
  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [instituteName, setInstit_name] = React.useState("");
  const [instituteMobile, setInstit_mobile] = React.useState("");
  const [instituteStudents, setInstit_students] = React.useState("");
  const [instituiontypes, setInstitutionTypes] = React.useState([]);
  const [instituiontype, setInstitutionType] = React.useState("");
  const [instituteEmail, setInstit_email] = React.useState("");
  const [instituteAddress, setInstit_address] = React.useState("");
  const [instituioncities, setInstitutionCities] = React.useState([]);
  const [instituioncity, setInstitutionCity] = React.useState("");
  const [instituionzipcode, setInstit_zipcode] = React.useState("");
  const [instituionwebsite, setInstit_website] = React.useState("");
  const [instituiongender, setInstit_gender] = React.useState("");
  const [instituionlocation, setInstit_location] = React.useState("");
  const [instituionyear, setInstit_year] = React.useState("");
  const [instituionlatitude, setInstit_latitude] = React.useState("");
  const [instituionlongitude, setInstit_longitude] = React.useState("");
  const [uploadImage, setUploadImage] = React.useState();
  const [bannerImage, setUploadBanner] = React.useState();
  const [image, setImage] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [gallery, setGallery] = React.useState([]);
  const [profile, setProfile] = React.useState([]);
  
  const [insti_id] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );

  const [content, setContent] = useState("");
  const editor = useRef(null);
  const config = {
    readonly: false,
    height: 300,
  };
  const handleUpdate = (event) => {
    const editorContent = event;
    setContent(editorContent);
  };


  useEffect(() => {
    editPost();
    getInstitution();
    getCities();
  }, []);

  React.useEffect(() => {
    getProfile();
  }, [JSON.parse(localStorage.getItem("data"))]);


  const editPost = () => {
    setLoading(true);
    axios(`Institution/get_institute_profile?institutionId=${insti_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error === true) {
        } else {
          setLoading(false);
          setInstit_name(res.data.data.name);
          setInstitutionType(res.data.data.type);
          setInstit_mobile(res.data.data.tel_no);
          setInstit_students(res.data.data.no_of_students);
          setInstit_email(res.data.data.email);
          setInstit_address(res.data.data.address_1);
          setInstitutionCity(res.data.data.city_id);
          setInstit_zipcode(res.data.data.zipcode);
          setInstit_website(res.data.data.website);
          setInstit_gender(res.data.data.gender);
          setInstit_location(res.data.data.map_link);
          setInstit_year(res.data.data.no_of_students);
          setInstit_latitude(res.data.data.latitude);
          setInstit_longitude(res.data.data.longitude);
          setImage(res.data.data.logo);
          setBanner(res.data.data.banner);
          setGallery(res.data.data.gallery);
          setContent(res.data.data.description);
        }
      })
      .catch((err) => console.log(err));
  };

  const getInstitution = () => {
    axios(`Institution/getInstitutionType`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setInstitutionTypes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const getCities = () => {
    axios(`Institution/getCities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setInstitutionCities(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("institutionId", insti_id);
    formdata.append("instituteName", instituteName);
    formdata.append("instituiontype", instituiontype);
    formdata.append("instituteEmail", instituteEmail);
    formdata.append("instituteMobile", instituteMobile);
    formdata.append("instituteAddress", instituteAddress);
    formdata.append("instituteStudents", instituteStudents);
    formdata.append("instituioncity", instituioncity);
    formdata.append("instituionzipcode", instituionzipcode);
    formdata.append("instituiondesc", content);
    formdata.append("instituionlocation", instituionlocation);
    formdata.append("instituionwebsite", instituionwebsite);
    formdata.append("instituiongender", instituiongender);
    formdata.append("instituionlatitude", instituionlatitude);
    formdata.append("instituionlongitude", instituionlongitude);
    formdata.append("uploadImage", uploadImage);
    formdata.append("bannerImage", bannerImage);
    axios(`Institution/update_institution`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };


  let inst_image;
  if (image !== "") {
    inst_image = image;
  } else {
    inst_image = logo_skeleton;
  }

  let inst_banner;
  if (banner !== "") {
    inst_banner = banner;
  } else {
    inst_banner = logo_skeleton;
  }

  const getProfile = () => {
    axios(`Home/get_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <HeaderFour />
      <Box
        className="caro_bg"
        sx={{ bgcolor: "#F7F7F7", pt: "80px", pb: "25px" }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
            }}
          >
            <Box sx={{ p: 1 }}>
              <Box
                component={"img"}
                p={1}
                width={170}
                src={LogoSkeleton}
                alt=""
                to="/home"
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="#3E92D2"
                sx={{ height: 100, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box
              className="education_detail_admission_box cp_bg"
              sx={{ p: 1, flexGrow: 1 }}
            >
              <Typography
                className="cp education_detail_text_head"
                variant="body2"
                //  color="InactiveCaptionText"
                gutterBottom
                component="div"
                //   onClick={() => viewalleducation(0,1)}
              >
                {profile && profile.fullname}
              </Typography>
              <Typography
                className="cp"
                variant="body2"
                //    color="InactiveCaptionText"
                gutterBottom
                component="div"
                //   onClick={() => viewalleducation(0,1)}
              >
                <span className="education_detail_text_head"> Phone:</span>{" "}
                {profile && profile.mobile}
              </Typography>
              <Typography
                className=" cp"
                variant="body2"
                //   color="InactiveCaptionText"
                gutterBottom
                component="div"
                //   onClick={() => viewalleducation(0,1)}
              >
                <span className="education_detail_text_head">Email:</span>{" "}
                {profile && profile.email}
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Stack direction="row" spacing={2}>
                <Link to="/MyAccount">
                  <Button
                    variant="contained"
                    color="info"
                    startIcon={<ArrowBackIcon />}
                  >
                    Back
                  </Button>
                </Link>
                <Button
                  variant="outlined"
                  color="inherit"
                  startIcon={<LogoutIcon />}
                >
                  Logout
                </Button>
              </Stack>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box py="30px">
          <Box display="flex" alignItems="center" p={1}>
            <Typography variant="subtitle2"> Institute Information </Typography>
            <Tooltip title=" Institute Information">
              <HelpOutlineIcon sx={{ pl: 1, color: "#2D5DAC" }} size="small" />
            </Tooltip>
          </Box>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "47%" },
            }}
            sm={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
            md={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                required
                label="Name of Institution"
                value={instituteName}
                onChange={(e) => setInstit_name(e.target.value)}
              />
              <Autocomplete
                    multiple
                    id="combo-box-demo"
                    options={instituiontypes}
                    getOptionLabel={(option) => option.name}
                    value={instituiontypes.filter(
                      (instituiontypes) => instituiontypes.id == instituiontype
                    )}
                    onChange={(e, value) => {
                      setInstitutionType(
                        value.length > 1
                          ? value[1].id
                          : value.length == 1
                          ? instituiontype != 0
                            ? instituiontype
                            : value[0].id
                          : 0
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type of Institution"
                      />
                    )}
                  />
              <TextField
                required
                label="Institution Email"
                value={instituteEmail}
                onChange={(e) => setInstit_email(e.target.value)}
              />
              <TextField
                required
                label="Contact Number"
                value={instituteMobile}
                onChange={(e) => setInstit_mobile(e.target.value)}
              />
              <TextField required label="Address" 
              value={instituteAddress}
              onChange={(e) => setInstit_address(e.target.value)}
              />
              <TextField
                required
                label="No of Students"
                value={instituteStudents}
                onChange={(e) => setInstit_students(e.target.value)}
              />
              <Autocomplete
                  multiple
                  id="combo-box-demo2"
                  options={instituioncities}
                  getOptionLabel={(option) => option.name}
                  value={instituioncities.filter(
                    (instituioncities) => instituioncities.id == instituioncity
                  )}
                  onChange={(e, value) => {
                    setInstitutionCity(
                      value.length > 1
                        ? value[1].id
                        : value.length == 1
                        ? instituioncity != 0
                          ? instituioncity
                          : value[0].id
                        : 0
                    );
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                    />
                  )}
                />
              <TextField
                label="Zip Code / Postal Code"
                type="Zip Code / Postal Code"
                InputLabelProps={{
                  shrink: true,
                }}
                value={instituionzipcode}
                onChange={(e) => setInstit_zipcode(e.target.value)}
              />
              <TextField required label="Website" 
              value={instituionwebsite}
              onChange={(e) => setInstit_website(e.target.value)}
               />
              <TextField required label="Gender"
              value={instituiongender}
              onChange={(e) => setInstit_gender(e.target.value)}
               />
              <TextField required label="Location" 
              value={instituionlocation}
              onChange={(e) => setInstit_location(e.target.value)}
               />
              <TextField
                required
                label="Founded Year"
                value={instituteStudents}
                onChange={(e) => setInstit_students(e.target.value)}
              />
              <TextField required label="Latitude"
              value={instituionlatitude}
              onChange={(e) => setInstit_latitude(e.target.value)}
               />
              <TextField required label="Longitude"
              value={instituionlongitude}
              onChange={(e) => setInstit_longitude(e.target.value)}
               />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box display="flex" flexGrow={1}>
                  <label htmlFor="contained-button-file1">
                    <Input
                      accept="image/*"
                      id="contained-button-file1"
                      multiple
                      type="file"
                      onChange={(e) => setUploadImage(e.target.files[0])}
                    />
                    <Button variant="contained" component="span">
                      Institute Logo
                    </Button>
                  </label>
                  <label htmlFor="icon-button-file">
                    <CardMedia
                      image={inst_image}
                      border="solid 1px #0f0d0d"
                      sx={{
                        width: 70,
                        height: 70,
                        m: 1,
                        mt: 0,
                        mb: 3,
                        border: "solid 1px #d8d5d5",
                        borderRadius: 1,
                      }}
                    ></CardMedia>
                  </label>
                </Box>
                <Box display="flex" flexGrow={1}>
                  <label htmlFor="contained-button-file2">
                    <Input
                      accept="image/*"
                      id="contained-button-file2"
                      multiple
                      type="file"
                      onChange={(e) => setUploadBanner(e.target.files[0])}
                    />
                    <Button variant="contained" component="span">
                      Institute Banner
                    </Button>
                  </label>
                  <label htmlFor="icon-button-file">
                    <CardMedia
                      image={inst_banner}
                      border="solid 1px #0f0d0d"
                      sx={{
                        width: 150,
                        height: 70,
                        m: 1,
                        mt: 0,
                        mb: 3,
                        border: "solid 1px #d8d5d5",
                        borderRadius: 1,
                      }}
                    ></CardMedia>
                  </label>
                </Box>
              </Stack>
              <Box display="flex" alignItems="center" p={1} pt={2}>
                <Typography variant="subtitle2">Institute Overview</Typography>
                <Tooltip title=" Institute Information">
                  <HelpOutlineIcon
                    sx={{ pl: 1, color: "#2D5DAC" }}
                    size="small"
                  />
                </Tooltip>
              </Box>
              <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  onBlur={handleUpdate}
                  onChange={(newContent) => {}}
                />          
              {/* <TextEditor /> */}

              <Box display="flex" alignItems="center" p={1} pt={2}>
                <Typography variant="subtitle2">Photos & Videos</Typography>
                <Tooltip title="Photos & Videos">
                  <HelpOutlineIcon
                    sx={{ pl: 1, color: "#2D5DAC" }}
                    size="small"
                  />
                </Tooltip>
              </Box>
              <Box
                className="MLphotVideo_bg"
                display="flex"
                alignItems="center"
                p={1}
                pt={2}
              >
                <AvatarGroup max={5}>
                  {gallery.map((photo, index) => (
                  <CardMedia
                    image={photo.images}
                    border="solid 1px #0f0d0d"
                    sx={{
                      width: 80,
                      height: 60,
                      m: 1,
                      mb: 3,
                      border: "solid 1px #d8d5d5",
                      borderRadius: 1,
                    }}
                  ></CardMedia>
                  ))}
                </AvatarGroup>
                <Box pb={2}>
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                    />
                    <Button
                      className="manageListingPlusButton"
                      variant="contained"
                      component="span"
                    >
                      <AddIcon />
                    </Button>
                  </label>
                </Box>
              </Box>
            </div>
          </Box>

          <Box
            className="AddCourses_bg"
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIcon />}
              onClick={submitForm}
            >
              Add Courses
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}
