import React, { useEffect } from "react";
import HeaderFour from "../HeaderFour/HeaderFour";
import { Box, Paper, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MyAccountPersonalinfo from "./MyAccount_Personalinfo";
import MyAccountManageListing from "./MyAccount_ManageListing";
import PropTypes from "prop-types";
import axios from "../Utils/Axios"; //import axios config
import { useHistory } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./MyAccount.css";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { Grid, createTheme, ThemeProvider } from "@mui/material";
import MyOrders from "../Cart/MyOrders";
import MyAccountContributionsTabs from "./MyAccountContributionsTabs";
import MyAccountCommunityList from "../Education/Communities/MyAccountCommunityList";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const actions = [
  { icon: <FileCopyIcon />, name: "Add Courses" },
  { icon: <SaveIcon />, name: "Course List" },
  { icon: <PrintIcon />, name: "Register New Listing" },
  { icon: <ShareIcon />, name: "Share" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function MyAccount(props) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [profile, setProfile] = React.useState({});
  const [storebooks, setstoreBooks] = React.useState([]);

  const [hasg, setHash] = React.useState(props.location.hash);

  React.useEffect(() => {
    getProfile();
    storeBooksList();
  }, []);

  const getProfile = () => {
    setLoading(true);
    axios(`Profile/edit_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          setLoading(false);
        } else {
          setLoading(false);
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const storeBooksList = () => {
    setLoading(true);
    axios(`Home/get_contributes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setstoreBooks(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (hasg == "#contribute") {
    var s = 2;
  } else {
    var s = 0;
  }
  const [value, setValue] = React.useState(s);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    palette: {
      primary: {
        light: "#833baa",
        main: "#572672",
        dark: "#381849",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

  return (
    <div>
      <HeaderFour />
      <Box sx={{ bgcolor: "#FBFBFB", pt: "120px", pb: "48px" }}>
        <Container>
          <ThemeProvider theme={theme}>
            <Grid container item spacing={0}>
              <Grid item xs={12} md={3} lg={3} mt={3}>
                <Card>
                  <CardContent>
                    <Tabs
                      className="MyAccountEditTabs"
                      orientation="vertical"
                      fullWidth
                      variant="scrollable"
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs example"
                    >
                      <Tab
                        className="tabac"
                        label=" Personal info"
                        {...a11yProps(0)}
                      />
                      <Tab className="tabac" label="Pages" {...a11yProps(1)} />
                      <Tab
                        className="tabac"
                        label="Contributions"
                        {...a11yProps(2)}
                      />
                      <Tab
                        className="tabac"
                        label="Communities"
                        {...a11yProps(3)}
                      />
                      <Tab
                        className="tabac"
                        label=" Orders"
                        {...a11yProps(4)}
                      />
                    </Tabs>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <TabPanel value={value} index={0}>
                  <MyAccountPersonalinfo />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={12}>
                      <MyAccountManageListing />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <MyAccountContributionsTabs />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <MyAccountCommunityList />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={12}>
                      <MyOrders />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </Box>
    </div>
  );
}
