import {
  Avatar,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  createTheme,
  ThemeProvider,
  CardContent,
  Box,
} from "@mui/material";
import React from "react";
import axios from "../Utils/Axios"; //import axios config
import { useHistory } from "react-router-dom";
import MyAccountContributionsArticlesDialog from "./MyAccountContributionsArticlesDialog";
import parse from "html-react-parser";
import LoadingOverlay from "react-loading-overlay";
import MyAccountContributionsArticlesDialogEdit from "./MyAccountContributionsArticlesDialogEdit";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import { ConfirmProvider } from "material-ui-confirm";


export default function MyAccountContributionsTabsArticles() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [profile, setProfile] = React.useState({});
  const [storebooks, setstoreBooks] = React.useState([]);
  const [articles, setarticles] = React.useState([]);
  
    React.useEffect(() => {
      articlesList();
    }, []);
  
    const articlesList = () => {
      setLoading(true);
      axios(`Article/user_articles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            setLoading(false);
            console.log(res.data.message);
          } else {
            setLoading(false);
            setarticles(res.data.data); //to set response data to state
          }
        })
        .catch((err) => console.log(err));
    };
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const theme = createTheme({
      palette: {
        primary: {
          light: "#833baa",
          main: "#572672",
          dark: "#381849",
          contrastText: "#fff",
        },
        secondary: {
          light: "#ff7961",
          main: "#f44336",
          dark: "#ba000d",
          contrastText: "#000",
        },
      },
    });

  return (
    <div>
        <ThemeProvider theme={theme}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
        <Grid container spacing={3}>
        {/* <Grid item xs></Grid>
        <Grid item xs="auto">
          <MyAccountContributionsArticlesDialog articlesList={articlesList}/>
        </Grid> */}
         <Grid item xs={12} pl={0}>
        <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <MyAccountContributionsArticlesDialog articlesList={articlesList} />
            </Grid>
          </Grid>
          {articles.length > 0 ? (
          <div>
          {articles.map((value, index) => {
            return (
              <div>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt={value.title} src={value.images} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={value.title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="gray"
                          >
                             {value.created}
                          </Typography>
                           <Typography component="span" color="gray">
                          {parse(value.short_description)}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                     <Box sx={{ alignSelf: "center"}}>  
                     <ConfirmProvider>
                       <DeleteConfirmDialog type="articles" id={value.id} Lists={articlesList}/> 
                    </ConfirmProvider>
                    </Box>
                     <Box sx={{ alignSelf: "center"}}>  
                        <MyAccountContributionsArticlesDialogEdit article_id={value.id} articlesList={articlesList}/>
                            </Box>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              </div>
            );
          })}
          </div>
          ) : (
            <div>
              <Grid item xs={12} sx={{ ml: 2, mt: 2 , mb: 2}}>
                <Typography>
                  No Articles Found
                </Typography>
              </Grid>
            </div>
          )}
          </CardContent>
        </Card>
      </Grid>
      </Grid>
      </LoadingOverlay>
        </ThemeProvider>
    </div>
  );
}
