import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Grid, IconButton } from "@mui/material";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import Avatar from "@mui/material/Avatar";

export default function Orders() {
  const [loading, setLoading] = React.useState(true);
  const [orderdata, setOrderData] = React.useState([]);

  React.useEffect(
    () => {
      window.scrollTo(0, 0);
      getstoreorders();
    }, 
    []
  );

  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpandedPanel(isExpanded ? panel : false);
  };

  const getstoreorders = () => {
    setLoading(true);
    axios(`Orders/getstoreorders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setOrderData([]);
          } else {
            setLoading(false);
            setOrderData(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div sx={{ display: "flex" }}>
      <IconButton>
        <ShoppingBagIcon />
      </IconButton>
      <Typography display="inline-block" gutterBottom variant="h6" fontWeight={800}>
                Orders
      </Typography>
      <CardContent sx={{ py: 1 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" gutterBottom color="GrayText">
              Orders #
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" color="GrayText" gutterBottom>
              Status
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" color="GrayText" gutterBottom>
              Date purchased
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" color="GrayText" gutterBottom>
              
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Grid item xs={12}>
      <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
      {orderdata && orderdata.length > 0 ? (
            <div>
        {orderdata &&
          orderdata.map((row, index) => {
            return (
          <Accordion
            expanded={expandedPanel === "panel" + index}
            onChange={handleAccordionChange("panel" + index)}
          >
            <AccordionSummary expandIcon={<ArrowForwardIcon />}>
              <Grid container spacing={0}>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h6">{row.order_no}</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Stack direction="row">
                    <Chip label={row.status} />
                  </Stack>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="subtitle1">{row.created}</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  {/* <Typography variant="subtitle1">{row.currency} {row.net}</Typography> */}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} borderTop={1} borderColor="lightgray">
              {row.items.map((row, index) => {
                    return (
                        <Grid container spacing={0}>
                          <Grid item xs={6} sm={3}>
                            <Typography variant="subtitle1" color="GrayText">{row.name}</Typography>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <Avatar src={row.image}/>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <Typography variant="subtitle1">{row.qty}</Typography>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <Typography variant="subtitle1">AED {row.price}</Typography>
                          </Grid>
                        </Grid>
                    );
                  })}
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6" gutterBottom>
                    Shipping Address
                  </Typography>
                  <Typography variant="subtitle1" color="GrayText">
                  {row.address && row.address.name}
                  </Typography>
                  <Typography variant="subtitle1" color="GrayText">
                  {row.address && row.address.address} - {row.address &&row.address.city},
                  {row.address && row.address.area}{" "}
                  </Typography>
                  <Typography variant="subtitle1" color="GrayText">
                  {row.address && row.address.country}
                  </Typography>
                  <Typography variant="subtitle1" color="GrayText">
                  {row.address && row.address.zipcode}
                  </Typography>
                  <Typography variant="subtitle1" color="GrayText">
                  {row.address && row.address.mobile_no}
                  </Typography>
                  <Typography variant="subtitle1" color="GrayText">
                  {row.address && row.address.vat_number}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6" gutterBottom>
                    Payment methode
                  </Typography>
                  <Typography variant="subtitle1" color="GrayText">
                    Cash on delivery
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6" gutterBottom>
                    
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle2"
                        component="div"
                        color="GrayText"
                      >
                       
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        gutterBottom
                        variant="subtitle2"
                        component="div"
                        color="GrayText"
                      >
                        
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          );
        })}
          </div>
        ) : (
          <Typography variant="p" color="GaryText">
            No Items Found
          </Typography>
        )}
        </LoadingOverlay>
      </Grid>
    </div>
  );
}
