//import React from "react";
import React from "react";
import Header from "../Header/Header";
import HeaderTwo from "../HeaderTwo/HeaderTwo";
import HeaderThree from "../HeaderThree/HeaderThree";
import HeaderFour from "../HeaderFour/HeaderFour";
import CardMedia from "@mui/material/CardMedia";
import "./Education.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExploreNearby from "./ExploreNearby";
import TopUniversities from "./TopUniversities";
import TopCourses from "./TopCourses";
import MeetCareerExperts from "./MeetCareerExperts";
import Footer from "../Footer/Footer";
import axios from "../Utils/Axios"; //import axios config
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import EducationDetailBannerOne from "../images/Education_Detail/education_detail_banner3.png";
import { useHistory } from "react-router-dom";
import CentersforSpecialNeeds from "./CentersforSpecialNeeds";
import SearchIcon from '@mui/icons-material/Search';
import EducationGuide from "./EducationGuide";
import EducationBooks from "./EducationBooks";
import Communitees from "./Communitees";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "1rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.3rem",
    fontWeight: "400",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
    fontWeight: "400",
  },
};

export default function Education() {
  const history = useHistory();
  const [keyword, setKeyword] = React.useState("");
  const [autoData, setAutoData] = React.useState([]);

  React.useEffect(() => {
    getSearchAutocomplete();
  }, [keyword]);

  const getSearchAutocomplete = () => {
    if (keyword && keyword != "") {
      axios(`Home/search_autocomplete_data?keyword=${keyword}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoData([]);
    }
  };

  const filterSearch = () => {

    history.push({
      pathname: `/EducationLisiting`,
      search: `?keyword=${keyword}`,
    });

};


 const InstituteView = (id, type) => {
  if (type == 2) {
    history.push({
      pathname: `/EducationCourseDetail/${id}`,
    });
  } else {
    history.push({
      pathname: `/EducationDetail/${id}`,
    });
  }
};


  return (
 
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: "1px solid #e5e0e0",
        }}
        >
      <CardMedia
        alt="SAAEH Education Banner"
        class="education_banner_bg"
        image={EducationDetailBannerOne}
        className="overlay"
      
      >
        {/* <HeaderTwo /> */}
        <Header />
        <Box sx={{ m: "10%" }}>
       
          <Box
            sx={{
              display: "flex",
               pt: 7,
            }}
          >
            <Box>
              <ThemeProvider theme={theme}>
            
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color="#572672"
                  gutterBottom
                >
                  Explore TA`LEEM    
                </Typography>
              </ThemeProvider>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h4"
                  color="#3E3E3E"
                  gutterBottom
                  component="strong"
                >
                  Find unique education centers
                </Typography>
              </ThemeProvider>
            </Box>
          </Box>
         
          <Box
            sx={{
              display: "flex",
              pt: 3,
            }}
          >
            <Box
              bgcolor="white"
              border="1px solid #EBEBEB"
              p={2}
              minWidth="40%"
              borderRadius="10px"
              className="education_banner_search_box"
            >
              <Autocomplete
                freeSolo
                id="FindByUniversities"
                disableClearable
                options={autoData}
                getOptionLabel={(option) => option.edu_title}
                onKeyUp={(e) => setKeyword(e.target.value)}
                onKeyPress={(e) => setKeyword(e.target.value)}
                onKeyDown={(e) => {e.key === "Enter" && filterSearch()}}
                onClick={(e)=>{setKeyword(e.target.value);filterSearch();}}
                onChange={(e, value) => setKeyword(value.edu_title)}
                renderOption={(props, option) => (
                  <li {...props} onClick={()=>{InstituteView(option.edu_id,option.edu_type)}} >
                    {option.edu_title}
               </li>
                  )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Find by universities, schools and courses"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              bgcolor="#572672"
              color="white"
              px={3}
              marginLeft="8px"
              borderRadius="10px"
              className="education_banner_search_btn"
            >
              <Button onClick={filterSearch}>
                <SearchIcon /> SEARCH
              </Button>
            </Box>
          </Box>
        </Box>
      </CardMedia>
      </Box>

      <ExploreNearby />
      <EducationGuide/>
      <TopUniversities />
      <CentersforSpecialNeeds />
      <TopCourses />
      <Communitees />
      <MeetCareerExperts />
      <EducationBooks/>
      <Footer />
     
    </div>
      
  );
}
