import React from 'react'
import { Box } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import axios from "../Utils/Axios"; //import axios config
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";

import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
    search: {
      position: "relative",
      border: "solid 1px #E2E2E2",
      borderRadius: "20px",
      minWidth: "200px",
      display: "flex",
      alignItems: "center",
      padding: "0px 0px 0px 10px",
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      height: "32px",
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
      underline: {
        "&&&:before": {
          borderBottom: "none",
        },
        "&&:after": {
          borderBottom: "none",
        },
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
      [theme.breakpoints.up("lg")]: {
        width: "40ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  }));


export default function SearchBox() {

    const classes = useStyles();
    const history = useHistory();
    const [keyword, setKeyword] = React.useState("");
    const [autoData, setAutoData] = React.useState([]);
    const [profile, setProfile] = React.useState([]);

    React.useEffect(() => {
        getSearchAutocomplete();
        getProfile();
      }, [keyword]);
      React.useEffect(() => {
        getProfile();
      }, [JSON.parse(localStorage.getItem("data"))]);

      
    
      const filterSearch = () => {
        history.push({
          pathname: `/EducationLisiting`,
          search: `?keyword=${keyword}`,
        });
      };

      const getSearchAutocomplete = () => {
        if (keyword && keyword != "") {
          axios(`Home/search_autocomplete_data?keyword=${keyword}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "x-api-key": JSON.parse(localStorage.getItem("data")),
            },
          })
            .then((res) => {
              setAutoData(res.data.data);
            })
            .catch((err) => console.log(err));
        } else {
          setAutoData([]);
        }
      };

      const getProfile = () => {
        axios(`Home/get_profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        })
          .then((res) => {
            if (res.data.error) {
            } else {
              setProfile(res.data.data);
            }
          })
          .catch((err) => console.log(err));
      };

      const InstituteView = (id, type) => {
        if (type == 2) {
          history.push({
            pathname: `/EducationCourseDetail/${id}`,
          });
        } else {
          history.push({
            pathname: `/EducationDetail/${id}`,
          });
        }
      };



    return (
        <Box className="home_search_bg">
        <div className={classes.search}>
          <Autocomplete
            freeSolo
            className="headerTwo_search"
            disableClearable
            options={autoData}
            getOptionLabel={(option) => option.edu_title}
            onKeyUp={(e) => setKeyword(e.target.value)}
            onKeyPress={(e) => setKeyword(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && filterSearch();
            }}
            onClick={(e) => {
              setKeyword(e.target.value);
              filterSearch();
            }}
            onChange={(e, value) => setKeyword(value.edu_title)}
            renderOption={(props, option) => (
              <li
                {...props}
                onClick={() => {
                  InstituteView(option.edu_id, option.edu_type);
                }}
              >
                {option.edu_title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search ..."
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  maxWidth: "540px",
               
                }}
              />
            )}
          />
          <IconButton aria-label="search" color="inherit">
            <SearchIcon onClick={() => filterSearch()} />
          </IconButton>
        </div>
      </Box>
    )
}
