import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Divider } from "@mui/material";

const theme = createTheme();

export default function ThankYou() {

    return (
       <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid
          item
          xs={false}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
        >       
          <Box
            sx={{
              my: 20,
              mx: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Link to="/home">
              <Box
                component={"img"}
                pb={8}
                width={200}
                src="https://jobportal.gligx.com/img/saaeh/logo.webp"
                alt=""
                to="/home"
              />
            </Link>
            <Typography component="h1" variant="bold" color="GrayText">
            Thank You!
            </Typography>
            <Divider  sx={{ width: 250, m: 0.5 }} />
            <Typography color="GrayText" variant="body2" gutterBottom>
            Your registration has been submitted successfully. 
Click the link sent in your email address to activate your account.
            </Typography>
         
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage:
              "url(https://jobportal.gligx.com/img/saaeh/reg_background.webp)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              my: 20,
              mx: 25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography component="h1" variant="bold" gutterBottom color="GrayText">
            Ta’leem تعليم
            </Typography>
            <Typography color="GrayText" variant="body2" gutterBottom>
            1- linked to tabarr’u to offer free online educational platform , for those in need to continue their education
            </Typography>
            <Typography color="GrayText" variant="body2" gutterBottom>
            2- providing  a space to sell and donate used educational books , going straight to the student , to ensure direct receiving.
            </Typography>
            <Typography color="GrayText" variant="body2" gutterBottom>
            3-It will be a platform , for all those who have anything to teach , from knitting , carpeting , tailoring , sign language , cooking, singing , playing instruments ,etc. With all languages , and locations provided around the world .            </Typography>
            <Typography color="GrayText" variant="body2" gutterBottom>
            4-offering special section  , for people of determination , online in different languages.            </Typography>
            <Typography color="GrayText" variant="body2" gutterBottom>
            5- it will have in sha’allah , a personalized interface for those in need to enter some courses free , in order to help continue their education , with special ID given to each student.            </Typography>
            <Typography color="GrayText" variant="body2" gutterBottom>
            6- It will house all different schools, universities , institutes, learning centers,   in UAE , and other countries, to make it easier for tourists and education seekers , to find their best learning destinations, by filtering options , according , to type , location , prices , experience, etc. .. Especially the online universities that has been established to encourage students to enroll from all over the world            </Typography>
           
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    )
}
