import React from "react";
import Footer from "../Footer/Footer";
//import HeaderTwo from "../HeaderTwo/HeaderTwo";
import { Link, useHistory, useParams } from "react-router-dom";
import {Box,Rating,Container,Typography,Grid,ButtonBase,createTheme,
  ThemeProvider,Button,IconButton,Tooltip,Avatar,Card,CardMedia} from "@mui/material";
import { useAlert } from "react-alert";
import axios from "../Utils/Axios"; //import axios config
import StarIcon from "@mui/icons-material/Star";
import { ArrowBackOutlined } from "@material-ui/icons";
import Logo from "../images/logo-icon.webp";
import PropTypes from "prop-types";
import ReviewDialog from "./ReviewDialog";
import EducationBookStoresDetailRelated from "./EducationBookStoresDetailRelated";
import Header from "../Header/Header";

const theme = createTheme({
  palette: {
    primary: {
      light: "#7b37a0",
      main: "#572672",
      dark: "#3f1b52",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        fontSize: "0.875rem",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default function EducationBookStoresDetail(props) {
  const [value, setValue] = React.useState(2);
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const [books, setBooks] = React.useState({});
  const [booksId, setBookId] = React.useState(params && params.id);
  const [allrating, setRating] = React.useState([]);
  const history = useHistory();

  React.useEffect(() => {
    setBookId(params && params.id ? params.id : "");
  }, [params]);

  React.useEffect(() => {
    getbooks();
  }, [booksId]);
  const getbooks = () => {
    setLoading(true);
    axios(`Home/get_books_info?store_item_id=${booksId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setBooks(res.data.data[0]);
          setRating(res.data.data[0].all_rating);
          setLoading(false);
          setValue(res.data.data[0].rating);
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(
    () => {
      window.scrollTo(0, 0);
    }, 
    []
  );

  const addCart = (product_id) => {
    if (localStorage.getItem("data")) {
      axios(`Cart/addtoCart?store_item_id=${product_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            alert.show(res.data.message);
            history.push("/Cart");
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push("/Login");
    }
  };

  return (
    <div>
      {/* <HeaderTwo /> */}
      <Header />
      <ThemeProvider theme={theme}>
        <Box
          className="caro_bg"
          sx={{ bgcolor: "#fff", pt: "110px", pb: "48px" }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                p: 2,
                margin: "auto",
                flexGrow: 1,
                border: "none",
                boxShadow: "none",
              }}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <ButtonBase sx={{ maxWidth: "750px", maxHeight: "800px" }}>
                    <Card>
                      <CardMedia
                        component="img"
                        height="540"
                        image={books.image}
                        alt="green iguana"
                      />
                    </Card>
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container className="MyAccountProfileBg">
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="h6"
                        color="primary"
                        component="div"
                      >
                        {books.title}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Paperback – {books.created}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        by {books.author}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                        color="text.secondary"
                        fontWeight={600}
                      >
                        Price: {books.price} AED
                      </Typography>
                      <ButtonBase>
                        <Button
                          className="primaryButton"
                          variant="contained"
                          color="primary"
                          onClick={() => addCart(books.id)}
                        >
                          Buy Now
                        </Button>
                      </ButtonBase>
                     
                      <Box
                        sx={{
                          width: 200,
                          display: "flex",
                          alignItems: "center",
                          my: 2,
                        }}
                      >
                        <Rating
                         readOnly 
                          name="hover-feedback"
                          value={value}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                      </Box>
                      <Link to={`/EducationStoresDetails/${books.store_id}`}>
                        <Typography variant="body2" gutterBottom>
                          <Button>Seller</Button> {books.store_title}
                        </Typography>
                      </Link>
                      <Typography
                        variant="subtitle1"
                        component="div"
                      >
                        {books.description}  
                      </Typography>
                      <Typography gutterBottom>
                        Share your thoughts with other customers
                      </Typography>

                      <ReviewDialog bookId={booksId} getbooks={getbooks}/>
                      {allrating &&
                          allrating.map((value, index) => {
                            return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          p: 1,
                          m: 1,
                          height: "auto",
                          width: "100%",
                          borderRadius: 1,
                        }}
                      >
                        <Item>
                          <Avatar
                            alt="Education Books"
                            src={Logo}
                            sx={{ width: 56, height: 56 }}
                          />
                        </Item>
                        <Item>
                          <Typography fontWeight={600}>{value.fullname}</Typography>
                          <Typography variant="subtitle2" color="GrayText">
                            Reviewed on {value.created_at}
                          </Typography>
                          <Typography fontWeight={400}>{value.title}</Typography>
                          {value.review}
                        </Item>
                      </Box>
                      );
                    })}

                      <Link to="/EducationStoresAllbooks">
                        <Tooltip title=" Back to Books">
                          <Typography display="inline-block">
                            <IconButton>
                              <ArrowBackOutlined />
                            </IconButton>
                          </Typography>
                        </Tooltip>
                        <Typography
                          display="inline-block"
                          variant="subtitle1"
                          component="div"
                        >
                          Back to Books
                        </Typography>{" "}
                      </Link>
                    </Grid>
                  </Grid>
                  {/* <Grid item display={{ xs: "none", md: "block" }}>
                    <Link to="/EducationStoresAllbooks">
                      <Tooltip title=" Back to Books">
                        <Typography display="inline-block">
                          <IconButton>
                            <ArrowBackOutlined />
                          </IconButton>
                        </Typography>
                      </Tooltip>
                    </Link>
                  </Grid> */}
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <EducationBookStoresDetailRelated />
      </ThemeProvider>
      <Footer />
    </div>
  );
}
