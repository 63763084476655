import React, { useEffect, useState } from "react";
import {Grid,Box,Typography,IconButton,FormControl,InputLabel,Select,ListItem,Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,TextField,Button,} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import image from "../images/skeleton/1250-1000.webp";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const useStyle = makeStyles({
  image: {
    borderRadius: 10,
    border: "1px solid #e0e0e0",
  },
});

const filter = createFilterOptions();

export default function MyAccountBookStoreEditProductDialogEdit({book_id,store_id,manageListing}) {
  const alert = useAlert();
  const [uploadImage, setUploadImage] = useState("");
  const [editbooks, seteditBooks] = React.useState({});
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  const [format, setFormat] = React.useState("");
  const FormatChange = (event) => {
    setFormat(event.target.value);
  };


  const [languages, setLanguages] = React.useState("");
  const LanaguageChange = (event) => {
    setLanguages(event.target.value);
  };
  
  const [proimage, setImage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [author ,setAuthor] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
    axios(`Bookstore/get_books_info?id=${book_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          alert.error(res.data.message);
        } else {
          seteditBooks(res.data.data[0]);
          setUploadImage(res.data.data[0].images);
          setFormat(res.data.data[0].format);
          setLanguages(res.data.data[0].languages);
          setValue(res.data.data[0].author);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateBook = (event) => {
    console.log(value);
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("book_id", book_id);
    formdata.append("name", editbooks.title);
    formdata.append("price", editbooks.price);
    formdata.append("author", value);
    formdata.append("description", editbooks.description);
    formdata.append("format", format);
    formdata.append("languages", languages);
    formdata.append("logo", proimage);

    axios(`Bookstore/update_books`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setOpen(false);
          manageListing(store_id);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const [value, setValue] = React.useState(null);

  const getAuthors = () => {
    axios(`Institution/get_authors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setAuthors(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const [authors, setAuthors] = useState([]);


  useEffect(() => {
    getAuthors();
  }, []);

  return (
    <div>
      <ListItem
        onClick={handleClickOpen}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <EditIcon />
          </IconButton>
        }
      ></ListItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Products </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography guttterBottom>
            Edit Products to your store here.
            </Typography>
          </DialogContentText>

          <Grid container item spacing={3}>
            <Grid item xs={12} md={4}>
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  mt={3}
                >
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="body2">Product Logo </Typography>

                  <img
                    src={uploadImage ? uploadImage : image}
                    className={classes.image}
                    id="output"
                    width="100%"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCamera />
                  </label>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                autoFocus
                id="outlined-textarea"
                label="Product Name"
                placeholder="Product Name"
                variant="standard"
                fullWidth
                multiline
                value={editbooks.title}
                onChange={(e) => {
                  seteditBooks({ ...editbooks, title: e.target.value });
                }}
              />
              <TextField
                id="outlined-textarea"
                label="Product Price"
                placeholder="Product Price"
                variant="standard"
                fullWidth
                multiline
                value={editbooks.price}
                onChange={(e) => {
                  seteditBooks({ ...editbooks, price: e.target.value });
                }}
              />
              <TextField
                id="outlined-textarea"
                label="Author Name"
                placeholder="Author Name"
                variant="standard"
                fullWidth
                multiline
                value={editbooks.author}
                onChange={(e) => {
                  seteditBooks({ ...editbooks, author: e.target.value });
                }}
              />
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Description"
                variant="standard"
                multiline
                margin="dense"
                rows={4}
                value={editbooks.description}
                onChange={(e) => {
                  seteditBooks({ ...editbooks, description: e.target.value });
                }}
              />
              <FormControl fullWidth size="small" sx={{mt: 3}}>
                <InputLabel id="demo-simple-select-label">
                  Language
                </InputLabel>
                <Select
                variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={languages}
                  label="Status"
                  onChange={LanaguageChange}
                >
                  <MenuItem value={1}>English</MenuItem>
                  <MenuItem value={2}>Arabic</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" sx={{mt: 3}}>
                <InputLabel id="demo-simple-select-label">
                  Book Format
                </InputLabel>
                <Select
                variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={format}
                  label="Status"
                  onChange={FormatChange}
                >
                  <MenuItem value={1}>Audio CD</MenuItem>
                  <MenuItem value={2}>Board Book</MenuItem>
                  <MenuItem value={3}>Hardcover</MenuItem>
                  <MenuItem value={4}>Large Print</MenuItem>
                  <MenuItem value={5}>Loose Leaf</MenuItem>
                  <MenuItem value={6}>Paperback</MenuItem>
                </Select>
              </FormControl>
              <Autocomplete fullWidth size="small" sx={{mt: 3}}
                  value={value}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue(newValue);
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue(newValue.inputValue);
                    } else {
                      setValue(newValue.title);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={authors}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.title;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.title}</li>}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Authors" />
                  )}
                />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="MyAccountProfileBg">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateBook} className="primaryButton">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
