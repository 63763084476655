import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import axios from "../Utils/Axios"; //import axios config
import { Container, Grid } from "@mui/material";
import parse from "html-react-parser";

export default function EducationCourseDetailAdmissions({ courseId }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [loading, setLoading] = React.useState(false);

  const [course, setCourse] = React.useState({});

  React.useEffect(() => {
    getCourseProfile();
  }, [courseId]);

  const getCourseProfile = () => {
    setLoading(true);
    axios(`Course/get_course_profile?courseId=${courseId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setCourse(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
      // sx={{ pt: "48px", pb: "48px", bgcolor:"grey.100" }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {course.custom_fields &&
                course.custom_fields.map((item, index) => {
                  return (
                    <Accordion
                      sx={{ p: "0px 16px" }}
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        sx={{ width: "100%", flexShrink: 0 }}
                      >
                        <Typography variant="h6">{item.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="subtitle1">
                          {parse(item.description)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}
