import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { styled } from "@mui/material/styles";
import MuiGrid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const labels = {
  0.5: "0.5",
  1: "1",
  1.5: "1.5",
  2: "2",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "4.5",
  5: "5",
};

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};



export default function EducationBookStoresDetailRelated() {
    React.useEffect(
        () => {
          window.scrollTo(0, 0);
        },
        []
      );
    
      const [hover, setHover] = React.useState(0);
        
      const history = useHistory();
      const [loading, setLoading] = React.useState(false);
    
      const [books, setBooks] = React.useState([]);
      const [storebooks, setstoreBooks] = React.useState([]);
    
      React.useEffect(() => {
        instituteBooksList();
        storeBooksList();
      }, []);
    
      const BookView = (id) => {
        history.push({
          pathname: `/EducationBooksDetail/${id}`,
        });
      };
    
      const viewallcategory = () => {
        history.push({
          pathname: `/EducationAllbooks`,
        });
      };
    
      const instituteBooksList = () => {
        setLoading(true);
        axios(`Home/get_store_books`, {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        })
          .then((res) => {
            if (res.data.error) {
              setLoading(false);
              console.log(res.data.message);
            } else {
              setLoading(false);
              setBooks(res.data.data); //to set response data to state
            }
          })
          .catch((err) => console.log(err));
      };
    
    
    
      const storeBooksList = () => {
        setLoading(true);
        axios(`Home/get_store_books`, {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        })
          .then((res) => {
            if (res.data.error) {
              setLoading(false);
              console.log(res.data.message);
            } else {
              setLoading(false);
              setstoreBooks(res.data.data); //to set response data to state
            }
          })
          .catch((err) => console.log(err));
      };

  return (
    <div style={{ width: "100%" }}>
      <Grid container item spacing={0}>
        <Grid item xs={12} md={12}>
          <Box
            className="caro_bg"
            sx={{ bgcolor: "#f3e5f5", pt: "48px", pb: "48px" }}
          >
            <Container maxWidth="xl">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  p: 1,
                  overflow: "hidden",
                }}
              >
                <Box sx={{ p: 1 }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    color="#572672"
                  >
                    Related Books
                  </Typography>
                </Box>
                <Box sx={{ p: 1 }}>
                  <Divider
                    color="white"
                    sx={{ height: 28, m: 0.5 }}
                    orientation="vertical"
                  />
                </Box>

                <Box sx={{ p: 1, flexGrow: 1 }}>
                  <Typography
                    className="cp"
                    variant="body2"
                    color="InactiveCaptionText"
                    gutterBottom
                    component="div"
                    onClick={() => viewallcategory()}
                  >
                    View All Books
                  </Typography>
                </Box>
              </Box>
              <Box className="TopUniversities_carousel_bg">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 1.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "#025b95",
                      },
                    }),
                  }}
                  spinner
                  text="Loading ..."
                >
                  {books.length > 0 ? (
                    <Box width="100%" mx="auto" mb={5}>
                      <Carousel
                        responsive={responsive}
                        rightChevron={<KeyboardArrowRight />}
                        leftChevron={<KeyboardArrowLeft />}
                      >
                        {books.map((value, index) => {
                          return (
                            <Box
                              p={2}
                              className="TopUniversities_box"
                              onClick={() => BookView(value.id)}
                            >
                              <Link to="/EducationBooksDetail">
                                <Card>
                                  <CardMedia
                                    component="img"
                                    height="300"
                                    image={value.images}
                                    alt={value.title}
                                  />
                                  <Box p={1} className="cp" bgcolor="#fff">
                                    <CardContent>
                                      <Tooltip Tooltip title={value.title}>
                                        <Typography
                                          sx={{ mt: 1.5 }}
                                          gutterBottom
                                          textAlign="start"
                                          variant="subtitle2"
                                          component="div"
                                          className="text_bg"
                                        >
                                          {value.title}
                                        </Typography>
                                      </Tooltip>
                                      <Typography
                                        className="text_bg"
                                        textAlign="start"
                                        gutterBottom
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {value.author}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Rating
                                          name="hover-feedback"
                                          value={value.rating}
                                          precision={0.5}
                                          emptyIcon={
                                            <StarIcon
                                              style={{ opacity: 0.55 }}
                                              fontSize="inherit"
                                            />
                                          }
                                        />
                                        {value !== null && (
                                          <Box
                                            className="text_bg"
                                            sx={{ ml: 2 }}
                                          >
                                            {
                                              labels[
                                                hover !== -1 ? hover : value
                                              ]
                                            }
                                          </Box>
                                        )}
                                      </Box>
                                    </CardContent>
                                  </Box>
                                </Card>
                              </Link>
                            </Box>
                          );
                        })}
                      </Carousel>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        maxWidth: "650px",
                      }}
                    >
                      <Box p={5} paddingBottom="105px" className="error">
                        <Typography variant="h6" color="primary">
                          <span className="error_text">No data</span> found.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </LoadingOverlay>
              </Box>
            </Container>
          </Box>
        </Grid>
       
      </Grid>
    </div>
  )
}
