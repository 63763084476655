import {Box,Container,Divider,Grid,Paper,Typography,} from "@mui/material";
import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { experimentalStyled as styled } from "@mui/material/styles";

function srcset(
  image: string,
  width: number,
  height: number,
  rows = 1,
  cols = 1
) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CreativeTalentShow() {
  return (
    <div style={{ width: "100%" }}>
      <Box
        className="caro_bg"
        sx={{ bgcolor: "#faf0fc", pt: "48px", pb: "48px" }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
              overflow: "hidden",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="#572672"
              >
                Creative Talent Show
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>

            <Box sx={{ p: 1, flexGrow: 1 }}>
              <Typography
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
              >
                View All
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {Array.from(Array(3)).map((_, index) => (
              <Grid item xs={12} sm={12} md={4} key={index}>
                <Item>
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    color="#572672"
                  >
                    Student Talent Show
                  </Typography>
                  <ImageList
                    sx={{
                      width: "100%",
                      height: 350,
                      transform: "translateZ(0)",
                    }}
                    rowHeight={200}
                    gap={1}
                  >
                    {itemData.map((item) => {
                      const cols = item.featured ? 2 : 1;
                      const rows = item.featured ? 2 : 1;

                      return (
                        <ImageListItem key={item.img} cols={cols} rows={rows}>
                          <img
                            {...srcset(item.img, 250, 200, rows, cols)}
                            alt={item.title}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            sx={{
                              background:
                                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                            }}
                            title={item.title}
                            position="top"
                            actionIcon={
                              <IconButton
                                sx={{ color: "white" }}
                                aria-label={`star ${item.title}`}
                              >
                                <StarBorderIcon />
                              </IconButton>
                            }
                            actionPosition="left"
                          />
                        </ImageListItem>
                      );
                    })}
                  </ImageList>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

const itemData = [
  {
    img: "https://cdn2.momjunction.com/wp-content/uploads/2021/08/Draw.jpg.webp",
    title: "Draw",
    author: "@Draw",
  },
  {
    img: "https://cdn2.momjunction.com/wp-content/uploads/2021/08/Perform-magical-acts.jpg.webp",
    title: "Perform magical acts",
    author: "@Performmagicalacts",
  },
  {
    img: "https://cdn2.momjunction.com/wp-content/uploads/2021/08/Read-a-popular-song-in-a-dramatic-voice.jpg.webp",
    title: "Read a popular song in a dramatic voice",
    author: "@dramaticvoice",
    featured: true,
  },

  {
    img: "https://cf.ltkcdn.net/home-school/images/orig/204199-1695x1132-fingermath.jpg",
    title: "Easy Finger Math Tricks",
    author: "@EasyFingerMathTricks",
  },
  {
    img: "https://cdn2.momjunction.com/wp-content/uploads/2021/08/Dance.jpg.webp",
    title: "Dance",
    author: "@Dance",
  },

  {
    img: "https://cf.ltkcdn.net/kids/images/orig/178412-775x619-Kid-Magician-IS.jpg",
    title: "Easy Magic Tricks for Kids",
    author: "@EasyMagicTricksforKids",
    featured: true,
  },
];
