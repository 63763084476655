import * as React from "react";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import axios from "../Utils/Axios";
import { useAlert } from "react-alert";
import { Link, useHistory } from "react-router-dom";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function ReviewRatingDialogCourse({courseId,ratingvalue,rating_count,getCourseProfile}) {
  const [open, setOpen] = React.useState(false);
  const [title, set_title] = React.useState("");
  const [desc, set_desc] = React.useState("");
  const [rating, setRating] = React.useState();
  const history = useHistory();
  const alert = useAlert();
  const handleClickOpen = () => {
    if(localStorage.getItem("data"))
    {
    setOpen(true);
    getcourserating();
    }
    else{
      history.push({
        pathname: "/login",
        state: { data: true },
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);

  const addRating = () => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      
      formdata.append("rating", rating);
      formdata.append("title", title);
      formdata.append("description", desc);
      
      formdata.append("courseId", courseId);
      axios(`Course/rate_course`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            alert.success(res.data.message);
            getCourseProfile();
            setOpen(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getcourserating = () => {
    axios(`Course/get_user_course_rating?courseId=${courseId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          set_title (res.data.data[0].title);
          setRating (res.data.data[0].rating);
          set_desc (res.data.data[0].review);
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    getCourseProfile();
    getcourserating();
  }, [courseId]);

  return (
    <div>
      <Box
        onClick={handleClickOpen}
        className="star_rating_box"
        display="flex"
        sx={{
          "& > legend": { pb: 5 },
          cursor: "pointer",
        }}
      >
        <Rating name="half-rating" 
        readOnly 
        value={ratingvalue}
        precision={0.5}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}/>{" "}
        <span className="education_detail_rating_count">{ratingvalue} ({rating_count})</span>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Write a Customer Comment and Rating</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please write a comment for this Course. We will use this to improve
            our service.
          </DialogContentText>
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="hover-feedback"
              value={rating}
              getLabelText={getLabelText}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {rating !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
            )}
          </Box>
          <TextField
            autoFocus
            label="Title"
            variant="standard"
            fullWidth
            value={title}
            onChange={(e) => set_title(e.target.value)}
          />
          <TextField
            multiline
            maxRows={4}
            margin="dense"
            id="name"
            label="About the Course"
            type="text"
            fullWidth
            variant="standard"
            value={desc}
            onChange={(e) => set_desc(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="MyAccountProfileBg">
          <Button onClick={handleClose}>Cancel</Button>
          <Button className="primaryButton"
          onClick={addRating}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
