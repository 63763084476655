import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import logo_skeleton from "../images/skeleton/logo_skeleton.webp";
import { makeStyles } from "@material-ui/core/styles";
import image2 from "../images/user.webp";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import axios from "../Utils/Axios"; //import axios config
import {
  IconButton,
  Box,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Stack,
  Pagination,
  Grid,
  Divider,
  TextField,
  Paper,
  Autocomplete,
  Button,
} from "@mui/material";

const useStyle = makeStyles({
  image2: {
    borderRadius: 100,
  },
});

export default function MyAccountBookStore(props) {
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  const history = useHistory();
  const alert = useAlert();
  const [uploadImage, setUploadImage] = React.useState("");
  const [image, setImage] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [insti_name, setInsti_name] = React.useState("");
  const [insti_email, setInsti_email] = React.useState("");
  const [insti_mobile, setInsti_mobile] = React.useState("");
  const [insti_address, setInsti_address] = React.useState("");
  const [insti_city, setInsti_city] = React.useState("");
  const [insti_zipcode, setInsti_zipcode] = React.useState("");
  const [insti_website, setInsti_website] = React.useState("");
  const [countriesss, setCountires] = React.useState([]);
  const [country, set_country] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [storebooks, setstoreBooks] = React.useState([]);

  React.useEffect(() => {
    getCountries();
    storeBooksList();
  }, []);

  let inst_image;
  if (image !== "") {
    inst_image = image;
  } else {
    inst_image = logo_skeleton;
  }

  let inst_banner;
  if (banner !== "") {
    inst_banner = banner;
  } else {
    inst_banner = logo_skeleton;
  }

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("name", insti_name);
    formdata.append("country", country);
    formdata.append("logo", image);
    formdata.append("email", insti_email);
    formdata.append("mobile", insti_mobile);
    formdata.append("address", insti_address);
    formdata.append("city", insti_city);
    formdata.append("zipcode", insti_zipcode);
    formdata.append("website", insti_website);
    axios(`Bookstore/add_bookstore`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCountries = () => {
    axios(`Settings/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const storeBooksList = () => {
    setLoading(true);
    axios(`Home/get_store_books`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setstoreBooks(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div sx={{ display: "flex" }}>
      <Accordion
        sx={{ bgcolor: "#fff", px: 2, mt: 3 }}
        expanded={expanded === "panel3"}
        onChange={handleChange2("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ width: "100%", flexShrink: 0 }}
        >
          <Typography variant="subtitle2">Book Store List</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {storebooks.map((value, index) => {
          return (
          <Box sx={{ display: "flex", p: 1 }}>
            <Box>
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <ListItemText>{value.title}</ListItemText>
            </Box>
            <Box>
              <ListItem
                secondaryAction={
                  <Link to={`/MyAccountBookStoreEdit/${value.id}`}>
                    <IconButton edge="end" aria-label="delete">
                      <EditIcon />
                    </IconButton>{" "}
                  </Link>
                }
              ></ListItem>
            </Box>
            <Box>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                }
              ></ListItem>
            </Box>
          </Box>
          )
        })}
          <Box p={2} display="flex" justifyContent="center">
            <Stack spacing={2}>
              <Pagination />
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ bgcolor: "#fff", px: 2 }}
        expanded={expanded === "panel4"}
        onChange={handleChange2("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
          sx={{ width: "100%", flexShrink: 0 }}
        >
          <Typography variant="subtitle2">Register new Store</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Store Name"
                variant="outlined"
                value={insti_name}
                onChange={(e) => setInsti_name(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Email"
                variant="outlined"
                value={insti_email}
                onChange={(e) => setInsti_email(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Phone Number"
                type="number"
                variant="outlined"
                value={insti_mobile}
                onChange={(e) => setInsti_mobile(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Address"
                variant="outlined"
                value={insti_address}
                onChange={(e) => setInsti_address(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                fullWidth
                size="small"
                id="country-select-demo"
                options={countriesss}
                autoHighlight
                getOptionLabel={(option) => option.title}
                onChange={(event, value) => value && set_country(value.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    variant="outlined"
                    value={countriesss}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="City"
                variant="outlined"
                value={insti_city}
                onChange={(e) => setInsti_city(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                label="Zip Code / Postal Code"
                variant="outlined"
                value={insti_zipcode}
                onChange={(e) => setInsti_zipcode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                label="Website"
                variant="outlined"
                value={insti_website}
                onChange={(e) => setInsti_website(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper style={{ width: "250px" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file2"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="body2">Store Logo </Typography>

                  <img
                    src={uploadImage ? uploadImage : image2}
                    className={classes.image2}
                    id="output"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file2" style={{ cursor: "pointer" }}>
                    <PhotoCamera />
                  </label>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                textAlign="right"
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
