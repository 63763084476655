import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useHistory } from "react-router-dom";
import axios from "../Utils/Axios"; //import axios config
import { AuthContext } from "../Context/AuthContext";
import LogInPopup from "../Registration/LogIn_Popup";
import { Avatar } from "@mui/material";
import logo from "../images/logo.webp";
import EducationMenuMobile from "../Header/EducationMenuMobile";
import Menu from "@mui/material/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";

export default function HeaderFour() {

  const [anchorElNav2, setAnchorElNav2] = React.useState(null);

  const handleOpenNavMenu2 = (event) => {
    setAnchorElNav2(event.currentTarget);
  };

  const handleCloseNavMenu2 = () => {
    setAnchorElNav2(null);
  };

  const history = useHistory();
  
  const logOut = () => {
    localStorage.removeItem("data");
    setLogin(false);
    history.push({
      pathname: `/UserProfile`,
    });
  };

  const [profile, setProfile] = React.useState([]);
  const { login, setLogin } = useContext(AuthContext);

  React.useEffect(() => {
    getProfile();
  }, [JSON.parse(localStorage.getItem("data"))]);

  const getProfile = () => {
    axios(`Home/get_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="header_three_bg">
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/education">
              <Box component={"img"} p={1} width={170} src={logo} alt="" />
            </Link>
          </Typography>

          {localStorage.getItem("data") || login ? (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Avatar
                src={profile.profile_image}
                sx={{ width: 32, height: 32, bgcolor: "white", my: "auto" }}
              />
              <Button color="inherit">{profile.fullname}</Button>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mx: 1 }}
              >
                <LogoutIcon onClick={logOut} />
              </IconButton>
            </Box>
          ) : (
            <Box>
              <LogInPopup />
            </Box>
          )}

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu2}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav2)}
              onClose={handleCloseNavMenu2}
              sx={{
                display: { xs: "block" },
              }}
            >
              {/* <SaaehListMenu /> */}
              <EducationMenuMobile />
              <MenuItem mt={2}>
                <Avatar
                  sx={{ width: 32, height: 32, bgcolor: "white", mr: 1 }}
                  src={profile.profile_image}
                />{" "}
                <Link to="/MyAccount">My Account </Link>
              </MenuItem>
              <Link to="/Settings">
                <MenuItem>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
              </Link>
              <MenuItem onClick={logOut}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
