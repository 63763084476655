import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";

export default function MyAccountEditOverviewTextEditor({overview,setOverview}) {
    const editor = useRef(null);
    const [content, setContent] = useState("Start writing");
    const config = {
      readonly: false,
      height: 400,
    };
    const handleUpdate = (event) => {
      const editorContent = event.target.innerHTML;
      setContent(editorContent);
    };

  return (
    <div className="TextEditor">
      <JoditEditor
        ref={editor}
        value={overview}
        config={config}
        onBlur={(e) => {
          setOverview(e);
        }}
        onChange={(newContent) => {}}
      />
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}
