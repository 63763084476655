import React from "react";
import Footer from "../Footer/Footer";
//import HeaderTwo from "../HeaderTwo/HeaderTwo";
import Header from "../Header/Header";
import Container from "@mui/material/Container";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import LoadingOverlay from "react-loading-overlay";
import { createTheme } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "../Utils/Axios"; //import axios config
import EducationDetailAwards from "./EducationDetailAwards";
import parse from "html-react-parser";
import { Grid, IconButton, Tooltip } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import CallIcon from "@mui/icons-material/Call";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ReviewRatingDialog from "./ReviewRatingDialog";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function EducationCourseDetail(props) {
  const [value, setValue] = React.useState(0);
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [course, setCourse] = React.useState({});
  const [showNumber, setshowNumber] = React.useState(0);
  const [showMore, setShowMore] = React.useState(false);
  const [showButton, setshowButton] = React.useState(0);
  const params = useParams();
  const [institutionId, setInstitutionId] = React.useState(params && params.id);

  React.useEffect(() => {
    getCourseProfile();
  }, [institutionId]);

  const getCourseProfile = () => {
    setLoading(true);
    axios(`Institution/get_expert_profile?institutionId=${institutionId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setCourse(res.data.data);
          setValue(res.data.data.rating);
          if (
            res.data.data.description &&
            res.data.data.description.length > 500
          ) {
            setshowButton(1);
          }
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const ShowNumber = () => {
    setshowNumber(1);
  };

  const apply = (courseId, request_type) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("courseId", courseId);
      formdata.append("request_type", request_type);
      axios(`Institution/course_apply`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            getCourseProfile();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  const [ratings, setRating] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const getrating = (e, page, rowsPerPage) => {
    setLoading(true);
    axios(
      `Institution/get_all_institute_rating?institutionId=${institutionId}&page=${page}&per_page=${rowsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setLoading(false);
          setRating(res.data.data);
          setTotal(res.data.total);
        }
      })
      .catch((err) => console.log(err));
  };

  function loginclick(event) {
    history.push({
      pathname: "/login",
      state: { data: true },
    });
  }

  return (
    <div style={{ width: "100%" }}>
      {/* <HeaderTwo /> */}
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
      <Header/>
      </LoadingOverlay>
      <Box sx={{ bgcolor: "#572672" }}>
      <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
        <Container>
          <Box sx={{ flexGrow: 1, mt: 9 }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={4} md={3}>
                <Link to="/education">
                  <CardMedia
                    component={"img"}
                    sx={{
                      width: 170,
                      height: 170,
                      p: 1,
                      m: 1,
                      borderRadius: 100,
                      border: "5px solid #fff",
                      bgcolor: "#fff",
                    }}
                    src={course.logo}
                    alt=""
                    to="/home"
                  />
                </Link>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Typography
                  textOverflow="ellipsis"
                  overflow="hidden"
                  variant="h4"
                  gutterBottom
                  component="div"
                  color="white"
                  startIcon={<LocationOnIcon />}
                >
                  {course.name && course.name}
                </Typography>
                <Box sx={{ color: "white", alignItems: "center" }}>
                  <IconButton aria-label="upload picture" component="span">
                    <LocationOnIcon sx={{ color: "white" }} />
                  </IconButton>
                  <Typography
                    color="white"
                    variant="standard"
                    startIcon={<LocationOnIcon />}
                  >
                    {course.city && course.city}
                  </Typography>
                </Box>
                <ReviewRatingDialog
                  instiId={institutionId}
                  ratingvalue={value}
                  rateType={1}
                  rating_count={course.rating_count}
                  getinstituteProfile={getCourseProfile}
                  getrating={getrating}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
        </LoadingOverlay>
      </Box>
      <Box sx={{ bgcolor: "#f9f9f9", py: "1rem" }}>
        <Container>
          <Typography variant="body2" gutterBottom>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/">
                  <Typography>Home</Typography>
                </Link>
                <Link to="/Education">
                  <Typography>Education</Typography>
                </Link>
                <Link to="">
                  <Typography>Experts </Typography>
                </Link>
              </Breadcrumbs>
            </div>
          </Typography>
        </Container>
      </Box>

      <Container>
        <Grid container spacing={3} py={3}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={12}>
                <CardMedia
                  component="img"
                  sx={{ border: "solid 1px #f1f1f1", borderRadius: 4 }}
                  height="275"
                  Width="100%"
                  alt="Course banner"
                  image={course.banner}
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <Typography variant="p" color="text.secondary">
                  {showMore
                    ? course.description && parse(course.description)
                    : `${course.short_description && course.short_description}`}
                  {showButton === 1 ? (
                    <Typography variant="p" color="text.secondary">
                      {showMore ? "" : ".."}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {showButton === 1 ? (
                  <Button
                    className="btn"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Show less" : "Show more"}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} height="fit-content">
            <Box bgcolor="grey.100" borderRadius={4}>
              <Grid>
                <Grid>
                  {course.course_fee !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={2} md={2}>
                          <Tooltip title="Fees" placement="right">
                            <IconButton aria-label="fees">
                              <AttachMoneyIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <Typography variant="body2" gutterBottom>
                            {course.course_fee}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {course.duration !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={2} md={2}>
                          <Tooltip title="Duration" placement="right">
                            <IconButton aria-label="Duration">
                              <TimelapseIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <Typography variant="body2" gutterBottom>
                            {course.duration}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {course.type_id !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={2} md={2}>
                          <Tooltip title="  Course Type" placement="right">
                            <IconButton aria-label="Course Type">
                              <LibraryBooksIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <Typography variant="body2" gutterBottom>
                            {course.course_type} Weekend, Weekday classes
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {course.duration_type !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={2} md={2}>
                          <Tooltip title="Course Schedule" placement="right">
                            <IconButton aria-label="Course Schedule">
                              <MenuBookIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <Typography variant="body2" gutterBottom>
                            2021 - 2022
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {course.institution_id !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={2} md={2}>
                          <Tooltip title="University" placement="right">
                            <IconButton aria-label="University">
                              <SchoolIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <Typography variant="body2" gutterBottom>
                            {course.institue}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {course.course_fee !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={2} md={2}>
                          <Tooltip title="Teliphone" placement="right">
                            <IconButton aria-label="Teliphone">
                              <CallIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="cp_bg"
                          >
                            {showNumber === 0 ? (
                              <span className="cp" onClick={() => ShowNumber()}>
                                Show number
                              </span>
                            ) : (
                              course.tel_no
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#48C851"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 0px 15px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Typography>
                        {localStorage.getItem("data") ? (
                          course.apply === 0 ? (
                            <Button
                              color="inherit"
                              onClick={() => apply(course.id, 1)}
                            >
                              Apply
                            </Button>
                          ) : (
                            <Button
                              color="inherit"
                              onClick={() => alert.error("All ready applied!")}
                            >
                              Admission Applied
                            </Button>
                          )
                        ) : (
                          <Button
                            color="inherit"
                            onClick={loginclick}
                          >
                            Admission
                          </Button>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#434343"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 15px 0px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {localStorage.getItem("data") ? (
                      course.request === 0 ? (
                        <Button
                          color="inherit"
                          onClick={() => apply(course.id, 2)}
                        >
                          Request info
                        </Button>
                      ) : (
                        <Button color="inherit">Requested</Button>
                      )
                    ) : (
                      <Button
                        color="inherit"
                        onClick={loginclick}
                      >
                        Request info
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{ bgcolor: "background.paper" }}>
        <EducationDetailAwards
          institueId={institutionId}
          setInstitutionId={setInstitutionId}
          getrating={getrating}
          ratings={ratings}
          total={total}
        />
      </Box>
      <Footer />
    </div>
  );
}
