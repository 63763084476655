//import React from "react";
//import React from "react";
import { useHistory } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { lightBlue } from "@mui/material/colors";
import { Grid } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ButtonBase from "@mui/material/ButtonBase";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function ExploreNearby() {
  const history = useHistory();
  const [locations, setLocations] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    locationList();
  }, []);

  //method for getting recent jobs
  const locationList = () => {
    setLoading(true);
    axios(`Home/locationList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setLocations(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const EducationLisiting = (id) => {
    history.push({
      pathname: `/EducationLisitingMap`,
      search: `?location=${id}`,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Container maxWidth="xl">
        <Box pt={6} pb={6} m={0}>
          <Box width="100%" px={2}>
            <Typography
              variant="body2"
              color="InactiveCaptionText"
              gutterBottom
              component="div"
            >
              Explore with TA`LEEM
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              color="#572672"
            >
              Explore Nearby
            </Typography>
          </Box>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Grid
              container
              px={2}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 1, sm: 6, md: 12 }}
            >
              {locations.map((value, index) => {
                return (
                  <Grid
                    item
                    xs={2}
                    sm={3}
                    md={4}
                    lg={3}
                    key={index}
                    sx={{ flexWrap: "inherit" }}
                  >
                    <Grid
                      sx={{
                        borderRadius: 5,
                        margin: "auto",
                        maxWidth: 500,
                        flexGrow: 1,
                        display: "flex",
                      }}
                      onClick={() => EducationLisiting(value.id)}
                    >
                      <Grid container spacing={2} sx={{ flexWrap: "nowrap" }}>
                        <Grid item>
                          <ButtonBase sx={{ width: 96, height: 96 }}>
                            <Img
                              alt="complex"
                              src={value.imgUrl}
                              sx={{ borderRadius: 5 }}
                            /> 
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            spacing={2}
                            alignSelf="center"
                          >
                            <Grid item xs>
                              <Typography
                                noWrap
                                variant="h6"
                                color="Primary"
                                gutterBottom
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: "1",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {value.title}
                              </Typography>
                              <Typography
                                noWrap
                                variant="p"
                                color="Primary"
                                gutterBottom
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: "1",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {value.count} Education Center
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

          </LoadingOverlay>
        </Box>
      </Container>
    </div>
  );
}
