import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MyAccountManageListingList from "./MyAccount_ManageListingList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import MyAccountPagesStepper from "./MyAccountPagesStepper";

export default function MyAccount_ManageListing() {
  const [expanded, setExpanded] = React.useState("panel1");
  const [edit, setEdit] = React.useState(0);

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [value, setValue] = React.useState(0);

  const handleEdit = (event) => {
    setEdit(1);
  };

  const handleEdit2 = (event) => {
    setEdit(0);
  };

  return (
    <div sx={{ display: "flex" }}>
      <Grid container item spacing={2} mt={0.3}></Grid>
      <Grid container item spacing={2}>
        {edit === 0 ? (
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                textAlign="start"
                gutterBottom
                variant="h6"
                sx={{ mb: 1.5 }}
              >
                Manage List
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} className="MyAccountProfileBg">
              <Typography textAlign="end">
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleEdit}
                  className="primaryButton"
                >
                  Create new business
                </Button>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12}>
          {edit === 0 ? (
            <Card>
              <CardContent>
                <MyAccountManageListingList />
              </CardContent>
            </Card>
          ) : (
            <Grid container spacing={2}>
              <Grid container item spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    textAlign="start"
                    gutterBottom
                    variant="h6"
                    sx={{ mb: 1.5 }}
                  >
                    Create new business
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6} className="MyAccountProfileBg">
                  <Typography textAlign="end">
                    <Button
                      startIcon={<ArrowBackIcon />}
                      onClick={handleEdit2}
                      className="primaryButton"
                    >
                      Manage List
                    </Button>
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <MyAccountPagesStepper />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
