import React, { useState } from "react";
import HeaderFour from "../HeaderFour/HeaderFour";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Container,
  Typography,
  TextField,
  Card,
  Autocomplete,
  Paper,
  Tooltip,
  Tab,
  Tabs,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText,
  MenuItem,
  Button,
  IconButton,
  Grid,
  InputLabel,
  FormControl,
  Select,
  createTheme,
  ThemeProvider,
  CardContent,
} from "@mui/material/";
import PropTypes from "prop-types";
import axios from "../Utils/Axios"; //import axios config
import { makeStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import logo_skeleton from "../images/skeleton/logo_skeleton.webp";
import MyAccountEditGallery from "./MyAccountEditGallery";
import MyAccountEditAwards from "./MyAccountEditAwards";
import MyAccountEditOverviewTextEditor from "./MyAccountEditOverviewTextEditor";
import LoadingOverlay from "react-loading-overlay";
import MyAccountEditNewsAndEvents from "./MyAccountEditNewsAndEvents";
import CustomeFieldDialog from "./CustomeFieldDialog";
import CustomeFieldDialogEdit from "./CustomeFieldDialogEdit";
import MyAccountEditCourses from "./MyAccountEditCourses";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import MyAccountEditDashboard from "./MyAccountEditDashboard";

const useStyle = makeStyles({
  image2: {
    borderRadius: 10,
    maxWidth: "100%",
  },
  image3: {
    borderRadius: 10,
    maxWidth: "100%",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      light: "#833baa",
      main: "#572672",
      dark: "#381849",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

window.scrollTo(0, 0);

export default function MyAccountEdit(props) {
  const [page, setPage] = React.useState(1);
  const [experts, setExpertsList] = React.useState([]);
  const [editaddress, setEditaddress] = React.useState({});
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [insti_id] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [customfields, setCustomlist] = React.useState([]);

  React.useEffect(() => {
    //topCoursesList();
    manageList(insti_id);
    customList(insti_id);
  }, [page, rowsPerPage]);

  const manageList = (insti_id) => {
    setLoading(true);
    axios(
      `Course/course_instituitions?institue_id=${insti_id}&page=${page}&per_page=${rowsPerPage}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTotal(res.data.total);
          setExpertsList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  var fileload2 = (event) => {
    console.log(event.target.files);
    if (event.target.files) {
      setUploadBanner(URL.createObjectURL(event.target.files[0]));
      setBanner(event.target.files[0]);
    }
  };

  const [type, setType] = React.useState("");
  const [city, setCity] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [status, setStatus] = React.useState("");
  const StatushandleChange = (event) => {
    setStatus(event.target.value);
  };

  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [instituteName, setInstit_name] = React.useState("");
  const [instituteMobile, setInstit_mobile] = React.useState("");
  const [instituteStudents, setInstit_students] = React.useState("");
  const [instituiontypes, setInstitutionTypes] = React.useState([]);
  const [instituiontype, setInstitutionType] = React.useState("");
  const [instituteEmail, setInstit_email] = React.useState("");
  const [minfee, setInstit_minfee] = React.useState("");
  const [maxfee, setInstit_maxfee] = React.useState("");
  const [instituteAddress, setInstit_address] = React.useState("");
  const [instituioncities, setInstitutionCities] = React.useState([]);
  const [instituioncity, setInstitutionCity] = React.useState("");
  const [instituioncountry, setInstitutionCountry] = React.useState("");
  const [instituionzipcode, setInstit_zipcode] = React.useState("");
  const [instituionwebsite, setInstit_website] = React.useState("");
  const [instituiongender, setInstit_gender] = React.useState("");
  const [instituionlocation, setInstit_location] = React.useState("");
  const [instituionleadership, setInstit_leadership] = React.useState("");
  const [instituionyear, setInstit_year] = React.useState("");
  const [instituionlatitude, setInstit_latitude] = React.useState("");
  const [instituionmap, setInstit_map] = React.useState("");
  const [instituionlongitude, setInstit_longitude] = React.useState("");
  const [uploadImage, setUploadImage] = React.useState("");
  const [bannerImage, setUploadBanner] = React.useState("");
  const [image, setImage] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [gallery, setGallery] = React.useState([]);
  const [profile, setProfile] = React.useState([]);
  const [content, setContent] = useState("");
  const [countriesss, setCountires] = useState([]);
  const [overview, setOverview] = React.useState("");
  const [whatdo, setWhatdo] = React.useState("");

  React.useEffect(() => {
    editPost();
    getInstitution();
    getCities();
    getCountries();
  }, []);

  React.useEffect(() => {
    getProfile();
  }, [JSON.parse(localStorage.getItem("data"))]);

  const getInstituitionEdit = (insti_id, id) => {
    history.push({
      pathname: `/MyAccount_ManageListingAddCoursesEdit/${insti_id}/${id}`,
    });
  };

  const editPost = () => {
    setLoading(true);
    axios(`Institution/get_own_institute_profile?institutionId=${insti_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error === true) {
          history.push({
            pathname: "/",
          });
        } else {
          setLoading(false);
          setInstit_name(res.data.data[0].name);
          setInstitutionType(res.data.data[0].type);
          setInstit_mobile(res.data.data[0].tel_no);
          setInstit_students(res.data.data[0].no_of_students);
          setInstit_email(res.data.data[0].email);
          setInstit_address(res.data.data[0].address_1);
          setInstitutionCity(res.data.data[0].city_id);
          setInstitutionCountry(res.data.data[0].country);
          setInstit_zipcode(res.data.data[0].zipcode);
          setInstit_website(res.data.data[0].website);
          setInstit_gender(res.data.data[0].gender_id);
          setInstit_location(res.data.data[0].map_link);
          setInstit_year(res.data.data[0].no_of_students);
          setInstit_latitude(res.data.data[0].latitude);
          setInstit_longitude(res.data.data[0].longitude);
          setInstit_map(res.data.data[0].maplink);
          setInstit_leadership(res.data.data[0].leadership);
          setImage(res.data.data[0].logo);
          setBanner(res.data.data[0].banner);
          setGallery(res.data.data[0].gallery);
          setContent(res.data.data[0].description);
          setOverview(res.data.data[0].description);
          setWhatdo(res.data.data[0].whatdo);
          setStatus(res.data.data[0].status);
          setInstit_minfee(res.data.data[0].min_fee);
          setInstit_maxfee(res.data.data[0].max_fee);
        }
      })
      .catch((err) => console.log(err));
  };

  const getInstitution = () => {
    axios(`Institution/getInstitutionType`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setInstitutionTypes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = () => {
    axios(`Institution/getCities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setInstitutionCities(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCountries = () => {
    axios(`Institution/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("institutionId", insti_id);
    formdata.append("instituteName", instituteName);
    formdata.append("instituiontype", instituiontype);
    formdata.append("instituteEmail", instituteEmail);
    formdata.append("instituteMobile", instituteMobile);
    formdata.append("instituteAddress", instituteAddress);
    formdata.append("instituteStudents", instituteStudents);
    formdata.append("instituioncity", instituioncity);
    formdata.append("instituioncountry", instituioncountry);
    formdata.append("instituionzipcode", instituionzipcode);
    formdata.append("instituiondesc", content);
    formdata.append("instituionlocation", instituionlocation);
    formdata.append("instituionwebsite", instituionwebsite);
    formdata.append("instituiongender", instituiongender);
    formdata.append("instituionlatitude", instituionlatitude);
    formdata.append("instituionlongitude", instituionlongitude);
    formdata.append("instituionleadership", instituionleadership);
    formdata.append("instituionmap", instituionmap);
    formdata.append("min_fee", minfee);
    formdata.append("max_fee", maxfee);
    formdata.append("uploadImage", image);
    formdata.append("bannerImage", banner);
    formdata.append("status", status);
    axios(`Institution/update_institution`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  let inst_image;
  if (image !== "") {
    inst_image = image;
  } else {
    inst_image = logo_skeleton;
  }

  let inst_banner;
  if (banner !== "") {
    inst_banner = banner;
  } else {
    inst_banner = logo_skeleton;
  }

  const getProfile = () => {
    axios(`Home/get_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const overviewsubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("institutionId", insti_id);
    formdata.append("overview", overview);
    axios(`Institution/update_institution_overview`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const customList = (insti_id) => {
    setLoading(true);
    axios(`Home/get_custom_fields?institue_id=${insti_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCustomlist(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <HeaderFour />
      <Box
        sx={{ bgcolor: "#F6F9FC" }}
        py={{ xs: "1rem", sm: "2rem", md: "3rem", lg: "4rem" }}
      >
        <Container maxWidth="xl">
          <ThemeProvider theme={theme}>
            <Box
              pt={{ xs: "100px", sm: "60px", md: "48px", lg: "48px" }}
              pb={{ xs: "16px", sm: "16px", md: "48px", lg: "48px" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Tabs
                        className="MyAccountEditTabs"
                        orientation="vertical"
                        fullWidth
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                      >
                        <Tab
                          className="tabac"
                          label="Profile Insights"
                          {...a11yProps(0)}
                        /> 
                         <Tab
                          className="tabac"
                          label="Basic Details"
                          {...a11yProps(1)}
                        /> 
                        <Tab
                          className="tabac"
                          label="Overview"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="tabac"
                          label="Courses"
                          {...a11yProps(3)}
                        />
                        <Tab
                          className="tabac"
                          label="Gallery"
                          {...a11yProps(4)}
                        />
                        <Tab
                          className="tabac"
                          label="Awards and Accreditions"
                          {...a11yProps(5)}
                        />
                        <Tab
                          className="tabac"
                          label="News and Events"
                          {...a11yProps(6)}
                        />
                        <Tab
                          className="tabac"
                          label="Custome Fields +"
                          {...a11yProps(7)}
                        ></Tab>
                      </Tabs>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={8} lg={9}>
                  <Card> 
                  <TabPanel value={value} index={0}>
                  <MyAccountEditDashboard />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <LoadingOverlay
                        active={loading}
                        styles={{
                          overlay: (base) => ({
                            ...base,
                            background: "rgba(255, 255, 255, 1.9)",
                          }),
                          spinner: (base) => ({
                            ...base,
                            width: "75px",
                            "& svg circle": {
                              stroke: "#025b95",
                            },
                          }),
                        }}
                        spinner
                        text="Loading ..."
                      >
                        <Grid container item spacing={3}>
                          <Grid item xs={12} md={10}>
                            <Typography variant="h6" color="GaryText">
                              Basic details
                            </Typography>
                            <Typography
                              variant="body2"
                              textAlign="start"
                              gutterBottom
                            >
                              The information can be edited
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Link to="/MyAccount">
                              <Typography textAlign="end">
                                <Tooltip title="MyAccount">
                                  <IconButton>
                                    <ArrowBackIcon />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </Link>{" "}
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Typography textAlign="end">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={submitForm}
                              >
                                Save
                              </Button>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Institutions Name"
                              value={instituteName}
                              onChange={(e) => setInstit_name(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth size="small">
                              <Autocomplete
                                size="small"
                                multiple
                                id="combo-box-demo"
                                options={instituiontypes}
                                getOptionLabel={(option) => option.name}
                                value={instituiontypes.filter(
                                  (instituiontypes) =>
                                    instituiontypes.id == instituiontype
                                )}
                                onChange={(e, value) => {
                                  setInstitutionType(
                                    value.length > 1
                                      ? value[1].id
                                      : value.length == 1
                                      ? instituiontype != 0
                                        ? instituiontype
                                        : value[0].id
                                      : 0
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Type of Institution"
                                  />
                                )}
                              />
                            </FormControl>{" "}
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Sub Title"
                              id="outlined-start-adornment"
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Email"
                              value={instituteEmail}
                              onChange={(e) => setInstit_email(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Contact Number"
                              value={instituteMobile}
                              onChange={(e) => setInstit_mobile(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Address"
                              value={instituteAddress}
                              onChange={(e) =>
                                setInstit_address(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="No of Students"
                              value={instituteStudents}
                              onChange={(e) =>
                                setInstit_students(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Leadership"
                              id="outlined-start-adornment"
                              value={instituionleadership}
                              onChange={(e) =>
                                setInstit_leadership(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Address Line 2"
                              id="outlined-start-adornment"
                              value={instituionleadership}
                              onChange={(e) =>
                                setInstit_leadership(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth size="small">
                              <Autocomplete
                                size="small"
                                multiple
                                id="combo-box-demo2"
                                options={instituioncities}
                                getOptionLabel={(option) => option.name}
                                value={instituioncities.filter(
                                  (instituioncities) =>
                                    instituioncities.id == instituioncity
                                )}
                                onChange={(e, value) => {
                                  setInstitutionCity(
                                    value.length > 1
                                      ? value[1].id
                                      : value.length == 1
                                      ? instituioncity != 0
                                        ? instituioncity
                                        : value[0].id
                                      : 0
                                  );
                                }}
                                autoHighlight
                                renderInput={(params) => (
                                  <TextField {...params} label="City" />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Zip Code / Postal Code"
                              value={instituionzipcode}
                              onChange={(e) =>
                                setInstit_zipcode(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Website"
                              value={instituionwebsite}
                              onChange={(e) =>
                                setInstit_website(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth size="small">

                              <Autocomplete
                                multiple
                                size="small"
                                options={gender_list}
                                getOptionLabel={(option) => option.title}
                                value={gender_list.filter(
                                  (gender_list) =>
                                    gender_list.id == instituiongender
                                )}
                                onChange={(e, value) => {
                                  setInstit_gender(
                                    value.length > 1
                                      ? value[1].id
                                      : value.length == 1
                                      ? instituiongender != 0
                                        ? instituiongender
                                        : value[0].id
                                      : 0
                                  );
                                }}
                                autoHighlight
                                renderInput={(params) => (
                                  <TextField {...params} label="Gender" />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth size="small">
                              <Autocomplete
                                size="small"
                                multiple
                                id="country-select-demo"
                                value={countriesss.filter(
                                  (countriesss) =>
                                    countriesss.id == instituioncountry
                                )}
                                options={countriesss}
                                classes={{
                                  option: classes.option,
                                }}
                                onChange={(e, value) => {
                                  setInstitutionCountry(
                                    value.length > 1
                                      ? value[1].id
                                      : value.length == 1
                                      ? instituioncountry != 0
                                        ? instituioncountry
                                        : value[0].id
                                      : 0
                                  );
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.title}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Choose a country"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Map Link"
                              id="outlined-start-adornment"
                              value={instituionmap}
                              onChange={(e) => setInstit_map(e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Latitude"
                              value={instituionlatitude}
                              onChange={(e) =>
                                setInstit_latitude(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Longitude"
                              value={instituionlongitude}
                              onChange={(e) =>
                                setInstit_longitude(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="Status"
                                onChange={StatushandleChange}
                              >
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={2}>In-Active</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Education Fees Minimum"
                              value={minfee}
                              onChange={(e) => setInstit_minfee(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Education Fees maximum"
                              value={maxfee}
                              onChange={(e) => setInstit_maxfee(e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            {" "}
                          </Grid>
                          <Grid item xs={12} md={3} lg={3}>
                            <Paper style={{ width: "100%" }}>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                              >
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="image"
                                  id="file"
                                  onChange={loadFile}
                                  style={{ display: "none" }}
                                />
                                <Typography variant="body2">
                                  Logo or Profile image
                                </Typography>

                                <img
                                  src={uploadImage ? uploadImage : inst_image}
                                  className={classes.image2}
                                  id="output"
                                  width="auto"
                                  maxWidth="100%"
                                  height="135px"
                                  alt="Logo or Profile image"
                                />
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                padding="5px 20px"
                              >
                                <label
                                  htmlFor="file"
                                  style={{ cursor: "pointer" }}
                                >
                                  <PhotoCamera />
                                </label>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            <Paper style={{ width: "100%" }}>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                              >
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="image"
                                  id="file2"
                                  onChange={fileload2}
                                  style={{ display: "none" }}
                                />  
                                <Typography variant="body2">
                                  Banner{" "}
                                </Typography>

                                <img
                                  src={bannerImage ? bannerImage : inst_banner}
                                  className={classes.image2}
                                  id="output2"
                                  width="auto"
                                  maxWidth="100%"
                                  height="135px"
                                  alt="test"
                                />
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                padding="5px 20px"
                              >
                                <label
                                  htmlFor="file2"
                                  style={{ cursor: "pointer" }}
                                >
                                  <PhotoCamera />
                                </label>
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>
                      </LoadingOverlay>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                          p: 1,
                        }}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" color="GaryText">
                            Overview
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography textAlign="end">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={overviewsubmit}
                            >
                              Save
                            </Button>
                          </Typography>
                        </Grid>
                      </Box>
                      <Divider fullwidth />
                      <MyAccountEditOverviewTextEditor
                        overview={overview}
                        setOverview={setOverview}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <MyAccountEditCourses insti_id={insti_id} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <MyAccountEditGallery insti_id={insti_id} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                      <MyAccountEditAwards insti_id={insti_id} />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                      <MyAccountEditNewsAndEvents insti_id={insti_id} />
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                      <Grid container item spacing={3}>
                        <Grid item xs={12} md={6}>
                          <div>
                            <Dialog open={open2} onClose={handleClose2}>
                              <DialogTitle> Edit Address</DialogTitle>
                              <DialogContent>
                                <Box p={1} flexGrow={1} width={1}>
                                  <Autocomplete
                                    multiple
                                    id="country-select-demo"
                                    value={countriesss.filter(
                                      (countriesss) =>
                                        countriesss.id === editaddress.country
                                    )}
                                    options={countriesss}
                                    autoHighlight
                                    onChange={(e, value) => {
                                      setEditaddress({
                                        ...editaddress,
                                        country:
                                          value.length > 1
                                            ? value[1].id
                                            : value.length == 1
                                            ? editaddress.country != 0
                                              ? editaddress.country
                                              : value[0].id
                                            : 0,
                                      });
                                    }}
                                    getOptionLabel={(option) => option.title}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Choose a country"
                                        variant="filled"
                                      />
                                    )}
                                  />
                                </Box>
                                <Box
                                  component="form"
                                  sx={{
                                    "& > :not(style)": { m: 1, width: "47%" },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    label="Name"
                                    variant="outlined"
                                    size="small"
                                    value={editaddress.name}
                                    onChange={(e) => {
                                      setEditaddress({
                                        ...editaddress,
                                        name: e.target.value,
                                      });
                                    }}
                                  />
                                  <TextField
                                    label="Mobile"
                                    variant="outlined"
                                    size="small"
                                    value={editaddress.mobile_no}
                                    onChange={(e) => {
                                      setEditaddress({
                                        ...editaddress,
                                        mobile: e.target.value,
                                      });
                                    }}
                                  />
                                  <TextField
                                    label="City"
                                    variant="outlined"
                                    size="small"
                                    value={editaddress.city}
                                    onChange={(e) => {
                                      setEditaddress({
                                        ...editaddress,
                                        city: e.target.value,
                                      });
                                    }}
                                  />
                                  <TextField
                                    label="Area"
                                    variant="outlined"
                                    size="small"
                                    value={editaddress.area}
                                    onChange={(e) => {
                                      setEditaddress({
                                        ...editaddress,
                                        area: e.target.value,
                                      });
                                    }}
                                  />
                                  <TextField
                                    label="Zipcode"
                                    variant="outlined"
                                    size="small"
                                    value={editaddress.zipcode}
                                    onChange={(e) => {
                                      setEditaddress({
                                        ...editaddress,
                                        zipcode: e.target.value,
                                      });
                                    }}
                                  />
                                  <TextField
                                    label="Vat Number (Company Only)"
                                    variant="outlined"
                                    size="small"
                                    value={editaddress.vat_number}
                                    onChange={(e) => {
                                      setEditaddress({
                                        ...editaddress,
                                        vat_number: e.target.value,
                                      });
                                    }}
                                  />
                                </Box>
                                <Box pt={0.8} m={1}>
                                  <TextField
                                    label="Address - Bldg No./ Landmark / Streat"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    maxRows={4}
                                    value={editaddress.address}
                                    onChange={(e) => {
                                      setEditaddress({
                                        ...editaddress,
                                        address: e.target.value,
                                      });
                                    }}
                                  />
                                </Box>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose2}>Cancel</Button>
                                <Button variant="contained">Submit</Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                          <Typography variant="h6" color="GaryText">
                            Custom Fields
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography textAlign="end">
                            <CustomeFieldDialog
                              insti_id={insti_id}
                              type={1}
                              customList={customList}
                            />
                          </Typography>
                        </Grid>

                        <Grid item xs={12} mb={2}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          {customfields && customfields.length > 0 ? (
                            <div>
                              <Grid item xs={12}>
                                {customfields &&
                                  customfields.map((value, index) => {
                                    return (
                                      <Grid container spacing={1} py={1}>
                                        <Grid item xs={4} sm={2} md={3}>
                                          <ListItemText primary={value.title} />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={7}>
                                          <ListItemText
                                            primary={value.description}
                                          />
                                        </Grid>
                                        <Grid item xs={2} sm={1} md={1}>
                                          <CustomeFieldDialogEdit
                                            custom_id={value.id}
                                            insti_id={insti_id}
                                            type={1}
                                            customList={customList}
                                          />
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                          <DeleteConfirmDialog
                                            type="institute_custom"
                                            id={value.id}
                                            Lists={customList}
                                          />
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </div>
                          ) : (
                            <Box className="EducationProgramsContainer">
                              <Box
                                p={1}
                                className="EducationDetailPrograms_bg cp"
                              >
                                <Box p={1}>
                                  <Typography
                                    sx={{ mt: 1.5 }}
                                    gutterBottom
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    No Data Found
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </ThemeProvider>
        </Container>
      </Box>
    </div>
  );
}

const gender_list = [
  { title: "Male", id: "1" },
  { title: "Female", id: "2" },
  { title: "Mixed", id: "3" },
];
