import React from "react";
import axios from "../Utils/Axios"; //import axios config
import EditIcon from "@mui/icons-material/Edit";
import MyAccountEditCoursesDialog from "./MyAccountEditCoursesDialog";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import {
    Avatar,
    Box,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Pagination,
    Stack,
    Typography,
  } from "@mui/material";
  import DeleteConfirmDialog from "./DeleteConfirmDialog";
import { ConfirmProvider } from "material-ui-confirm";


export default function MyAccountEditCourses({insti_id}) {
  const [page, setPage] = React.useState(1);
  const [experts, setExpertsList] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    manageList(insti_id);
  }, [page, rowsPerPage]);

  const manageList = (insti_id) => {
    setLoading(true);
    axios(
      `Course/course_instituitions?institue_id=${insti_id}&page=${page}&per_page=${rowsPerPage}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTotal(res.data.total);
          setExpertsList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setPage(newValue);
  };

  const history = useHistory();
  //  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);

  const getInstituitionEdit = (insti_id, id) => {
    history.push({
      pathname: `/MyAccount_ManageListingAddCoursesEdit/${insti_id}/${id}`,
    });
  };

  return (
    <div>
      <Grid container item spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" color="GaryText">
            Courses
          </Typography>
          <Typography variant="body2" color="GrayText">
            Manage your courses details
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign="end">
            <MyAccountEditCoursesDialog
              insti_id={insti_id}
              manageList={manageList}
            />
          </Typography>
        </Grid>
        
        <Grid item xs={12} mb={2}>
          <Divider />
        </Grid>

        {experts && experts.length > 0 ? (
          <Grid item xs={12}>
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
            {experts &&
              experts.map((value, index) => {
                return (
                  <Grid container spacing={1} py={1}>
                    <Grid item xs={2} sm={2} md={2}>
                      <ListItemAvatar>
                        <Avatar>
                          <Avatar src={value.image} alt={value.title} />
                        </Avatar>
                      </ListItemAvatar>
                    </Grid>
                    <Grid item xs={10} sm={8} md={8}>
                      <ListItemText primary={value.title} />
                    </Grid>
                    <Grid item xs={2} sm={1} md={1}>
                      <ListItem
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() =>
                              getInstituitionEdit(insti_id, value.id)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        }
                      ></ListItem>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1}>
                    <ConfirmProvider>
                       <DeleteConfirmDialog type="course" id={value.id} Lists={manageList}/>  
                    </ConfirmProvider>
                    </Grid>
                  </Grid>
                );
              })}
            </LoadingOverlay>
            <Box p={2} display="flex" justifyContent="center">
              <Stack spacing={2}>
                <Pagination count={total} page={page} onChange={handleChange} />
              </Stack>
            </Box>
          </Grid>
        ) : (
          <Box className="EducationProgramsContainer">
            <Box p={1} className="EducationDetailPrograms_bg cp">
              <Box p={1}>
                <Typography
                  sx={{ mt: 1.5 }}
                  gutterBottom
                  variant="subtitle2"
                  component="div"
                >
                  No Course Found
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
    </div>
  );
}
