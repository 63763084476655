import React, { useState, useRef } from "react";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import image from "../images/user.webp";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import JoditEditor from "jodit-react";
import EditIcon from "@mui/icons-material/Edit";

const useStyle = makeStyles({
  image: {
    borderRadius: 100,
  },
});

const currencies = [
  {
    value: "1",
    label: "Active",
  },
  {
    value: "2",
    label: "In-Active",
  },
];
export default function MyAccountContributionsArticlesDialogEdit({article_id,articlesList}) {
  const [Category, setCategory] = React.useState([]);

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const [state, setState] = React.useState({
    Active: true,
  });

  const handleChangeActive = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const editor = useRef(null);
  const [content, setContent] = useState("Start writing");
  const config = {
    readonly: false,
    height: 400,
  };

  const alert = useAlert();
  const [uploadImage, setUploadImage] = useState("");
  const [edit, setedits] = React.useState({});
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };
  const [proimage, setImage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);

    axios(`Article/get_article_infos?id=${article_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          alert.error(res.data.message);
        } else {
          setedits(res.data.data[0]);
          setUploadImage(res.data.data[0].images);
          setStatus2(res.data.data[0].status);
          setCategory(res.data.data[0].categories);
        }
      })
      .catch((err) => console.log(err));

  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    getCategories();
  }, []);

  const [categories, setCategories] = useState([]);

  const setCategorySelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setCategory(arr);
  };

  const getCategories = () => {
    axios(`Article/get_news_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCategories(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const updateArticle = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("article_id", article_id);
    formdata.append("title", edit.title);
    formdata.append("description", edit.description);
    formdata.append("logo", proimage);
    formdata.append("status", status2);
    formdata.append("categories", JSON.stringify(Category));

    axios(`Article/update_article`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          articlesList();
          alert.success(res.data.message);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const [status2, setStatus2] = React.useState("1");

  const handleChange = (event) => {
    setStatus2(event.target.value);
  };

  return (
    <div>
      {/* <ListItem
        onClick={handleClickOpen}
        secondaryAction={ */}
          <IconButton   onClick={handleClickOpen} aria-label="delete">
            <EditIcon />
          </IconButton>
        {/* }
      ></ListItem> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Articles </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography guttterBottom>
              Edit Articles to your account here. You can add multiple
              contributions.
            </Typography>
          </DialogContentText>

          <Grid container item spacing={3}>
            <Grid item xs={12} md={4}>
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  mt={3}
                >
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="body2">Banner, logo </Typography>

                  <img
                    src={uploadImage ? uploadImage : image}
                    className={classes.image}
                    id="output"
                    width="100%"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCamera />
                  </label>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                sx={{ pt: 4 }}
                autoFocus
                id="outlined-textarea"
                placeholder="Title"
                variant="outlined"
                fullWidth
                value={edit.title}
                onChange={(e) => {
                  setedits({ ...edit, title: e.target.value });
                }}
              />
              <FormControl
                variant="standard"
                fullWidth
                size="small"
                sx={{ py: 2 }}
              >
                <Autocomplete
                  size="small"
                  multiple
                  id="country-select-demo"
                  options={categories}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  value={categories.filter(
                    (categories) =>Category && Category.includes(categories.id)
                  )}
                  onChange={(e, value) => {
                    setCategorySelect(value);
                  }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a category"
                      //     variant="outlined"
                    />
                  )}
                />
              </FormControl>
              {/* <FormControl
                component="fieldset"
                variant="standard"
                fullWidth
                sx={{ py: 2 }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.Active}
                        onChange={handleChangeActive}
                        name="Active"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </FormControl> */}

              <TextField
                fullWidth
                size="small"
                id="outlined-select-status-native"
                select
                label="Select status"
                value={status2}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                {currencies.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <JoditEditor
                ref={editor}
                config={config}
                value={edit.description}
                onBlur={(e) => {
                  setedits({ ...edit, description: e.target.value });
                }}
                onChange={(newContent) => {}}
              />
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="MyAccountProfileBg">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateArticle} className="primaryButton">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
