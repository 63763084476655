import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Person, VerifiedUser, VideoLabel } from '@material-ui/icons';


export default function TopCards() {
  return (
    <Grid container item spacing={3} >
    <Grid item xs={12} md={6} lg={6} xl={3}>
    <Card>
        <Box  sx={{ display: 'flex', justifyContent: 'space-between', bgcolor:'#ecedf7', color:"#192566", alignItems: 'center', }}>
      <CardContent>
      <Typography sx={{ fontSize: 16, }}  gutterBottom>
      Total Students
        </Typography>
        <Typography variant="h6" component="div" className=' over'>
        1250
        </Typography>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
       +1200 Students
        </Typography> */}
      </CardContent>
      <CardContent>
        <Person fontSize="large" />
      </CardContent>
      </Box>
    </Card>
    </Grid>
    <Grid item xs={12} md={6} lg={6} xl={3}>
    <Card>
        <Box  sx={{ display: 'flex', justifyContent: 'space-between', bgcolor:'#ecf5f7', color:"#502369", alignItems: 'center', flexDirection: 'row' }}>
      <CardContent>
      <Typography sx={{ fontSize: 16 }}  gutterBottom> 
      Qualified Instructor
        </Typography>
        <Typography variant="h6" component="div" className=' over'>
        310
        </Typography>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
       +1200 Students
        </Typography> */}
      </CardContent> 
      <CardContent>
        <EmojiEventsIcon fontSize="large" />
      </CardContent>
      </Box>
    </Card>
    </Grid>
    <Grid item xs={12} md={6} lg={6} xl={3}>
    <Card>
        <Box  sx={{ display: 'flex', justifyContent: 'space-between', bgcolor:'#f7ecf3', color:"#A21F6E", alignItems: 'center', flexDirection: 'row' }}>
      <CardContent>
      <Typography sx={{ fontSize: 16 }}  gutterBottom>
      Course enrolments
        </Typography>
        <Typography variant="h6" component="div" className=' over'>
        1565
        </Typography>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
       +1200 Students
        </Typography> */}
      </CardContent> 
      <CardContent>
        <VerifiedUser fontSize="large" />
      </CardContent>
      </Box>
    </Card>
    </Grid>
    <Grid item xs={12} md={6} lg={6} xl={3}>
    <Card>
        <Box  sx={{ display: 'flex', justifyContent: 'space-between', bgcolor:'#f7ecf5', color:"#BD1E6F", alignItems: 'center', flexDirection: 'row' }}>
      <CardContent>
      <Typography sx={{ fontSize: 16 }}  gutterBottom>
      Online Videos
        </Typography>
        <Typography variant="h6" component="div" className=' over'>
        856
        </Typography>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
       +1200 Students
        </Typography> */}
      </CardContent> 
      <CardContent>
        <VideoLabel fontSize="large" />
      </CardContent>
      </Box>
    </Card>
    </Grid>
    </Grid>
  );
}
