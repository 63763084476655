import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-oldschool-dark'
import Context from './component/Context/AuthContext'

/* ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
); */


reportWebVitals();

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '75px',
  transition: transitions.SCALE
}

ReactDOM.render(
  <React.StrictMode>
    <Context>
    <AlertProvider template={AlertTemplate} {...options}>
    <App />
    </AlertProvider>
    </Context>
  </React.StrictMode>,
  document.getElementById('root')
);
