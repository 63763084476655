import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { CardMedia, createTheme } from "@mui/material";
import aboutbanner from "../images/aboutbanner.webp";
import aboutbanner2 from "../images/aboutbanner2.webp";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
};


export default function About() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "100%" }}>
    <Header />

    <Container>
      <Grid container spacing={3} py={3} mt={9}>
        <Grid item xs={12} sm={12} md={4}>
          <Grid container>
            <Grid item sm={12} md={12}>
              <CardMedia
                component="img"
                sx={{ border: "solid 1px #f1f1f1", borderRadius: 4 }}
                height="275"
                Width="100%"
                alt="Course banner"
                image={aboutbanner}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} height="fit-content">
          <Box>
            <Typography variant="h5" pb={1} gutterBottom color="secondary">
              About Us
            </Typography>
            <Typography color="text.secondary">
              Ta'aleem website is made for everyone, from parents, students,
              teachers, elderly, schools, universities, institutions and
              people of determination, and anyone who can teach any craft like
              carpentry, tailoring or a skilled job, to be identified by
              customers, employers and talent hunters. Ta'aleem website offers
              business growth integrated programmes for educational
              organizations, especially for schools that consider correcting
              performance deviations, strategising needs and meeting them,
              investing in teachers to join reputed programmes to elevate the
              schools' positioning nationally, and the same goes for students.
            </Typography>

            <Typography color="text.secondary">
              The website offers programmes that can shape talents from a
              young age, to be recruited as soon as they graduate and given
              priority in scholarships. The website also aims to digitalise
              traditional libraries’ business models, to join the e-commerce
              sector in order to earn more revenue and expand their customer
              base.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
    <Container>
      <Grid container py={3}>
        <Grid
          item  
          xs={12}
          sm={12}
          md={12}
          sx={{ bgcolor: "#f9f9f9", p: 3, borderRadius: 4 }}
        >
          <Typography color="text.secondary">
            What's special about the website is that it aims to be a growth
            companion to its users, helping them to be educational
            ambassadors, especially in the educational tourism sector.
            Ta'aleem provides a distinctive package, which gives access to
            conferences, seminars, workshops and exhibitions with the service
            of providing an accompanying professional colleague. In addition
            to this, it also allows users to receive education from
            specialists and experienced people, who can provide guidance in
            professional career advancement. Since Ta'aleem values the
            person’s individuality, this would contribute to development in
            charitable sector as donations won't only include students with
            excellent final grades, but also students with acceptable final
            grades with distinction in a particular subject or interest.
          </Typography>
        </Grid>
      </Grid>
    </Container>

    <Container>
      <Grid container spacing={3} pb={5}>
        <Grid item xs={12} sm={12} md={8} height="fit-content">
          <Box sx={{ bgcolor: "background.paper" }}>
            <Typography variant="h5" pb={1} gutterBottom color="secondary">
              Who we are
            </Typography>
            <Typography color="text.secondary">
              The rewarding system Every registered user receives a good
              rating on the personal profile or achieves an accomplishment in
              terms of academic performance or special activities such as
              voluntary work or story reading when it comes to kids, (which
              will be given in the special platform for kids), and for
              teachers who are working as an individual or in an institution
              with good remarks in their performance, and for people of
              determination offering a service on Ta'aleem, will be eligible
              to get customised rewards that fit their interests, through the
              website's partnerships established with different retail shops
              and companies that are related to: health (such as getting
              offers and discounts in nearby clinics, pharmacies or
              hospitals), sports (such as gyms, sportswear stores),
              restaurants, online and offline clothing stores and cosmetic
              shops, online and offline supermarkets, beauty salons,
              bookshops, to name a few. The rewards are unending.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={12}>
              <CardMedia
                component="img"
                sx={{ border: "solid 1px #f1f1f1", borderRadius: 4 }}
                height="275"
                Width="100%"
                alt="Course banner"
                image={aboutbanner2}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>

    <Footer />
  </div>
  )
}
