import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

export default function EducationMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: "white", m: 1, alignSelf: "center"}}
      >
        Education
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <div> 
        <Box sx={{ flexGrow: 1, maxWidth: 800, }}>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Tutors</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>Teachers</MenuItem>
        <MenuItem onClick={handleClose}>Undergraduate</MenuItem>
        <MenuItem onClick={handleClose}>Post-graduate</MenuItem>
        <MenuItem onClick={handleClose}>Creer experts</MenuItem>
        </Grid>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Charity</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>CSR</MenuItem>
        <MenuItem onClick={handleClose}>Companies</MenuItem>
        </Grid>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Institutions</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>Universities</MenuItem>
        <MenuItem onClick={handleClose}>Collages</MenuItem>
        </Grid>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Books</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>E-Commerce</MenuItem>
        <MenuItem onClick={handleClose}>bookshope</MenuItem>
        </Grid>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Business</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>Educational Tourism</MenuItem>
        <MenuItem onClick={handleClose}>Join our</MenuItem>
        </Grid>
        <Grid item xs={6} lg={4}>
        <MenuItem onClick={handleClose} sx={{fontWeight: "bold"}}>Special Services</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>People of determination</MenuItem>
        <MenuItem onClick={handleClose}>distinguished packages</MenuItem>
        </Grid>
      </Grid>
    </Box>
        </div>
       
       
      </Menu>
    </div>
  );
}
