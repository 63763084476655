import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StarIcon from "@mui/icons-material/Star";
import { Box, Rating } from "@mui/material";
import axios from "../Utils/Axios";
import { useAlert } from "react-alert";
import { Link, useHistory } from "react-router-dom";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

export default function ReviewDialog({bookId,getbooks}) {
  const [hover, setHover] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [title, set_title] = React.useState("");
  const [desc, set_desc] = React.useState("");
  const [rating, setRating] = React.useState();
  const bookid = bookId.bookId;
  const alert = useAlert();
  const history = useHistory();


  console.log(bookid);

  const handleClickOpen = () => {
   // setOpen(true);
   if(localStorage.getItem("data"))
   {
   setOpen(true);
 //  getbookcomment();
   }
   else{
     history.push({
       pathname: "/login",
       state: { data: true },
     });
   }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addbookRating = (bookId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("bookId", bookId);
      formdata.append("rating", rating);
      formdata.append("title", title);
      formdata.append("description", desc);
      axios(`Home/add_Book_rating`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            alert.success(res.data.message);
            setOpen(false);
            getbooks();
          }
        })
        .catch((err) => console.log(err));
    }
  };


  const getbookrating = () => {
      axios(`Home/get_books_review?bookId=${bookId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
          } else {
            set_title (res.data.data[0].title);
            setRating (res.data.data[0].rating);
            set_desc (res.data.data[0].review);
          }
        })
        .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    getbookrating();
  }, [bookId]);

  return (
    <div className="MyAccountProfileBg">
      <Button className="primaryButtonOutlined" variant="outlined" onClick={handleClickOpen}>
        Write a customer Cmment and Rating
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Write a customer comment and rating </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please write a comment for this product. We will use this to improve
            our service.
          </DialogContentText>
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
              my: 2,
              cursor: "pointer",
            }}
          >
            <Rating
              name="hover-feedback"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {rating !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
            )}
          </Box>
          <TextField label="Title"  variant="standard" fullWidth 
            value={title}
            onChange={(e) => set_title(e.target.value)} />
          <TextField
            autoFocus
            multiline
            maxRows={4}
            margin="dense"
            id="name"
            label="About the product"
            type="text"
            fullWidth
            variant="standard"
            value={desc}
            onChange={(e) => set_desc(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="MyAccountProfileBg">
          <Button onClick={handleClose}>Cancel</Button>
          <Button className="primaryButton" variant="contained" onClick={() => addbookRating(bookId)}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
