import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import LoadingOverlay from "react-loading-overlay";
import axios from "../Utils/Axios"; //import axios config
import { Grid } from "@mui/material";

export default function EducationDetail_Programs_ViewAll(props) {
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [courseLists, setCourseList] = React.useState([]);
  const [loadfooter, setloadfooter] = React.useState(false);

  const [institueId, setinstitueId] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getAllCourses();
  }, [institueId]);

  const getAllCourses = () => {
    setLoading(true);
    axios(`Course/courselists?institue_id=${institueId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setCourseList(res.data.data); //to set response data to state
          setLoading(false);
        }
        setloadfooter(true);
      })
      .catch((err) => console.log(err));
  };

  const CourseView = (id) => {
    setinstitueId(id);
    history.push({
      pathname: `/EducationCourseDetail/${id}`,
    });
    window.location.reload();
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Header />
      <Container maxWidth="xl">
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
          {courseLists && courseLists.length > 0 ? (
            <div>
              <Box display="flex" sx={{ p: 1, pt: 15 }}>
                <Typography
                  variant="h4"
                  sx={{ color: "#572672" }}
                  gutterBottom
                  component="div"
                >
                  All Programs
                </Typography>
              </Box>

              <Grid container spacing={2} pb={10}>
                {courseLists &&
                  courseLists.map((value, index) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        md={3}
                        lg={3}
                        xl={2}
                        sx={{ cursor: "pointer" }}
                        onClick={() => CourseView(value.id)}
                      >
                        <CardMedia
                          component="img"
                          image={value.image}
                          sx={{
                            boxShadow: 2,
                            height: 250,
                            borderRadius: 5,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        />
                        <Box
                          p={1}
                          sx={{
                            bgcolor: "white",
                            boxShadow: 2,
                            borderRadius: 5,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                          }}
                        >
                          <Typography
                            //  sx={{ mt: 1.5 }}
                            gutterBottom
                            variant="subtitle2"
                            component="div"
                            sx={{
                              mt: 1.5,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "1",
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {value.title}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          ) : (
            <Box className="EducationProgramsContainer">
              <Box p={1} className="EducationDetailPrograms_bg cp">
                <Box p={1} mb={5} mt={15} minHeight={260} >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="#572672"
                    textAlign="center"
                  >
                    No Programs Found 
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </LoadingOverlay>
      </Container>
      {loadfooter && <Footer />}
    </div>
  );
}
