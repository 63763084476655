import React from "react";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
//import HeaderThree from "../HeaderThree/HeaderThree";
import LoadingOverlay from "react-loading-overlay";
import axios from "../Utils/Axios"; //import axios config
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import { Card, CardContent, Grid, Tooltip, Rating } from "@mui/material";
import Header from "../Header/Header";

export default function EducationAllbooks() {

  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [loadfooter, setloadfooter] = React.useState(false);

  const [books, setBooks] = React.useState([]);
  React.useEffect(() => {
    instituteBooksList();
  }, []);

  const BookView = (id) => {
    history.push({
      pathname: `/EducationBooksDetail/${id}`,
    });
  };

  const instituteBooksList = () => {
    setLoading(true);
    axios(`Home/get_institute_books`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setBooks(res.data.data); //to set response data to state
        }
        setloadfooter(true);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(
    () => {
      window.scrollTo(0, 0);
    }, 
    []
  );
  
  const [value, setValue] = React.useState(2);

  return (
    <div style={{ width: "100%" }}>
      {/* <HeaderThree /> */}
      <Header />

      <Box className="TopContainer" sx={{ bgcolor: "#fff", pb: "48px" }}>
        <Box>
         
        </Box>
      </Box>
      <Box className="caro_bg" sx={{ bgcolor: "#fff", pb: "48px" }}>
        <Container maxWidth="xl">
        <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
          <Grid container spacing={3} sx={{ pt: 5 }}>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className="filterBox"
              display={{ xs: "none", sm: "block" }}
            ></Grid>
            <Grid item xs={12} sm={10} md={10}>
              <Grid>
                <Typography
                  variant="h5"
                  fontWeight={600}
                  gutterBottom
                  component="div"
                  sx={{ color:"#572672"}}
                >
                  All Books
                </Typography>
              </Grid>
              <Grid container spacing={0}>
                {books.map((data2, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        onClick={() => BookView(data2.id)}
                      >
                        <Card>
                          <CardMedia
                            component="img"
                            height="300"
                            image={data2.images}
                            alt={data2.title}
                          />

                          <Box className="cp">
                            <Grid container spacing={0}>
                              <Grid item xs={12} sm={12} md={12}>
                                <CardContent fullwidth>
                                  <Tooltip Tooltip title={data2.title}>
                                    <Typography
                                      Tooltip
                                      title={data2.title}
                                      className="text_bg"
                                      textAlign="start"
                                      sx={{ mt: 1.5 }}
                                      gutterBottom
                                      variant="subtitle2"
                                      component="div"
                                    >
                                      {data2.title}
                                    </Typography>
                                  </Tooltip>
                                  <Typography
                                    className="text_bg"
                                    textAlign="start"
                                    gutterBottom
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {data2.author}
                                  </Typography>
                                  <Rating name="read-only" value={value} readOnly />
                                </CardContent>
                              </Grid>
                            </Grid>
                          </Box>
                        </Card>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
</LoadingOverlay>
        </Container>
      </Box>

      {loadfooter && <Footer />}
    </div>
  );
}
