import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Home from "./component/Home/Home";
import Education from "./component/Education/Education";
import EducationLisiting from "./component/Education/EducationLisiting";
import Registration from "./component/Registration/Registration";
import RegistrationTwo from "./component/Registration/RegistrationTwo";
import EducationDetail from "./component/Education/EducationDetail";
import EducationDetail_Programs_ViewAll from "./component/Education/EducationDetail_Programs_ViewAll";
import EducationCourseDetail from "./component/Education/EducationCourseDetail";
import EducationCourseListing from "./component/Education/EducationCourseListing";
import Map from "./component/Education/Map";
import EducationExpertDetail from "./component/Education/EducationExpertDetail";
import VideoBanner from "./component/videosAll/VideoBanner";
import ThankYou from "./component/Registration/ThankYou";
import LogIn_Popup from "./component/Registration/LogIn_Popup";
import ForgotPassword from "./component/Registration/ForgotPassword";
import EducationCategory from "./component/Education/EducationCategory";
import ResetPassword from "./component/Registration/ResetPassword";
import MyAccount from "./component/Registration/MyAccount";
import EducationLisitingMap from "./component/Education/EducationLisitingMap";
import MyAccount_ManageListingEdit from "./component/Registration/MyAccount_ManageListingEdit";
import MyAccount_ManageListingAddCoursesEdit from "./component/Registration/MyAccount_ManageListingAddCoursesEdit";
import MyAccount_ManageListingCourseListEdit from "./component/Registration/MyAccount_ManageListingCourseListEdit";
import MyAccountEdit from "./component/Registration/MyAccountEdit";
import EducationGuideArticles from "./component/Education/EducationGuideArticles";
import EducationGuideArticlesDetail from "./component/Education/EducationGuideArticlesDetail";
import EducationBooksDetail from "./component/Education/EducationBooksDetail";
import Settings from "./component/Registration/Settings";
import Login from "./component/Registration/Login";
import MyAccountEditCoursesDialog from "./component/Registration/MyAccountEditCoursesDialog";
import MyAccountEditCoursesEdit from "./component/Registration/MyAccountEditCoursesEdit";
import EducationAllbooks from "./component/Education/EducationAllbooks";
import EducationBookStores from "./component/Education/EducationBookStores";
import EducationGuideArticlesLists from "./component/Education/EducationGuideArticlesLists";
import Cart from "./component/Cart/Cart";
import Checkout from "./component/Cart/Checkout";
import Review from "./component/Cart/Review";
import EducationBookStoresDetail from "./component/Education/EducationBookStoresDetail";
import EducationBookStoresDetailAddressBook from "./component/Education/EducationBookStoresDetailAddressBook";
import Success from "./component/Cart/Success";
import MyAccountBookStoreEdit from "./component/Registration/MyAccountBookStoreEdit";
import MyAccountBookStore from "./component/Registration/MyAccountBookStore";
import CreativeTalentShow from "./component/Education/CreativeTalentShow";
import EducationStores from "./component/Education/EducationStores";
import EducationStoresDetails from "./component/Education/EducationStoresDetails";
import EducationStoresAllbooks from "./component/Education/EducationStoresAllbooks";
import CommuniteesDetail from "./component/Education/CommuniteesDetail";
import Groups from "./component/Education/Communities/Groups";
import About from "./component/pages/About";

function App() {
  return (
<Router>
  <main>
    <Switch>
      <Route path="/" exact><Education /></Route>
      <Route path="/Home" component={Home} exact />
      <Route path="/Education" component={Education} exact />
      <ProtectedRoute path="/MyAccount" component={MyAccount} exact />
       <AuthRoute path="/Registration" exact component={Registration} />
      <Route path="/RegistrationTwo" component={RegistrationTwo} exact />
      <Route path="/EducationDetail/:id?" component={EducationDetail} exact />
      <Route path="/EducationDetail_Programs_ViewAll/:id?" component={EducationDetail_Programs_ViewAll} exact />
      <Route path="/EducationLisiting" component={EducationLisiting} exact />
      <Route path="/EducationCourseDetail/:id?" component={EducationCourseDetail} exact />
      <Route path="/EducationCourseListing/:id?" component={EducationCourseListing} exact />
      <Route path="/Map" component={Map} exact />
      <Route path="/About"  component={About} exact /> 
      <Route path="/EducationExpertDetail/:id?" component={EducationExpertDetail} exact />
      <Route path ="/VideoBanner  " component={VideoBanner} exact />
      <Route path="/ThankYou" component={ThankYou} exact />
      <Route path="/LogIn_Popup" component={LogIn_Popup} exact />
      <Route path="/ForgotPassword" component={ForgotPassword} exact />
      <Route path="/EducationCategory/:id?" component={EducationCategory} exact />
      <Route path="/EducationAllbooks" component={EducationAllbooks} exact />
      <AuthRoute path="/reset-password/:key1/:key2" exact component={ResetPassword} />
      <Route path="/EducationLisitingMap" component={EducationLisitingMap} exact />
      <Route path="/MyAccount_ManageListingEdit/:id?" component={MyAccount_ManageListingEdit} exact /> 
      <Route path="/MyAccount_ManageListingAddCoursesEdit/:id/:keyid" component={MyAccount_ManageListingAddCoursesEdit} exact />
      <Route path="/MyAccount_ManageListingCourseListEdit" component={MyAccount_ManageListingCourseListEdit} exact /> 
      <ProtectedRoute path="/MyAccountEdit/:id?" component={MyAccountEdit} exact />
      <Route path="/EducationGuideArticles" component={EducationGuideArticles} exact />
      <Route path="/EducationGuideArticlesDetail/:id?" component={EducationGuideArticlesDetail} exact />
      <Route path="/EducationBooksDetail/:id?" component={EducationBooksDetail} exact />
      <Route path="/Settings" component={Settings} exact />
      <Route path="/Login" component={Login} exact />
      <Route path="/MyAccountEditCoursesDialog" component={MyAccountEditCoursesDialog} exact />
      <Route path="/MyAccountEditCoursesEdit" component={MyAccountEditCoursesEdit} exact />
      <Route path="/EducationBookStores" component={EducationBookStores} exact />
      <Route path="/EducationGuideArticlesLists/:id?" component={EducationGuideArticlesLists} exact />
      <Route path="/Cart" component={Cart} exact />
      <Route path="/Checkout" component={Checkout} exact />
      <Route path="/Review" component={Review} exact />
      <Route path="/EducationBookStoresDetail/:id?" component={EducationBookStoresDetail} exact />
      <Route path="/EducationBookStoresDetailAddressBook/:id?" component={EducationBookStoresDetailAddressBook} exact />
      <Route path="/Success" component={Success} exact />
      <ProtectedRoute path="/MyAccountBookStoreEdit/:id?" component={MyAccountBookStoreEdit} exact />
      <Route path="/MyAccountBookStore" component={MyAccountBookStore} exact />
      <Route path="/CreativeTalentShow" component={CreativeTalentShow} exact />
      <Route path="/EducationStores" component={EducationStores} exact />
      <Route path="/EducationStoresAllbooks" component={EducationStoresAllbooks} exact />
      <Route path="/EducationStoresDetails/:id?" component={EducationStoresDetails} exact />
      <Route path="/CommuniteesDetail/:id?" component={CommuniteesDetail} exact />
      <Route path="/Groups/" element={<Groups />} />
      <Redirect to="/" />
    </Switch>
  </main>
</Router>
  );
}

const ProtectedRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/Login",
              refresh: true,
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};


const AuthRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Redirect
            to={{
              pathname: "/",
              refresh: true,
              state: {
                prevLocation: path,
                error: "Already Login",
              },
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default App;
